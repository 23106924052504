import { useState, useEffect, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { es } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

import MaintenanceFeeHeader from "../components/maintenanceFeeDashboard/MaintenanceFeeHeader";
import MaintenanceFeeStats from "../components/maintenanceFeeDashboard/MaintenanceFeeStats";
import MaintenanceFeeRow from "../components/maintenanceFeeDashboard/MaintenanceFeeRow";
import MaintenanceFeeTable from "../components/common/MaintenanceFeeTable";
import EmptyStateAlert from "../components/common/EmptyStateAlert";
import MaintenanceFeeRowSkeleton from "../components/maintenanceFeeDashboard/MaintenanceFeeRowSkeleton";
import MaintenanceFeeStatsSkeleton from "../components/maintenanceFeeDashboard/MaintenanceFeeStatsSkeleton";

import useGlobalStore from "../../../state/store";
import { useMaintenanceFee } from "../hooks/useMaintenanceFees";
import createNumberArray from "../../../shared/utils/createNumberArray";
import useFilters from "../../../shared/hooks/useFilters";

const MaintenanceFee = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filterValue, setFilterValue] = useState("Todos");
  const { filters, setFilters } = useFilters();
  const maintenanceFeePeriod = filters.maintenanceFeesPeriod;

  const buildingId = useGlobalStore((state) => state.buildingId);
  const {
    maintenanceFees,
    loading,  
    error,
    fetchMaintenanceData,
  } = useMaintenanceFee();

  const navigate = useNavigate();
  const periodoDate = new Date(`${maintenanceFeePeriod}-01T12:00:00`);
  const currentMonthYear = format(periodoDate, "MMMM yyyy", { locale: es })
    .split(" ")
    .map((word, index) =>
      index === 0
        ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        : word
    )
    .join(" ");

  const handleDatePeriodChange = useCallback((selectedDate: string) => {
    setFilters({ maintenanceFeesPeriod: selectedDate });
    if (buildingId) {
      fetchMaintenanceData({ buildingId, period: selectedDate });
    }
  }, [buildingId, fetchMaintenanceData, setFilters]);

  const filteredAndSortedApartments = maintenanceFees
    .filter((apt) => {
      const matchesSearch =
        apt.apartmentNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        // Only search in active residents
        apt.residents?.some((resident) =>
          `${resident.first_name} ${resident.last_name}`
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        );
      
      if (!matchesSearch) return false;
      
      if (filterValue === "Todos") return true;
      if (filterValue === "Validados") return apt.montoPagado > 0;
      if (filterValue === "Pendientes") return apt.porValidar > 0;
      return true;
    })
    .sort((a, b) => {
      const numA = a.apartmentNumber ? parseInt(a.apartmentNumber.replace(/\D/g, '')) || 0 : 0;
      const numB = b.apartmentNumber ? parseInt(b.apartmentNumber.replace(/\D/g, '')) || 0 : 0;
      return  numA - numB;
    });

  const stats = {
    pagosRecibidos: maintenanceFees.reduce(
      (sum, apt) => sum + (apt.montoPagado || 0),
      0
    ),
    porValidar: maintenanceFees.reduce(
      (sum, apt) => sum + (apt.porValidar || 0),
      0
    ),
    recaudado: maintenanceFees.reduce(
      (sum, apt) => sum + (apt.montoPagado || 0),
      0
    ),
    total: maintenanceFees.reduce(
      (sum, apt) => sum + (apt.emiCuota || 0),
      0
    ),
  };

  const noCharges =
    filteredAndSortedApartments.length > 0 &&
    filteredAndSortedApartments.every((apt) => apt.charges.length === 0);

  const handleAddCharges = () => {
    navigate("/maintenance-fee/import");
  };

  if (error) {
    return (
      <Typography color="error" sx={{ p: 3 }}>
        {error}
      </Typography>
    );
  }

  return (
    <Box sx={{ padding: 4 }}>
      {/* MaintenanceFeeHeader - Moved to its own row */}
      <Box sx={{ marginBottom: 4 }}>
        <MaintenanceFeeHeader
          selectedDate={maintenanceFeePeriod}
          onDateChange={handleDatePeriodChange}
        />
      </Box>

      {/* EmptyStateAlert si no hay cargos */}
      {noCharges && (
        <Box sx={{ marginTop: 2 }}>
          <EmptyStateAlert
            message={
              <>
                <span style={{ fontWeight: 500 }}>
                  No se han registrado cuotas de mantenimiento para el mes en
                  curso,{" "}
                </span>
                <span style={{ fontWeight: 600, color: "#92400E" }}>
                  {currentMonthYear}
                </span>
                .
              </>
            }
            buttonLabel="Añadir Cuotas"
            onButtonClick={handleAddCharges}
          />
        </Box>
      )}

      {/* Stats */}
      <Box sx={{ marginTop: 4 }}>
        {loading ? (
          <MaintenanceFeeStatsSkeleton />
        ) : (
          <MaintenanceFeeStats
            selectedDate={maintenanceFeePeriod}
            data={stats}
          />
        )}
      </Box>

      {/* Table */}
      <Box sx={{ marginTop: 4 }}>
        <MaintenanceFeeTable
          onSearchChange={setSearchTerm}
          filterValue={filterValue}
          onFilterChange={setFilterValue}
          showDebtsButton={true}
          onDebtsClick={() => navigate('/maintenance-fee/debts')}
        />
      </Box>

      {/* Lista de filas */}
      {loading ? (
        createNumberArray(10).map((_, index) => (
          <MaintenanceFeeRowSkeleton key={index} />
        ))
      ) : (
        <Box sx={{ marginTop: 2 }}>
          {filteredAndSortedApartments.length > 0 ? (
            filteredAndSortedApartments.map((apartment) => (
              <MaintenanceFeeRow
                key={apartment.id}
                buildingId={buildingId}
                apartment={apartment}
              />
            ))
          ) : (
            <EmptyStateAlert
              message="No se encontraron apartamentos que coincidan con los criterios de búsqueda."
              buttonLabel="Limpiar Filtros"
              onButtonClick={() => {
                setSearchTerm("");
                setFilterValue("Todos");
              }}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default MaintenanceFee;