import { useEffect, useRef, useState } from "react";
import {
  Box,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  TableCell,
  Skeleton,
  SelectChangeEvent,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddPaymentModal from "../maintenanceFeeDashboard/AddPaymentModal";
import { Groups, PersonOutline, AccountBalanceWallet } from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddIndividualChargeModal from "../maintenanceFeeDashboard/AddIndividualChargeModal";
import { Charge, MaintenanceFee, Payment } from "../../types";
import useFilters from "../../../../shared/hooks/useFilters";
import { exportMaintenanceFeeToExcel } from "../../utils/exportMaintenanceFeeToExcel";
import useGlobalStore from "../../../../state/store";
import { useMaintenanceFee } from "../../hooks/useMaintenanceFees";
import React from "react";
import { useMaintenanceFeeAnalytics } from "../../hooks/useMaintenanceFeeAnalytics";

interface MaintenanceFeeTableProps {
  onSearchChange: (value: string) => void;
  filterValue: string;
  onFilterChange: (value: string) => void;
  showDebtsButton?: boolean;
  onDebtsClick?: () => void;
}

const MaintenanceFeeTable = ({
  onSearchChange,
  filterValue,
  onFilterChange,
  showDebtsButton,
  onDebtsClick
}: MaintenanceFeeTableProps) => {
  const userId = useGlobalStore((state) => state.user?.id);
  const [openAddPayment, setOpenAddPayment] = useState(false);
  const [openAddIndividualCharge, setOpenAddIndividualCharge] = useState(false);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const isDetailPage = location.pathname.includes("/detail");

  const { filters } = useFilters();
  const { maintenanceFees } = useMaintenanceFee();
  const { trackDataExported } = useMaintenanceFeeAnalytics();

  const handleOpenPaymentModal = () => setOpenAddPayment(true);
  const handleClosePaymentModal = () => setOpenAddPayment(false);

  const handleOpenIndividualChargeModal = () => {
    setOpenAddIndividualCharge(true);
    setOpen(false);
  };

  const handleCloseIndividualChargeModal = () => {
    setOpenAddIndividualCharge(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLButtonElement)
    ) return;
    setOpen(false);
  };

  const handleExportToExcel = () => {
    exportMaintenanceFeeToExcel(
      maintenanceFees,
      isDetailPage,
      id || "",
      filters.maintenanceFeesPeriod
    );
    trackDataExported(userId!, 'excel', maintenanceFees.length);
  };

  return (
    <>
      {/* Existing Search & Filter Toolbar */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 3,
          gap: 2,
        }}
      >
        {/* Search + Filter */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <TextField
            placeholder="Buscar..."
            onChange={(e) => onSearchChange(e.target.value)}
            size="small"
            InputProps={{
              startAdornment: (
                <SearchIcon sx={{ color: "#9CA3AF", marginRight: 1 }} />
              ),
            }}
            sx={{
              width: "250px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "24px",
                fontSize: "14px",
                backgroundColor: "#F9FAFB",
              },
            }}
          />

          <FormControl size="small">
            <InputLabel>Estado</InputLabel>
            <Select
              value={filterValue}
              label="Estado"
              onChange={(e) => onFilterChange(e.target.value)}
              sx={{
                minWidth: 120,
                backgroundColor: "#FFFFFF",
                borderRadius: "24px",
                fontSize: "14px",
              }}
            >
              <MenuItem value="Todos">Todos</MenuItem>
              <MenuItem value="Validados">Validados</MenuItem>
              <MenuItem value="Pendientes">Por validar</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* Buttons */}
        <Box sx={{ display: "flex", gap: 2 }}>
          {showDebtsButton && (
            <Button
              variant="contained"
              onClick={onDebtsClick}
              sx={{
                backgroundColor: "#DC2626",
                color: "#FFFFFF",
                borderRadius: "24px",
                textTransform: "none",
                fontSize: "14px",
                fontWeight: 500,
                padding: "8px 16px",
                "&:hover": { backgroundColor: "#BB1E1E" },
              }}
              startIcon={<AccountBalanceWallet />}
            >
              Gestionar Deudas
            </Button>
          )}

          <Button
            variant="outlined"
            onClick={handleExportToExcel}
            sx={{
              color: "#4F46E5",
              borderColor: "#E0E7FF",
              backgroundColor: "#F3F4F6",
              borderRadius: "24px",
              textTransform: "none",
              fontSize: "14px",
              fontWeight: 500,
              "&:hover": {
                backgroundColor: "#E0E7FF",
                borderColor: "#4F46E5",
              },
            }}
          >
            Exportar
          </Button>
          <Button
            variant="contained"
            sx={{
              background: "linear-gradient(90deg, #F97316 0%, #9333EA 100%)",
              color: "#FFFFFF",
              borderRadius: "24px",
              textTransform: "none",
              fontSize: "14px",
              fontWeight: 500,
              padding: "8px 16px",
              "&:hover": { opacity: 0.9 },
            }}
          >
            Conciliación Automática
          </Button>

          <Button
            variant="contained"
            sx={{
              backgroundColor: "#315CAC",
              color: "#FFFFFF",
              borderRadius: "24px",
              textTransform: "none",
              fontSize: "14px",
              fontWeight: 500,
              padding: "8px 16px",
              "&:hover": { backgroundColor: "#2A4F94" },
            }}
            onClick={handleToggle}
            ref={anchorRef}
          >
            Añadir cargo
          </Button>
          <Popper
            sx={{
              zIndex: 1,
              borderRadius: "0px",
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper
                  sx={{
                    borderRadius: "16px",
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      <MenuItem
                        key={"Individual"}
                        onClick={handleOpenIndividualChargeModal}
                        sx={{
                          gap: "8px",
                          fontWeight: "bold",
                          "&:hover": {
                            backgroundColor: "#E0E7FF",
                            borderRadius: "16px",
                          },
                        }}
                      >
                        <PersonOutline /> Individual
                      </MenuItem>
                      <MenuItem
                        key={"Multiple"}
                        onClick={() => navigate("/maintenance-fee/import")}
                        sx={{
                          gap: "8px",
                          fontWeight: "bold",
                          "&:hover": {
                            backgroundColor: "#E0E7FF",
                            borderRadius: "16px",
                          },
                        }}
                      >
                        <Groups />
                        Múltiple
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

          <Button
            variant="contained"
            sx={{
              backgroundColor: "#315CAC",
              color: "#FFFFFF",
              borderRadius: "24px",
              textTransform: "none",
              fontSize: "14px",
              fontWeight: 500,
              padding: "8px 16px",
              "&:hover": { backgroundColor: "#2A4F94" },
            }}
            onClick={handleOpenPaymentModal}
          >
            Añadir Pago
          </Button>
        </Box>
      </Box>

      {/* AddPaymentModal */}
      {openAddPayment && (
        <AddPaymentModal
          open={openAddPayment}
          onClose={handleClosePaymentModal}
        />
      )}

      {/* AddIndividualChargeModal */}
      {openAddIndividualCharge && (
        <AddIndividualChargeModal
          open={openAddIndividualCharge}
          onClose={handleCloseIndividualChargeModal}
        />
      )}
    </>
  );
};

export default MaintenanceFeeTable;