import React, { useRef, useState } from "react";
import {
  Box, IconButton, Button, CircularProgress, Alert, Typography
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Download as DownloadIcon,
  UploadFile as UploadFileIcon
} from "@mui/icons-material";
import { supabaseStorage } from "../../../storage";
import useGlobalStore from "../../../state/store";

interface MeterImageProps {
  imageUrl?: string;
  onImageChange: (newImageUrl: string) => void;
  meterInfo: {
    apartment: string;
    period: string;
  };
}

const MeterImage: React.FC<MeterImageProps> = ({ imageUrl, onImageChange, meterInfo }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { buildingId, user } = useGlobalStore();

  const hasImage = imageUrl && imageUrl !== "";

  const handleCardClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setLoading(true);
    setError(null);

    try {
      const path = `water_meters/${buildingId}/${user?.id}`;

      const upload = await supabaseStorage.uploadFile(file, {
        path: path
      });

      if (upload?.url && upload?.path) {
        onImageChange(upload.url);
      }
    } catch (err) {
      console.error("Error al subir la imagen:", err);
      setError(err instanceof Error ? err.message : "Error al subir la imagen");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteImage = () => {
    onImageChange("");
  };

  const handleDownload = async () => {
    try {
      if (!imageUrl) return;

      const response = await fetch(imageUrl);
      if (!response.ok) throw new Error("No se pudo descargar la imagen");
      const blob = await response.blob();
      const objectURL = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = objectURL;
      link.download = `${meterInfo.apartment}_${meterInfo.period}.jpg`;
      link.click();
      URL.revokeObjectURL(objectURL);
    } catch (error) {
      console.error("Error al descargar la imagen:", error);
    }
  };

  return (
    <Box mt={3}>
      <Typography variant="h6" gutterBottom>Foto del Contador</Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {hasImage ? (
        <>
          <Box
            component="img"
            src={imageUrl}
            alt="Foto del contador"
            sx={{ width: "100%", height: "auto", borderRadius: "8px", border: "1px solid #e0e0e0" }}
          />
          <Box mt={1} display="flex" justifyContent="flex-end" gap={1}>
            <IconButton 
              color="error" 
              onClick={handleDeleteImage}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton 
              color="primary" 
              onClick={handleDownload}
            >
              <DownloadIcon />
            </IconButton>
          </Box>
        </>
      ) : (
        <>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
              <CircularProgress size={30} />
            </Box>
          ) : (
            <Box
              sx={{
                border: "2px dashed #CCE1FF",
                borderRadius: "8px",
                p: 3,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                cursor: "pointer",
                mb: 2,
                "&:hover": {
                  backgroundColor: "#F4F8FA",
                }
              }}
              onClick={handleCardClick}
            >
              <UploadFileIcon sx={{ color: "#5D5E61", fontSize: 40, mb: 1 }} />
              <Typography variant="body1" sx={{ color: "#5D5E61", mb: 2 }}>
                Arrastra tu archivo aquí o haz clic para seleccionar
              </Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#315CAC",
                  borderRadius: "20px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#264785",
                  }
                }}
              >
                Seleccionar archivo
              </Button>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleFileChange}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default MeterImage;