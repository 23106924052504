// src/features/invitation/hooks/useVisits.js

import { invitationService } from "../services/invitationService";
import { parseISO } from 'date-fns';  
import useGlobalStore from "../../../state/store";
import { isValidNonEmptyArray } from "../../../shared/utils/isValidNonEmptyArray";
import { Visit } from "../types";

export const useVisits = () => {
  const setVisits = useGlobalStore((state) => state.setVisits);
  const visits = useGlobalStore((state) => state.visits);
  const user = useGlobalStore((state) => state.user);

  const fetchVisits = async () => {
    setVisits({
      error: null,
      loading: true,
    });
    try {
      const data = await invitationService.fetchVisitsForConserje(user!.id);

      const sortedVisits = data.sort((a, b) => {
        if (!a.invitation_date && !b.invitation_date) return 0;
        if (!a.invitation_date) return 1;
        if (!b.invitation_date) return -1;
        return parseISO(a.invitation_date).getTime()  - parseISO(b.invitation_date).getTime() ;
      });
      setVisits({ data: sortedVisits, loading: false });
    } catch (err: unknown) {
      const errorMessage = err instanceof Error ? err.message : "Unknown error";
      setVisits({
        loading: false,
        error: `Failed to fetch visits: ${errorMessage}`,
      });
      console.error("Error fetching visits:", err);
    }
  };

  const hasCachedVisits = isValidNonEmptyArray(visits.data);

  const getLocalCachedVisit = (visitId: string) => {
    if (isValidNonEmptyArray(visits.data)) {
      return visits.data?.find((visit) => visit.visit_id === visitId);
    }

    return null;
  };

  const getVisits = async ({ buildingId }: { buildingId: string }) => {
  
    setVisits({
      error: null,
      loading: true,
    });
  
    try {
      const data = await invitationService.fetchVisitsByBuilding(buildingId);
  
      const sortedVisits: Visit[] = data.sort((a: Visit, b: Visit) => {
        if (!a.invitation_date && !b.invitation_date) return 0;
        if (!a.invitation_date) return 1;
        if (!b.invitation_date) return -1;
        return parseISO(a.invitation_date).getTime() - parseISO(b.invitation_date).getTime();
      });
  
      setVisits({ data: sortedVisits, loading: false });
    } catch (err: unknown) {
      const errorMessage = err instanceof Error ? err.message : "Unknown error";
      setVisits({
        loading: false,
        error: `Failed to fetch visits: ${errorMessage}`,
      });
      console.error("Error fetching visits:", errorMessage);
    }
  };
  

  return {
    visits: visits.data,
    loading: visits.loading,
    error: visits.error,
    refetchVisits: fetchVisits,
    getLocalCachedVisit,
    getVisits,
    hasCachedVisits,
    fetchVisits,
  };
};
