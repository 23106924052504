import { utils, writeFile } from 'xlsx'; 
import { format } from 'date-fns'; 
import { es, fr } from 'date-fns/locale'; 
import { MaintenanceFee } from '../types'; 
import { log } from 'console';
import { formatMonthYear, formatDate } from './formatDate';

export const exportMaintenanceFeeToExcel = (
  MaintenanceFees: any[],
  isDetailPage: boolean,
  maintenanceId: string,
  period: string
): void => {
  if (!MaintenanceFees || MaintenanceFees.length === 0) {
    alert('No hay datos para exportar');
    return;
  }

  const [year, month] = period.split('-').map(Number); 
  const formattedDate = format(new Date(year, month - 1), 'MMMM yyyy', { locale: es }).replace(' ', '_');

  let excelData = [];
  
  if (isDetailPage) {
    const apt = MaintenanceFees.find(apt => apt.id === maintenanceId);
    
    if (!apt) {
      alert('No se encontró el apartamento seleccionado');
      return;
    }

    const charges = Array.isArray(apt.charges) ? apt.charges : [];
    const payments = Array.isArray(apt.payments) ? apt.payments : [];
    
    const combinedData = [
      ...charges.map((charge: any) => ({
        ...charge,
        type: 'charge',
        date: new Date(charge.issue_date),
        concepto: 'Emisión Cuota',
        periodo: new Date(charge.due_date)
      })).sort((a: any, b: any) => b.date.getTime() - a.date.getTime()),
      
      ...payments.map((payment: any) => ({
        ...payment,
        type: 'payment',
        date: new Date(payment.paymentDate),
        concepto: 'Pago de Cuota',
        periodo: new Date(payment.paymentDate)
      })).sort((a: any, b: any) => b.date.getTime() - a.date.getTime()),
    ];
    
        let runningTotal = 0;
    for (let i = combinedData.length - 1; i >= 0; i--) {
      const item = combinedData[i];
      if (item.type === 'charge') {
        const emiCuota = item.emiCuota !== undefined ? item.emiCuota : (item.total_amount || 0);
        const montoPagado = item.montoPagado || 0;
        const currentDebt = emiCuota > montoPagado ? (emiCuota - montoPagado) : 0;
        runningTotal += currentDebt;
        item.runningDeuda = runningTotal;
      } else {
        if (item.status === 'verified') {
          runningTotal = Math.max(0, runningTotal - (item.amount || 0));
        }
        item.runningDeuda = runningTotal;
      }
    }
    
    excelData = combinedData.map((item: any) => {
      const isCharge = item.type === 'charge';
      
      
      

      if(item.status === 'pending') item.status = 'Pendiente'
      else if(item.status === 'verified') item.status = 'Verificado'
      else if(item.status === 'rejected') item.status = 'Rechazado'
      
      return {
        'Concepto': item.concepto,
        'Periodo': formatMonthYear(item.periodo),
        'Fecha': formatDate(item.date ),
        'Código Operación': isCharge ? (item.referenceNumber || 'N/A') : (item.referenceNumber || 'N/A'),
        'Monto': isCharge ? (item.total_amount || 0).toFixed(2) : (item.amount || 0).toFixed(2),
        'Deuda Acumulada': item.runningDeuda.toFixed(2),
        'Estado': isCharge ? '' : (item.status ? item.status : 'Desconocido')
      };
    });
    
  } else {
    excelData = MaintenanceFees.map(apt => {
      const firstResident = apt.residents && apt.residents.length > 0
        ? `${apt.residents[0].first_name} ${apt.residents[0].last_name}`
        : 'Sin residente';
        
      let paymentStatus = 'Pendiente';
      if (apt.montoPagado >= apt.emiCuota) {
        paymentStatus = 'Pagado';
      } else if (apt.montoPagado > 0) {
        paymentStatus = 'Pago Parcial';
      }
      
      return {
        'Apartamento': apt.apartmentNumber,
        'Residente': firstResident,
        'Cuota': apt.emiCuota.toFixed(2),
        'Pagado': apt.montoPagado.toFixed(2),
        'Por Validar': apt.porValidar.toFixed(2),
        'Deuda': apt.deuda.toFixed(2),
        'Estado': paymentStatus
      };
    });
  }

  const ws = utils.json_to_sheet(excelData);
  const wb = utils.book_new();
  utils.book_append_sheet(
    wb, 
    ws, 
    isDetailPage ? 'Detalle de Cuotas' : 'Cuotas de Mantenimiento'
  );
  
  const colWidths: number[] = [];
  excelData.forEach((row: { [key: string]: string }) => {
    Object.keys(row).forEach((k, i) => {
      const width = Math.max(
        (colWidths[i] || 10),
        k.length + 2,
        String(row[k]).length + 2
      );
      colWidths[i] = width;
    });
  });
  
  ws['!cols'] = colWidths.map(width => ({ width }));
  
  const filename = isDetailPage 
    ? `Detalle_Cuotas_${maintenanceId}_${formattedDate}.xlsx`
    : `Cuotas_Mantenimiento_${formattedDate}.xlsx`;
    
  writeFile(wb, filename);
};