// src/features/manualVisitRegistration/components/NewVisitorRegistration.js

import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Snackbar } from '@mui/material';
import PhoneInputComponent from './PhoneInput';
import { manualVisitRegistrationService } from '../services/manualVisitRegistrationService';
import { useManualRegistrationAnalytics } from '../hooks/useManualRegistrationAnalytics';
import useGlobalStore from '../../../state/store';

const NewVisitorRegistration = ({ onSubmit, initialDni = '' }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dni, setDni] = useState(initialDni);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { trackNewVisitorCreated, trackRegistrationFailed } = useManualRegistrationAnalytics();
  const user = useGlobalStore((state) => state.user);


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      const visitorData = {
        first_name: firstName,
        last_name: lastName,
        document_number: dni,
        phone: phone || null,
        email: email || null
      };

      const newVisitor = await manualVisitRegistrationService.createVisitor(visitorData);

      if (user?.id) {
        trackNewVisitorCreated(user.id, {
          ...visitorData,
          id: newVisitor.id
        });
      }
      onSubmit(newVisitor);
    } catch (error) {
      console.error('Error registering new visitor:', error);
      let errorMsg = 'Error al registrar el nuevo visitante. ';
      if (error.code === '23505') {
        errorMsg += 'Este DNI o correo electrónico ya está registrado.';
      } else {
        errorMsg += 'Por favor, intente de nuevo.';
      }
      setErrorMessage(errorMsg);
      if (user?.id) {
        trackRegistrationFailed(user.id, errorMsg, 'new_visitor_creation');
      }
    }
  };

  const handleCloseSnackbar = () => {
    setErrorMessage('');
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography variant="h6" gutterBottom>
        Datos del Nuevo Visitante
      </Typography>
      <TextField
        required
        label="Nombre"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        required
        label="Apellidos"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        required
        label="DNI"
        value={dni}
        onChange={(e) => setDni(e.target.value)}
        fullWidth
        margin="normal"
      />
      <PhoneInputComponent
        value={phone}
        onChange={setPhone}
        error={false}
        helperText=""
      />
      <TextField 
        label="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button 
        type="submit" 
        fullWidth 
        variant="contained" 
        sx={{ mt: 2, borderRadius: '55px', height: '56px' }}
      >
        Registrar Visitante
      </Button>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={errorMessage}
      />
    </Box>
  );
};

export default NewVisitorRegistration;