import { useCallback } from 'react';
import { useAnalytics } from '../../../analytics/hooks/useAnalytics';
import { EVENT_TYPES } from '../../../analytics/config/events';


export const useWaterMeterAnalytics = () => {
    const { track } = useAnalytics();

    const trackMeterDetailViewed = useCallback((meterId: string ) => {
        track(EVENT_TYPES.METER_DETAIL_VIEWED, {
            meterId, 
        });
    }, [track]);

    const trackMeterReadingUpdated = useCallback((userId: string, meterId: string, apartment: string, period: string, buildingId: string, finalReading: number | string) => {
        track(EVENT_TYPES.METER_READING_UPDATED, {
            userId,
            meterId,
            apartment,
            period,
            buildingId, 
            finalReading 
        });
    }, [track]);
 
    const trackMeterReadingCreated = useCallback((userId: string, meterId: string, apartment: string, period: string, buildingId: string, finalReading: number | string) => {
        track(EVENT_TYPES.METER_READING_CREATED, {
            userId,
            meterId,
            apartment,
            period,
            buildingId, 
            finalReading 
        });
    }, [track]);

    const trackMeterExportToExcel = useCallback((userId: string, buildingId: string, period: string, meterCount: number) => {
        track(EVENT_TYPES.METER_EXPORT_TO_EXCEL, {
            userId,
            buildingId,
            period,
            meterCount
        });
    }, [track]);

    const trackMeterImagesDownloadAsZip = useCallback((userId: string, buildingId: string, period: string, imageCount: number,
        downloadTimeMs: number) => {
        track(EVENT_TYPES.METER_IMAGES_DOWNLOAD_AS_ZIP, {
            userId,
            buildingId,
            period,
            imageCount,
            downloadTimeSeconds: downloadTimeMs / 1000
        });
    }, [track]);

    return {
        trackMeterReadingCreated,
        trackMeterDetailViewed,
        trackMeterReadingUpdated, 
        trackMeterExportToExcel,
        trackMeterImagesDownloadAsZip
    };
};