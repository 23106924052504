import { useCallback } from 'react';
import { useAnalytics } from '../../../analytics/hooks/useAnalytics';
import { EVENT_TYPES } from '../../../analytics/config/events';

export const useInvitationAnalytics = () => {
  const { track } = useAnalytics();

  const trackVisitCheckedIn = useCallback((visitId, userId, buildingId) => {
    track(EVENT_TYPES.VISIT_CHECKED_IN, {
      visitId,
      userId,
      buildingId,
      source: 'manual'
    });
  }, [track]);

  const trackVisitDetailsViewed = useCallback((visitId, userId) => {
    track(EVENT_TYPES.VISIT_DETAILS_VIEWED, { visitId, userId });
  }, [track]);

  const trackVisitListFiltered = useCallback((userId, filterType, filterValue) => {
    track(EVENT_TYPES.VISIT_LIST_FILTERED, {
      userId,
      filterType,
      filterValue
    });
  }, [track]);

  const trackVisitListSorted = useCallback((userId, sortField, sortDirection) => {
    track(EVENT_TYPES.VISIT_LIST_SORTED, {
      userId,
      sortField,
      sortDirection
    });
  }, [track]);

  const trackVisitListSearched = useCallback((userId, searchTerm) => {
    track(EVENT_TYPES.VISIT_LIST_SEARCHED, {
      userId,
      searchTerm
    });
  }, [track]);

  const trackVisitTabChanged = useCallback((userId, tabName) => {
    track(EVENT_TYPES.VISIT_TAB_CHANGED, {
      userId,
      tabName
    });
  }, [track]);

  return {
    trackVisitCheckedIn,
    trackVisitDetailsViewed,
    trackVisitListFiltered,
    trackVisitListSorted,
    trackVisitListSearched,
    trackVisitTabChanged,
  };
};