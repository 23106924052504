// src/analytics/config/testConfig.ts

export type Environment = "production" | "development" | "preview" | "unknown";

type TestEmails = {
  DEV: string;
  UI: string;
  QA: string;
  DEMO: string;
  INVITATION: string;
  REGISTER: string;
  RESIDENT: string;
  VISIT: string;
  ADMIN: string;
  SECURITY: string;
  CONCIERGE: string;
};

type Domains = {
  PRODUCTION: string;
  PREVIEW: RegExp;
  LOCAL: RegExp;
};
export interface TestConfig {
  TEST_EMAILS: TestEmails;
  DOMAINS: Domains;
  TEST_EMAIL_PATTERNS: RegExp[];
}

export const TEST_CONFIG = {
  // Email patterns for testing
  TEST_EMAILS: {
    // Core testing purposes
    DEV: "test+admin.dev@appdomus.io",
    UI: "test+admin.ui@appdomus.io",
    QA: "test+admin.qa@appdomus.io",
    DEMO: "test+admin.demo@appdomus.io",
    // Feature specific
    MANUAL_REGISTRATION: "test+admin.registration@appdomus.io",
    VISITS: "test+admin.visits@appdomus.io",
    CHECK_IN: "test+admin.checkin@appdomus.io",
    PREMIUM: "test+admin.premium@appdomus.io",
    // Admin roles
    SUPERVISOR: "test+admin.supervisor@appdomus.io",
    CONCIERGE: "test+admin.concierge@appdomus.io",
    MANAGER: "test+admin.manager@appdomus.io",
    SECURITY: "test+admin.security@appdomus.io",
  },

  // Domains for different environments
  DOMAINS: {
    PRODUCTION: "admin.appdomus.io",
    PREVIEW: /\.vercel\.app/,
    LOCAL: /localhost|127\.0\.0\.1/,
  },

  // Test email patterns to filter
  TEST_EMAIL_PATTERNS: [/@appdomus\.io$/, /^test\+/, /^dev\+/, /^qa\+/],
};

// Helper functions
export const isTestEmail = (email: string | null | undefined): boolean => {
  if (!email) return false;
  return TEST_CONFIG.TEST_EMAIL_PATTERNS.some((pattern) => pattern.test(email));
};

export const getEnvironment = () => {
  // First check if we have an environment variable
  if (import.meta.env.VITE_NODE_ENV) {
    switch (import.meta.env.VITE_NODE_ENV) {
      case "development":
        return "development";
      case "production":
        // Even in production build, we want to check the domain
        break;
      case "test":
        return "test";
      default:
        console.warn(`Unknown NODE_ENV: ${import.meta.env.VITE_NODE_ENV}`);
        return "development";
    }
  }

  // Then check the hostname
  const hostname = window.location.hostname;

  // Explicit checks for each environment
  if (hostname === TEST_CONFIG.DOMAINS.PRODUCTION) {
    return "production";
  }

  if (TEST_CONFIG.DOMAINS.LOCAL.test(hostname)) {
    return "development";
  }

  if (TEST_CONFIG.DOMAINS.PREVIEW.test(hostname)) {
    return "preview";
  }

  // If we're on localhost or 127.0.0.1, it's definitely development
  if (hostname === "localhost" || hostname === "127.0.0.1") {
    return "development";
  }

  // Default to development if we can't determine the environment
  // This is safer than defaulting to production
  console.warn(`Unable to determine environment for hostname: ${hostname}`);
  return "development";
};

// Test email patterns to filter
TEST_CONFIG.TEST_EMAIL_PATTERNS = [
  /@appdomus\.io$/,
  /^test\+admin\./,
  /^dev\+admin\./,
  /^qa\+admin\./,
];
