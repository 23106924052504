// src/features/maintenanceFee/pages/MaintenanceFeeDetail.js

import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import MaintenanceFeeDetailHeader from '../components/maintenanceFeeDetail/MaintenanceFeeDetailHeader';
import MaintenanceFeeDetailStats from '../components/maintenanceFeeDetail/MaintenanceFeeDetailStats';
import EmptyStateAlert from '../components/common/EmptyStateAlert';
import MaintenanceFeeTable from '../components/common/MaintenanceFeeTable';
import MaintenanceFeeDetailTable from '../components/maintenanceFeeDetail/MaintenanceFeeDetailTable';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useMaintenanceFee } from '../hooks/useMaintenanceFees';
import useGlobalStore from '../../../state/store';
import useFilters from '../../../shared/hooks/useFilters';


const MaintenanceFeeDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { getMaintenanceFeeDetails, loading } = useMaintenanceFee();
  const selectedDate = useGlobalStore((state) => state.filters.maintenanceFeesPeriod);

  const maintenanceFee = getMaintenanceFeeDetails(id);
  
  const [searchTerm, setSearchTerm] = useState('');
  const [filterValue, setFilterValue] = useState('Todos');

  if (loading ) {
    return <CircularProgress sx={{ display: 'block', margin: '2rem auto' }} />;
  }

  if (!maintenanceFee && loading === false) {

    return (
      <Typography sx={{ p: 3 }}>
        No se encontró el apartamento o no se proporcionaron datos.
      </Typography>
    );
  }

  const {
    apartmentNumber,
    residentName,
    charges = [],
    payments = [],
    emiCuota,
    montoPagado,
    porValidar,
    deuda,
  } = maintenanceFee;

  const filteredPagos = payments.filter((pago) => {
    const matchesSearch =
      !searchTerm ||
      pago.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      pago.referenceNumber?.toLowerCase().includes(searchTerm.toLowerCase());

    let matchesFilter = true;
    if (filterValue === 'Validados') {
      matchesFilter = pago.status === 'verified';
    } else if (filterValue === 'Pendientes') {
      matchesFilter = pago.status === 'pending';
    }

    return matchesSearch && matchesFilter;
  });

  // Format current month year
  let periodoDate = new Date(`${selectedDate}-01T12:00:00`);
  if (isNaN(periodoDate)) {
    periodoDate = new Date();
  }
  const periodo = format(periodoDate, 'MMMM yyyy', { locale: es });

  return (
    <Box sx={{ padding: 4 }}>
      {/* Header */}
      <Box sx={{ marginBottom: 4 }}>
        <MaintenanceFeeDetailHeader onBack={() => navigate(-1)} />
      </Box>

      {/* Stats Section */}
      <Box sx={{ marginBottom: 4 }}>
        <MaintenanceFeeDetailStats
          apartmentNumber={apartmentNumber}
          residentName={residentName}
          emiCuota={emiCuota}
          montoPagado={montoPagado}
          porValidar={porValidar}
          deuda={deuda}
        />
      </Box>

      {/* EmptyStateAlert if charges array is empty */}
      {charges.length === 0 && (
        <Box sx={{ marginBottom: 4 }}>
          <EmptyStateAlert
            message={`Aún no has añadido los datos correspondientes de la Cuota de Mantenimiento para el mes en curso, ${periodo}.`}
            buttonLabel="Añadir data Cuota de Mantenimiento"
            onButtonClick={() => {
              console.log('Añadir datos de Cuota de Mantenimiento');
            }}
          />
        </Box>
      )}

      {/* MaintenanceFeeTable for search and filter */}
      <Box sx={{ marginBottom: 4 }}>
        <MaintenanceFeeTable
          onSearchChange={setSearchTerm}
          filterValue={filterValue}
          onFilterChange={setFilterValue}
        />
      </Box>

      {/* Charges and Payments Table */}
      <Box>
        <MaintenanceFeeDetailTable 
          charges={charges}
          payments={filteredPagos}
        />
      </Box>
    </Box>
  );
};

export default MaintenanceFeeDetail;