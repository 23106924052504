import { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  MenuItem,
  Select,
  TextField,
  Alert,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SpeedIcon from "@mui/icons-material/Speed";
import { FileSpreadsheet } from 'lucide-react';

import OpacityOutlined from "@mui/icons-material/OpacityOutlined";

import MeterTable, { MeterRow } from "../components/MeterTable";
import MeterDetailDialog from "../components/MeterDetailDialog";
import { useWaterConsumptions } from "../hooks/useWaterConsumptions";

import useFilters from "../../../shared/hooks/useFilters";
import { WaterConsumption } from "../types";
import exportWaterConsumptionsToExcel from "../utils/exportWaterConsumptionsToExcel";
import downloadImagesAsZip from "../utils/downloadImagesAsZip";

import getMonthPeriodOptions from "../../../shared/utils/getMonthPeriodOptions";
import { useGlobalStore } from "../../../state/store";
import { useWaterMeterAnalytics } from "../hooks/useWaterMeterAnalytics";

const MeterMain = () => {
  const [consumptionTotal, setConsumptionTotal] = useState("");
  const [valorPagado, setValorPagado] = useState("");
  const [costPerM3, setCostPerM3] = useState(0);

  const [selectedMeter, setSelectedMeter] = useState<WaterConsumption | null>(
    null
  );
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const { filters, setFilters } = useFilters();
  const buildingId = useGlobalStore((state) => state.buildingId);
  const userId = useGlobalStore((state) => state.user?.id);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [isZipModalOpen, setIsZipModalOpen] = useState(false);
  const [zipStatus, setZipStatus] = useState<"preparing" | "empty" | "error" | "completed">("preparing");
  const [isDownloading, setIsDownloading] = useState(false);
  const [zipProgress, setZipProgress] = useState({ current: 0, total: 0 });

  const waterConsumptionPeriod = filters.waterConsumptionPeriod;
  const { trackMeterReadingUpdated, trackMeterDetailViewed, trackMeterExportToExcel, trackMeterImagesDownloadAsZip } = useWaterMeterAnalytics();

  const {
    waterConsumptions,
    loading,
    error,
    updateWaterConsumption,
    hasCachedWaterConsumptions,
    getWaterConsumptions,
  } = useWaterConsumptions();

  // Recalcular el costo por m³ cada vez que cambien consumo total o valor pagado
  useEffect(() => {
    const ct = parseFloat(consumptionTotal);
    const vp = parseFloat(valorPagado);

    if (!isNaN(ct) && !isNaN(vp) && ct > 0) {
      setCostPerM3(vp / ct);
    } else {
      setCostPerM3(0);
    }
  }, [consumptionTotal, valorPagado]);

  const handleDateChange = (waterConsumptionPeriod: string) => {
    setFilters({ waterConsumptionPeriod });

    if (waterConsumptionPeriod && buildingId) {
      getWaterConsumptions(waterConsumptionPeriod, buildingId);
    }
  };

  const handleSaveChanges = async (updatedMeter: MeterRow) => {
    const waterConsumption: WaterConsumption = {
      ...updatedMeter,
      consumption: updatedMeter.consumption ?? 0,
    };

    if (buildingId) {
      await updateWaterConsumption({
        waterConsumption,
        waterConsumptionPeriod,
        buildingId,
      });
      trackMeterReadingUpdated(
        userId!,
        updatedMeter.id,
        updatedMeter.apartment,
        updatedMeter.period,
        buildingId, 
        updatedMeter.final
      );
    }

    setIsDetailOpen(false);
  };

  const handleDetailOpen = (meter: MeterRow) => {
    setSelectedMeter({
      ...meter,
      consumption: meter.consumption ?? 0,
    });
    setIsDetailOpen(true);
    if (buildingId) {
      trackMeterDetailViewed(
        meter.id, 
      );
    }
  };

  useEffect(() => {
    if (!hasCachedWaterConsumptions && buildingId) {
      if (waterConsumptionPeriod) {
        getWaterConsumptions(waterConsumptionPeriod, buildingId);
      }
    }
  }, [buildingId, waterConsumptionPeriod]);

  const handleDetailClose = () => {
    setSelectedMeter(null);
    setIsDetailOpen(false);
  };

  const monthPeriods = getMonthPeriodOptions();

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleExcelDownload = () => {
    exportWaterConsumptionsToExcel(waterConsumptions);
    handleCloseMenu();

    if (buildingId) {
      trackMeterExportToExcel(
        userId!,
        buildingId,
        filters.waterConsumptionPeriod,
        waterConsumptions.length
      );
    }
  };

  const handleImageDownload = async () => {
    handleCloseMenu();
    setIsZipModalOpen(true);
    setIsDownloading(true);
    setZipStatus("preparing");
    const downloadStartTime = Date.now();

    try {
      const result = await downloadImagesAsZip(
        waterConsumptions,
        waterConsumptionPeriod,
        (progress) => setZipProgress(progress),
        (status) => setZipStatus(status)
      );

      if (result.status === "empty" || result.status === "error") {
        setIsDownloading(false);
      } else if (result.status === "completed") {
        const downloadTime = Date.now() - downloadStartTime;
        setTimeout(() => {
          setIsZipModalOpen(false);
          setIsDownloading(false);
        }, 1500);
        if (buildingId) {
           trackMeterImagesDownloadAsZip(
            userId!,
            buildingId,
            waterConsumptionPeriod,
            result.totalImages!,
            downloadTime

          );
        }
      }
    } catch (error) {
      console.error("Error in zip download process:", error);
      setZipStatus("error");
      setIsDownloading(false);
    }
  };

  const handleCloseZipModal = () => {
    setIsZipModalOpen(false);
  };

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      {/* Header: COntadores */}
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        gap={1}
      >
        <OpacityOutlined sx={{ fontSize: 32, color: "#315CAC" }} />
        <Typography
          sx={{ fontSize: "20px", fontWeight: 600, color: "#315CAC" }}
        >
          Contadores
        </Typography>
      </Box>

      {/* SELECTOR DE period important :D */}
      <Box
        mt={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: 0,
            top: "50%",
            height: "1px",
            width: "40%",
            backgroundColor: "#E5E7EB",
            transform: "translateY(-50%)",
          }}
        />
        <Select
          value={waterConsumptionPeriod}
          onChange={(e) => handleDateChange(e.target.value)}
          IconComponent={ArrowDropDownIcon}
          sx={{
            textAlign: "center",
            minWidth: 150,
            fontSize: "14px",
            fontWeight: 500,
            color: "#315CAC",
            border: "1px solid #D1D5DB",
            borderRadius: "24px",
            padding: "4px 12px",
            backgroundColor: "white",
            "& .MuiSelect-icon": {
              color: "#315CAC",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:hover": {
              backgroundColor: "#F3F4F6",
            },
          }}
        >
          {monthPeriods.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {/* Línea derecha */}
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: "50%",
            height: "1px",
            width: "40%",
            backgroundColor: "#E5E7EB",
            transform: "translateY(-50%)",
          }}
        />
      </Box>

      {/* SECCIÓN EDIFICIO: Consumo total, Valor pagado y Costo por m³ */}
      <Box mt={3}>
        <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
          Edificio
        </Typography>
        <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={2}>
          <TextField
            label="Consumo total (m³)"
            variant="outlined"
            size="small"
            type="number"
            value={consumptionTotal}
            onChange={(e) => setConsumptionTotal(e.target.value)}
          />
          <TextField
            label="Valor pagado (S/)"
            variant="outlined"
            size="small"
            type="number"
            value={valorPagado}
            onChange={(e) => setValorPagado(e.target.value)}
          />
          <TextField
            label="Costo por m³"
            variant="outlined"
            size="small"
            type="number"
            value={costPerM3.toFixed(2)}
            InputProps={{ readOnly: true }}
          />
        </Box>
      </Box>

      {/* BOTÓN EXPORTAR */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: 3 }}>
        <Button
          variant="contained"
          onClick={handleOpenMenu}

        >
          Descargar
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          sx={{
            mt: 2,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}

        >
          <MenuItem onClick={handleExcelDownload} >
            <Box sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
              <FileSpreadsheet size={20} color="#217346" />
            </Box>
            <Typography>Lecturas en Excel</Typography>
          </MenuItem>
          <MenuItem onClick={handleImageDownload}>
            <Box sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
              <SpeedIcon />
            </Box>
            <Typography>Imágenes medidores ZIP</Typography>
          </MenuItem>
        </Menu>
      </Box>

      <MeterTable
        onDetailOpen={handleDetailOpen}
        costPerM3={costPerM3}
        selectedDate={waterConsumptionPeriod}
        meters={waterConsumptions}
        loading={loading}
      />

      {/* DIALOGO DE DETALLES */}
      <MeterDetailDialog
        open={isDetailOpen}
        meter={selectedMeter}
        onClose={handleDetailClose}
        onSaveChanges={handleSaveChanges}
      />

      <Dialog
        open={isZipModalOpen}
        onClose={isDownloading ? undefined : handleCloseZipModal}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          {zipStatus === "preparing" ? "Preparando tu archivo ZIP..." :
            zipStatus === "completed" ? "Archivo ZIP completado" :
              zipStatus === "empty" ? "No hay imágenes disponibles" :
                "Error al crear el archivo ZIP"}
        </DialogTitle>
        <DialogContent>
          {zipStatus === "preparing" ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 2 }}>
              <Typography sx={{ mb: 4 }}>
                Esto puede tardar unos segundos. No cierres esta ventana ni tu navegador.
              </Typography>
              {zipProgress.total > 0 && (
                <>
                  <Box sx={{ width: '100%', mb: 2 }}>
                    <LinearProgress
                      variant="determinate"
                      value={(zipProgress.current / zipProgress.total) * 100}
                      sx={{ height: 10, borderRadius: 5 }}
                    />
                  </Box>
                  <Typography sx={{ fontWeight: 'medium', mb: 2 }}>
                    {zipProgress.current} / {zipProgress.total}
                  </Typography>
                </>
              )}

            </Box>
          ) : zipStatus === "completed" ? (
            <Typography>
              El archivo ZIP se ha generado y descargado correctamente.
            </Typography>
          ) : zipStatus === "empty" ? (
            <Typography>
              No se encontraron imágenes de medidores para este período. Por favor, asegúrate de que existan imágenes asociadas a los apartamentos.
            </Typography>
          ) : (
            <Typography color="error">
              Ha ocurrido un error al crear el archivo ZIP. Por favor, inténtalo nuevamente más tarde.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseZipModal}
            variant="contained"
            disabled={isDownloading}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default MeterMain;