import { useState, useEffect, useCallback } from 'react';
import useGlobalStore from '../../../state/store';
import { secureApi } from '../../../supabase/secureApi';

export interface Resident {
  id: string;
  name: string;
  phone: string;
  isPrimary: boolean;
}

export interface ApartmentDebt {
  id: string;
  apartmentNumber: string;
  residentName: string;
  totalDebt: number;
  currentDebt: number;
  historicalDebt: number;
  lastPaymentDate: string | null;
  monthsOfDebt: number;
  residents: Resident[];
  charges: Charge[];
  payments: Payment[];
}

interface Apartment {
  id: string;
  apartment_number: string;
  residents?: Array<{
    resident: {
      id?: string;
      first_name: string;
      last_name: string;
      phone?: string;
    },
    is_active: boolean
  }>;
}

interface Charge {
  id: string;
  total_amount: number;
  status: string;
  due_date: string;
  issue_date: string;
  description?: string;
  charge_type: string;
  apartment_id: string;
}

interface Payment {
  id: string;
  amount: number;
  payment_date: string;
  payment_method: string;
  status: string;
  apartment_id: string;
}

const formatPhoneNumber = (phone: string | undefined): string => {
  if (!phone) return '';
  
  // Remove any non-digit characters except + at the start
  const cleaned = phone.replace(/[^\d+]/g, '');
  
  // If it already starts with +, return as is
  if (cleaned.startsWith('+')) {
    return cleaned;
  }
  
  // If it starts with a country code (2-3 digits), add +
  if (/^[1-9]\d{1,2}/.test(cleaned)) {
    return `+${cleaned}`;
  }
  
  // If no country code is present, assume Peru (+51)
  return `+51${cleaned}`;
};

export const usePendingCharges = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [apartmentDebts, setApartmentDebts] = useState<ApartmentDebt[]>([]);
  const buildingId = useGlobalStore((state) => state.buildingId);

  const fetchPendingCharges = useCallback(async () => {
    if (!buildingId) return;
    
    setLoading(true);
    setError(null);
    
    try {
      // 1. Fetch all apartments for the building
      const apartments = await secureApi.get<Apartment[]>("apartments", {
        select: `
          id,
          apartment_number,
          residents:resident_apartments (
            resident:users (
              id,
              first_name,
              last_name,
              phone
            ),
            is_active
          )
        `,
        filter: {
          building_id: buildingId,
          is_active: true,
        },
      });
      
      // 2. Fetch all pending charges for the building
      const pendingCharges = await secureApi.get<Charge[]>("charges", {
        select: `
          id,
          total_amount,
          status,
          due_date,
          issue_date,
          description,
          charge_type,
          apartment_id
        `,
        filter: {
          building_id: buildingId,
          status: "pending",
          is_active: true,
        },
      });
      
      // 3. Fetch all verified payments (potentially offsetting the charges)
      const verifiedPayments = await secureApi.get<Payment[]>("payments", {
        select: `
          id,
          amount,
          payment_date,
          payment_method,
          status,
          apartment_id
        `,
        filter: {
          building_id: buildingId,
          status: "verified",
          is_active: true,
        },
        order: { payment_date: "desc" },
      });
      
      // 4. Process data to calculate debt by apartment
      const apartmentsMap = new Map<string, ApartmentDebt>();
      
      // Initialize apartment data
      apartments.forEach(apt => {
        // Only use active resident_apartments relationships
        const activeResidents = apt.residents ? apt.residents.filter(r => r.is_active !== false) : [];
        
        const residentName = activeResidents.length > 0
          ? `${activeResidents[0].resident.first_name} ${activeResidents[0].resident.last_name}`
          : "Sin residente";
          
        // Process residents data - Filter out any without valid IDs
        let processedResidents: Resident[] = activeResidents
          .filter(r => r.resident.id) 
          .map((r, index) => ({
            id: r.resident.id as string,
            name: `${r.resident.first_name} ${r.resident.last_name}`,
            phone: formatPhoneNumber(r.resident.phone),
            isPrimary: index === 0
          })) || [];
        
        // If we have a resident name but no resident records, create a placeholder resident
        // so users can send WhatsApp notifications
        if (processedResidents.length === 0 && residentName !== "Sin residente") {
          // Split the resident name into first name and last name
          const nameParts = residentName.split(' ');
          const firstName = nameParts[0] || '';
          const lastName = nameParts.slice(1).join(' ') || '';
          
          processedResidents = [{
            id: `temp-${apt.id}-${uuidv4().substring(0, 8)}`,
            name: residentName,
            // Add a placeholder phone that can be edited later
            phone: '',
            isPrimary: true
          }];
        }
        
        apartmentsMap.set(apt.id, {
          id: apt.id,
          apartmentNumber: apt.apartment_number,
          residentName,
          residents: processedResidents,
          totalDebt: 0,
          currentDebt: 0,
          historicalDebt: 0,
          charges: [],
          payments: [],
          monthsOfDebt: 0,
          lastPaymentDate: null
        });
      });
      
      // Add charges to apartments
      const chargesByMonth = new Map<string, Set<string>>();
      
      pendingCharges.forEach(charge => {
        const apartmentId = charge.apartment_id;
        const apartment = apartmentsMap.get(apartmentId);
        
        if (apartment) {
          // Add to total debt
          apartment.charges.push(charge);
          apartment.totalDebt += parseFloat(charge.total_amount.toString());
          
          // Track charges by month to calculate months of debt
          const month = charge.issue_date.substring(0, 7); // YYYY-MM
          if (!chargesByMonth.has(apartmentId)) {
            chargesByMonth.set(apartmentId, new Set());
          }
          chargesByMonth.get(apartmentId)?.add(month);
          
          // Check if this is from current month (to separate current vs historical)
          const now = new Date();
          const currentMonth = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}`;
          
          if (charge.issue_date.startsWith(currentMonth)) {
            apartment.currentDebt += parseFloat(charge.total_amount.toString());
          } else {
            apartment.historicalDebt += parseFloat(charge.total_amount.toString());
          }
        }
      });
      
      // Calculate months of debt for each apartment
      chargesByMonth.forEach((months, apartmentId) => {
        const apartment = apartmentsMap.get(apartmentId);
        if (apartment) {
          apartment.monthsOfDebt = months.size;
        }
      });
      
      // Add payments to apartments and handle last payment date
      verifiedPayments.forEach(payment => {
        const apartmentId = payment.apartment_id;
        const apartment = apartmentsMap.get(apartmentId);
        
        if (apartment) {
          apartment.payments.push(payment);
          
          // Set last payment date if not already set or if this payment is more recent
          if (!apartment.lastPaymentDate || 
              new Date(payment.payment_date) > new Date(apartment.lastPaymentDate)) {
            apartment.lastPaymentDate = payment.payment_date;
          }
        }
      });
      
      // Convert map to array for state management
      const debtsArray = Array.from(apartmentsMap.values());
      
      setApartmentDebts(debtsArray);
    } catch (err) {
      console.error("Error fetching pending charges:", err);
      setError("Error al cargar los datos de deudas");
    } finally {
      setLoading(false);
    }
  }, [buildingId]);
  
  useEffect(() => {
    fetchPendingCharges();
  }, [fetchPendingCharges]);
  
  return {
    apartmentDebts,
    loading,
    error,
    refetch: fetchPendingCharges
  };
};