import { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  SelectChangeEvent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/es";

import { supabase } from "../../../../supabase/client";
import useGlobalStore from "../../../../state/store";
import { MaintenanceFee } from "../../types";
import { useMaintenanceFee } from "../../hooks/useMaintenanceFees";
import { useMaintenanceFeeAnalytics } from "../../hooks/useMaintenanceFeeAnalytics";

interface AddIndividualChargeModalProps {
  open: boolean;
  onClose: () => void;
}

export default function AddIndividualChargeModal({
  open,
  onClose,
}: AddIndividualChargeModalProps) {
  const { maintenanceFees } = useMaintenanceFee();
  const buildingId = useGlobalStore((state) => state.buildingId);
  const {   setMaintenanceFees } = useGlobalStore();
  const period = useGlobalStore((state) => state.filters.maintenanceFeesPeriod);
  const userId = useGlobalStore((state) => state.user?.id);
  const { trackChargeCreated } = useMaintenanceFeeAnalytics();
 
  const [formData, setFormData] = useState({
    dptoId: "",
    dpto: "",
    residente: "",
    monto: "",
    fecha: dayjs(),
  });
  const [loading, setLoading] = useState(false);

  const handleApartmentChange = (event: SelectChangeEvent<string>) => {
    const selectedApartment = maintenanceFees.find(
      (apartment: any) => apartment.apartmentNumber === event.target.value
    );
    if (selectedApartment) {
      setFormData({ ...formData, dpto: event.target.value as string, dptoId: selectedApartment.id });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date: Dayjs | null) => {
    setFormData({ ...formData, fecha: date || dayjs() });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      const { data, error } = await supabase
        .from("charges")
        .insert([
          {
            apartment_id: formData.dptoId,
            issue_date: formData.fecha.format("YYYY/MM/DD"),
            due_date: formData.fecha.format("YYYY/MM/DD"),
            total_amount: formData.monto,
            status: "pending",
            last_reminder_sent: null,
            transaction_id: null,
            created_by: userId,
            is_active: true,
            description: "Individual charge",
            charge_type: "maintenance_fee",
            building_id: buildingId,
          },
        ])
        .select();

      if (error) {
        console.error("Error al insertar:", error);
        return;
      }

      if((data && data.length > 0) && (period === formData.fecha.format("YYYY-MM"))) {
        const newCharge = {
          id: data[0].id,
          apartmentNumber: formData.dpto,
          apartmentId: formData.dptoId,
          total_amount: parseFloat(formData.monto),
          status: "pending",
          issue_date: formData.fecha.format("YYYY/MM/DD"),
          due_date: formData.fecha.format("YYYY/MM/DD"),
          description: "Individual charge",
          charge_type: "maintenance_fee",
          payments: [],
        };
        
        const updatedFees = [...maintenanceFees];
        const apartmentIndex = updatedFees.findIndex(fee => fee.id === formData.dptoId);
        
        if (apartmentIndex >= 0) {
          const updatedApartment = {
            ...updatedFees[apartmentIndex],
            charges: [...(updatedFees[apartmentIndex].charges || []), newCharge],
            emiCuota: (updatedFees[apartmentIndex].emiCuota || 0) + parseFloat(formData.monto),
            deuda: (updatedFees[apartmentIndex].deuda || 0) + parseFloat(formData.monto),
          };
          
          updatedFees[apartmentIndex] = updatedApartment;
        }
        
        setMaintenanceFees({ data: updatedFees });

      }

      if(data && data.length > 0) {
        trackChargeCreated(userId!, data[0].id, 
          {
          amount: formData.monto,
          apartment: formData.dpto,
          chargeType: "individual"
        });
      }
    } catch (error) {
      console.error("Error en la ejecución del handleSubmit:", error);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  useEffect(() => {
    dayjs.locale("es");
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{ sx: { borderRadius: "24px" } }}
    >
      <DialogTitle variant="h5" fontWeight="bold">
        Añadir Cargo
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
        <Grid marginTop={1} container spacing={3} xs={6}>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="departamento-label">Departamento</InputLabel>
              <Select
                labelId="departamento-label"
                value={formData.dpto}
                onChange={handleApartmentChange}
                label="Departamento"
                style={{ backgroundColor: "white", borderRadius: "16px" }}
              >
                {maintenanceFees.map((apartment: any) => (
                  <MenuItem key={apartment.id} value={apartment.apartmentNumber}>
                    {apartment.apartmentNumber}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Monto a Pagar"
              name="monto"
              fullWidth
              value={formData.monto}
              onChange={handleInputChange}
              variant="outlined"
              type="number"
              InputProps={{
                inputMode: "numeric",
                style: {
                  MozAppearance: "textfield",
                },
              }}
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                  display: "none",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
              <DatePicker
                label="Fecha"
                value={formData.fecha}
                onChange={handleDateChange}
                format="DD/MM/YYYY"
                slotProps={{
                  textField: {
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid marginTop={1} container spacing={3} xs={6}>
          <Grid item xs={12}>
            <TextField
              label="Residente"
              name="residente"
              fullWidth
              value={formData.residente}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ padding: "16px 24px", paddingTop: 0 }}>
        <Button onClick={onClose} disabled={loading}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={
            loading || !formData.dpto || !formData.fecha || !formData.monto || !formData.residente
          }
        >
          {loading ? "Procesando..." : "Subir Cargo"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
