import { useRef, useState } from "react";
import { Button, Box, Typography, ListItem, ListItemText, List } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useGlobalStore from "../../../state/store";
import { validateFile } from "../utils";
import { MaintenanceFeeFileUpload } from "../components/maintenanceFeeImport/MaintenanceFeeFileUpload";
import { ChevronRight } from "@mui/icons-material";

export default function MaintenanceImportStep1() {
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const setFileData = useGlobalStore((state) => state.setFileData);

  const [error, setError] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);

  const handleClick = () => {
    setError(null);
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : null;
    if (!selectedFile) {
      setError("No se ha subido ningún archivo.");
      return;
    }

    validateFile(selectedFile)
      .then((fileData) => {
        setFileData(fileData);
        setFile(selectedFile);
      })
      .catch((error: any) => {
        setError(error.message);
      });
  };

  const handleFileClear = () => {
    setFile(null);
    if (fileInputRef.current?.value) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 30 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                width: 32,
                height: 32,
                backgroundColor: "#2563eb",
                color: "#fff",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mr: 1,
              }}
            >
              1
            </Box>
            <Typography fontWeight="500">Preparar</Typography>
          </Box>
          <ChevronRight sx={{ mx: 2, color: "#d1d5db" }} />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                width: 32,
                height: 32,
                backgroundColor: "#D9E2FF",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mr: 1,
              }}
            >
              2
            </Box>
            <Typography fontWeight="500">Verificar</Typography>
          </Box>
        </Box>

        <Button color="inherit" onClick={() => navigate("/maintenance-fee")}>
          Cancelar
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" fontWeight="bold" color="#305CAB">
          Importar información Cuota de Mantenimiento
        </Typography>
      </Box>

      <Box>
        <Typography color="text" marginBottom="8px">
          El archivo debe contener al menos las siguientes columnas:
        </Typography>
        <List disablePadding sx={{ listStyleType: "disc" }}>
          <ListItem sx={{ display: "list-item", padding: 0, marginLeft: "20px" }}>
            <ListItemText
              primary={
                <Typography>
                  <strong>Departamento:</strong> Número o identificación del departamento.
                </Typography>
              }
            />
          </ListItem>
          <ListItem sx={{ display: "list-item", padding: 0, marginLeft: "20px" }}>
            <ListItemText
              primary={
                <Typography>
                  <strong>Residente:</strong> Nombre o identificación del residente.
                </Typography>
              }
            />
          </ListItem>
          <ListItem sx={{ display: "list-item", padding: 0, marginLeft: "20px" }}>
            <ListItemText
              primary={
                <Typography>
                  <strong>Monto a pagar:</strong> Valor de la cuota correspondiente.
                </Typography>
              }
            />
          </ListItem>
          <ListItem sx={{ display: "list-item", padding: 0, marginLeft: "20px" }}>
            <ListItemText
              primary={
                <Typography>
                  <strong>Mes:</strong> Periodo al que corresponde la cuota.
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Box>

      <Box display="flex" alignItems="center" gap={8} justifyContent="space-evenly">
        <img src="/excel.png" alt="Excel Icon" style={{ width: 48, height: 48, marginRight: 8 }} />
        <Typography fontWeight="bold" sx={{ color: "#315CAC" }}>
          2024 domus template
          <br />
          cuota de mantenimiento
        </Typography>
        <Typography color="text.secondary" flex={1}>
          Descarga nuestra plantilla de Excel predefinida y fácil de usar para ingresar los datos de
          forma rápida y organizada.
        </Typography>

        <Button
          href={`${
            import.meta.env.VITE_STORAGE_ENDPOINT
          }/object/sign/storage/files/cuota_de_mantenimiento.xlsx?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzdG9yYWdlL2ZpbGVzL2N1b3RhX2RlX21hbnRlbmltaWVudG8ueGxzeCIsImlhdCI6MTc0MDE1ODgxMywiZXhwIjo0ODkzNzU4ODEzfQ.MFmQ-rzYKzemBKrY7W67mYKbK_d6jpbbpgiw7ql8594`}
          download="Cuota de Mantenimiento"
          variant="contained"
          style={{
            marginLeft: "auto",
            color: "#315CAC",
            backgroundColor: "#E9EDFF",
            fontWeight: "bold",
          }}
        >
          Descargar
        </Button>
      </Box>

      <MaintenanceFeeFileUpload
        file={file}
        onFileChange={handleFileChange}
        onFileClear={handleFileClear}
        onFileClick={handleClick}
        fileInputRef={fileInputRef}
      />

      <Box textAlign="right">
        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
        <Button
          variant="contained"
          disabled={!file || !!error}
          color="primary"
          sx={{ fontWeight: "bold" }}
          onClick={() => {
            if (!fileInputRef.current?.files?.length) {
              setError("Por favor, sube un archivo antes de continuar.");
              return;
            }
            if (!error) {
              navigate(`/maintenance-fee/import/2`);
            }
          }}
        >
          Siguiente
        </Button>
      </Box>
    </div>
  );
}
