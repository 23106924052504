// src/features/maintenanceFee/services/maintenanceFeeService.js

import { secureApi } from "../../../supabase/secureApi";
import { startOfMonth, endOfMonth, format } from "date-fns";
import useGlobalStore from "../../../state/store";
import { useMaintenanceFeeAnalytics } from "../hooks/useMaintenanceFeeAnalytics";

//const { trackPaymentAdded, trackPaymentReconciled } = useMaintenanceFeeAnalytics();

export const maintenanceFeeService = {
  async fetchApartmentsByBuildingId(buildingId) {
    try {
      const apartments = await secureApi.get("apartments", {
        select: `
          id,
          apartment_number,
          residents:resident_apartments (
            resident:users (
              first_name,
              last_name
            ),
            is_active
          )
        `,
        filter: {
          building_id: buildingId,
          is_active: true,
        },
      });

      return apartments.map((apartment) => ({
        id: apartment.id,
        apartmentNumber: apartment.apartment_number,
        residents: apartment.residents
          ?.filter(r => r.is_active !== false)
          .map((r) => r.resident) || [],
      }));
    } catch (error) {
      console.error("Error fetching apartments:", error);
      throw error;
    }
  },

  async fetchMaintenanceData(buildingId, selectedDate) {
    try {
      const selectedDateObj = new Date(`${selectedDate}-01T12:00:00`);
      if (isNaN(selectedDateObj)) {
        throw new Error("Invalid selected date");
      }

      const startDate = format(startOfMonth(selectedDateObj), "yyyy-MM-dd");
      const endDate = format(endOfMonth(selectedDateObj), "yyyy-MM-dd");

      const charges = await secureApi.get("charges", {
        select: `
          id,
          total_amount,
          status,
          due_date,
          issue_date,
          description,
          charge_type,
          apartment:apartments (
            id,
            apartment_number
          )
        `,
        filter: {
          building_id: buildingId,
          "issue_date.gte": startDate,
          "issue_date.lte": endDate,
        },
      });

      if (!charges) return { charges: [] };

      return {
        charges: charges.map((charge) => ({
          id: charge.id,
          apartmentNumber: charge.apartment?.apartment_number,
          total_amount: parseFloat(charge.total_amount),
          status: charge.status,
          due_date: charge.due_date,
          issue_date: charge.issue_date,
          description: charge.description,
          charge_type: charge.charge_type,
          payments:
            charge.payments?.map((payment) => ({
              id: payment.id,
              amount: parseFloat(payment.amount),
              status: payment.status,
            })) || [],
        })),
      };
    } catch (error) {
      console.error("Error fetching maintenance data:", error);
      throw error;
    }
  },

  // Updated fetchPayments method to include apartment_id
  async fetchPayments(buildingId, filters = {}) {
    try {
      // Define default date range if not provided
      let { startDate, endDate } = filters;
      if (!startDate || !endDate) {
        const today = new Date();
        startDate = format(startOfMonth(today), "yyyy-MM-dd");
        endDate = format(endOfMonth(today), "yyyy-MM-dd");
      }

      const payments = await secureApi.get("payments", {
        select: `
          id,
          apartment_id,
          amount,
          payment_date,
          payment_method,
          bank_name,
          reference_number,
          evidence_url,
          evidence_path,
          status,
          verified_by,
          verified_at,
          notes,
          transaction_id,
          created_at
        `,
        filter: {
          building_id: buildingId,
          "payment_date.gte": startDate,
          "payment_date.lte": endDate,
          is_active: true,
        },
        order: { payment_date: "desc" }, // Optional: order payments by date
      });

      if (!payments) return { payments: [] };

      return {
        payments: payments.map((payment) => ({
          id: payment.id,
          apartmentId: payment.apartment_id,
          amount: parseFloat(payment.amount),
          paymentDate: payment.payment_date,
          paymentMethod: payment.payment_method,
          bankName: payment.bank_name,
          referenceNumber: payment.reference_number,
          evidenceUrl: payment.evidence_url,
          evidencePath: payment.evidence_path,
          status: payment.status,
          verifiedBy: payment.verified_by,
          verifiedAt: payment.verified_at,
          notes: payment.notes,
          transactionId: payment.transaction_id,
          createdAt: payment.created_at,
        })),
      };
    } catch (error) {
      console.error("Error fetching payments:", error);
      throw error;
    }
  },

  async createPayment(buildingId, paymentData) {
    try {
      if (!buildingId || !paymentData.apartment_id) {
        throw new Error("Missing required payment data");
      }
      const userId = useGlobalStore.getState().user.id;

      const result = await secureApi.post("payments", {
        ...paymentData,
        building_id: buildingId,
        is_active: true,
        created_at: new Date().toISOString(),
      });

      // trackPaymentAdded(userId, result.id, {
      //   amount: paymentData.amount,
      //   method: paymentData.payment_method,
      //   apartmentId: paymentData.apartment_id
      // });

      return result;
    } catch (error) {
      console.error("Error creating payment:", error);
      throw error;
    }
  },

  async updatePaymentStatus(paymentId, status) {
    try {
      console.log(`Updating payment ID: ${paymentId} to status: ${status}`); // Debug log
      const userId = useGlobalStore.getState().user.id; // Get user ID from the store
      const result = await secureApi.update("payments", paymentId, {
        status,
        verified_by: userId,
        verified_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      });
      //trackPaymentReconciled(userId, paymentId, status);
      console.log("Update result:", result); // Debug log
      return result;
    } catch (error) {
      console.error("Error updating payment status:", error);
      throw error;
    }
  },
};
