import React, { useState, useEffect } from "react";
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography,
  Box, Grid, TextField
} from "@mui/material";
import { MeterRow } from "./MeterTable";
import MeterImage from "./MeterImage";

interface MeterDetailDialogProps {
  open: boolean;
  meter: MeterRow | null;
  onClose: () => void;
  onSaveChanges: (updatedMeter: MeterRow) => void;
}

const MeterDetailDialog: React.FC<MeterDetailDialogProps> = ({ open, meter, onClose, onSaveChanges }) => {
  const [finalReading, setFinalReading] = useState<string>("");
  const [updatedImageUrl, setUpdatedImageUrl] = useState<string | undefined>("");

  useEffect(() => {
    if (meter) {
      setFinalReading(typeof meter.final === "number" ? meter.final.toFixed(3) : "");
      setUpdatedImageUrl(meter.image_url);
    }
  }, [meter]);

  if (!meter) return null;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (inputValue === "" || /^[0-9]+$/.test(inputValue) || /^[0-9]+\.[0-9]*$/.test(inputValue) || /^[0-9]*\.[0-9]+$/.test(inputValue)) {
      setFinalReading(inputValue);
    }
  };

  const handleSave = () => {
    if (finalReading === "") {
      return;
    }
    
    const numericValue = parseFloat(finalReading);
    const calculatedConsumption = typeof meter.initial === "number" ? Math.abs(numericValue - meter.initial) : 0;

    onSaveChanges({
      ...meter,
      final: numericValue,
      consumption: Math.round(calculatedConsumption * 1000) / 1000,
      image_url: updatedImageUrl,
    });
    onClose();
  };

  const calculateConsumption = () => {
    const hasInitialReading = typeof meter.initial === "number" && meter.initial !== null;
    if (!hasInitialReading || finalReading === "") return "0.000";
    const calculated = Math.abs(parseFloat(finalReading) - (meter.initial as number));
    return calculated.toFixed(3);
  };

  const hasInitialReading = typeof meter.initial === "number" && meter.initial !== null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Detalle Registro de Lectura</DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="textSecondary">
          Fecha de registro: {meter.created_at
            ? new Date(meter.created_at).toLocaleString("es-ES")
            : "No registrado"}
        </Typography>

        <Box mt={2}>
          <Typography variant="h6" gutterBottom>Información de lectura</Typography>
          <Box sx={{ border: "1px solid #e0e0e0", borderRadius: "8px", padding: "16px", backgroundColor: "#f9f9f9" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}><Typography variant="body1"><strong>Departamento:</strong> {meter.apartment}</Typography></Grid>
              <Grid item xs={12}><Typography variant="body1"><strong>Período:</strong> {meter.period}</Typography></Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <strong>Lectura anterior:</strong> {hasInitialReading ? `${parseFloat(meter.initial.toString()).toFixed(3)} m³` : "No agregado"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1"><strong>Lectura actual:</strong></Typography>
                <TextField
                  fullWidth 
                  variant="outlined" 
                  size="small" 
                  value={finalReading} 
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: <Typography variant="body2" color="textSecondary" sx={{ ml: 1 }}>m³</Typography>
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <strong>Consumo:</strong> {calculateConsumption()}{hasInitialReading && finalReading !== "" ? " m³" : ""}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <MeterImage 
          imageUrl={updatedImageUrl} 
          onImageChange={setUpdatedImageUrl} 
          meterInfo={{ apartment: meter.apartment, period: meter.period }} 
        />
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={handleSave} 
          color="primary" 
          variant="contained"
          disabled={finalReading === ""}
        >
          Guardar
        </Button>
        <Button onClick={onClose} color="primary" variant="outlined">Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MeterDetailDialog;