import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
  Avatar,
} from "@mui/material";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

// Bank and digital wallet logos
const BANK_LOGOS = {
  "Interbank": "https://play-lh.googleusercontent.com/vjYx0jloYA0BSr6fHPhvhhIbgieH0jmOV3fv_evGkj9bxxjITNO3Yhfux77bq2_HvBIF=w480-h960-rw",
  "BCP": "https://play-lh.googleusercontent.com/gBpVaCpZsbBrLufT06aRpuLQvsUq1KAZUCEof_ps76mtB8_llJg3xv24mey8I0m3dUE=w480-h960-rw",
  "Scotiabank": "https://play-lh.googleusercontent.com/bX-2nxLIzRoDZBf_wMVI_VIB8NgnochPebM8aDMGe3wqEYz6dSQZsJjZ4F5j8OMM86S2=w480-h960-rw",
  "BBVA": "https://play-lh.googleusercontent.com/ZsRXxnAaNfWkGh0znnApV1d2BnTysMJVgZSAG5i4xX5c3weg6C0IGr6rtkqWMrXZriA=w480-h960-rw",
  // Digital wallets
  "Yape": "https://play-lh.googleusercontent.com/y5S3ZIz-ohg3FirlISnk3ca2yQ6cd825OpA0YK9qklc5W8MLSe0NEIEqoV-pZDvO0A8=w480-h960-rw",
  "Plin": "https://scontent-lim1-1.xx.fbcdn.net/v/t39.30808-6/358694396_784669073456305_5517374345124528079_n.png?_nc_cat=104&ccb=1-7&_nc_sid=6ee11a&_nc_ohc=PMSZeVeTblMQ7kNvgGg8_nv&_nc_oc=AdgfFKapaOUI2Mzi8ujmZE59DPUThiDWIUjaC-b_3_59Fs6FyzHCo_TMSYLyh-14qPw&_nc_zt=23&_nc_ht=scontent-lim1-1.xx&_nc_gid=Atu9gpjnN9BZ1R85-ws_SOD&oh=00_AYB4nbxB5qPL5enNLCkc5Y2rWzOwOO6VY3NeDLAM6SZurA&oe=67CFE9EE",
  "Tunki": "https://content-us-2.content-cms.com/9b3f67ef-5a9f-4acc-8ce8-bcc27fa681c7/dxdam/26/2688f710-1267-4931-bcad-493fadedf85e/img_cta_f4_tunki_tablet_2x-min.jpg?t=1662393735638",
  "Lukita": "https://epsgrau.pe/webpage/oficinavirtual/pagos-digitales/img/lukita.png"
};

// Payment method options with their associated fields
const PAYMENT_METHODS = [
  { 
    id: "bank_transfer", 
    name: "Transferencia Bancaria",
    icon: "🏦"
  },
  { 
    id: "digital_wallet", 
    name: "Billetera Digital",
    icon: "📱"
  },
  { 
    id: "cash", 
    name: "Efectivo",
    icon: "💵"
  },
  {
    id: "other",
    name: "Otro",
    icon: "🔗"
  }
];

// Hardcoded bank options
const BANK_OPTIONS = [
  { name: "Interbank", payment_method: "bank_transfer" },
  { name: "BCP", payment_method: "bank_transfer" },
  { name: "Scotiabank", payment_method: "bank_transfer" },
  { name: "BBVA", payment_method: "bank_transfer" },
];

// Digital wallet options
const WALLET_OPTIONS = [
  { name: "Yape", payment_method: "digital_wallet" },
  { name: "Plin", payment_method: "digital_wallet" },
  { name: "Tunki", payment_method: "digital_wallet" },
  { name: "Lukita", payment_method: "digital_wallet" },
];

const ACCOUNT_TYPES = ["Ahorros", "Corriente"];
const CURRENCY_OPTIONS = ["PEN", "USD"];

const AddAccountModal = ({ open, onClose, onAddAccount, currency = "PEN" }) => {
  const [paymentMethod, setPaymentMethod] = useState("bank_transfer");
  const [type, setType] = useState("");
  const [owner, setOwner] = useState("");
  const [cci, setCci] = useState("");
  const [account, setAccount] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isDefault, setIsDefault] = useState(false);
  const [accountCurrency, setAccountCurrency] = useState(currency);
  const [alias, setAlias] = useState("");
  const [paymentLink, setPaymentLink] = useState("");

  // Reset form fields when payment method changes
  useEffect(() => {
    setAlias("");
    setPaymentLink("");
    setType("");
    setOwner("");
    setCci("");
    setAccount("");
    setPhoneNumber("");
  }, [paymentMethod]);

  const handleSubmit = () => {
    // Special validation for cash payments
    if (paymentMethod === "cash") {
      const newAccount = {
        id: crypto.randomUUID(),
        payment_method: "cash",
        provider_name: "Efectivo",
        is_default: isDefault,
        currency: accountCurrency,
        alias: alias || "Pago en Efectivo"
      };
      onAddAccount(newAccount);
      onClose();
      return;
    }

    // Validation based on payment method
    if (paymentMethod === "bank_transfer") {
      if (!owner || !account) {
        alert("Por favor completa los campos requeridos para transferencia bancaria.");
        return;
      }
    } else if (paymentMethod === "digital_wallet") {
      if (!phoneNumber) {
        alert("Por favor completa los campos requeridos para billetera digital.");
        return;
      }
    } else if (paymentMethod === "other") {
      if (!alias || !paymentLink) {
        alert("Por favor completa el nombre y el enlace del método de pago.");
        return;
      }
    }

    // Build base account object with common fields
    const newAccount = {
      id: crypto.randomUUID(),
      payment_method: paymentMethod,
      is_default: isDefault,
      alias: alias || "Método de pago"
    };

    // Add fields specific to payment method
    if (paymentMethod === "bank_transfer") {
      newAccount.account_type = type.toLowerCase() === "ahorros" ? "savings" : "current";
      newAccount.account_owner = owner;
      newAccount.cci_code = cci;
      newAccount.account_number = account;
      newAccount.currency = accountCurrency;
    } else if (paymentMethod === "digital_wallet") {
      newAccount.phone_number = `+${phoneNumber}`;
      newAccount.currency = accountCurrency;
    } else if (paymentMethod === "other") {
      newAccount.payment_link = paymentLink;
    }

    onAddAccount(newAccount);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Agregar Medio de Pago</DialogTitle>
      <DialogContent>
        {/* Payment Method Selection */}
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Método de Pago</InputLabel>
          <Select 
            value={paymentMethod} 
            label="Método de Pago" 
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            {PAYMENT_METHODS.map((method) => (
              <MenuItem key={method.id} value={method.id}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ mr: 1 }}>{method.icon}</Typography>
                  {method.name}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Fields specific to bank transfers */}
        <Box sx={{ 
          display: paymentMethod === "bank_transfer" ? 'block' : 'none',
          mt: 2
        }}>
          {/* Tipo de cuenta */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Tipo de cuenta</InputLabel>
            <Select 
              value={type} 
              label="Tipo de cuenta" 
              onChange={(e) => setType(e.target.value)}
            >
              {ACCOUNT_TYPES.map((t) => (
                <MenuItem key={t} value={t}>
                  {t}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Titular */}
          <TextField
            label="Titular"
            fullWidth
            sx={{ mb: 2 }}
            value={owner}
            onChange={(e) => setOwner(e.target.value)}
            required
          />

          {/* Cód. Interbancario */}
          <TextField
            label="Cód. Interbancario"
            fullWidth
            sx={{ mb: 2 }}
            value={cci}
            onChange={(e) => setCci(e.target.value.replace(/\D/g, ""))}
          />

          {/* Número de cuenta */}
          <TextField
            label="Número de Cuenta"
            fullWidth
            value={account}
            onChange={(e) => setAccount(e.target.value.replace(/\D/g, ""))}
            required
          />
        </Box>

        {/* Fields specific to digital wallets */}
        {paymentMethod === "digital_wallet" && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2" sx={{ mb: 1 }}>Número de Teléfono</Typography>
            <PhoneInput
              country={'pe'}
              value={phoneNumber}
              onChange={(phone) => setPhoneNumber(phone)}
              inputStyle={{ width: '100%', height: '56px' }}
              specialLabel=""
              countryCodeEditable={false}
              placeholder="912 345 678"
            />
          </Box>
        )}

        {/* Common fields for all payment methods */}
        {paymentMethod === "other" ? (
          <>
            <TextField
              label="Nombre del método de pago"
              fullWidth
              sx={{ mt: 2 }}
              value={alias}
              onChange={(e) => setAlias(e.target.value)}
              placeholder="Ej: PayPal, Stripe"
              required
            />
            <TextField
              label="Enlace de Pago"
              fullWidth
              sx={{ mt: 2 }}
              value={paymentLink}
              onChange={(e) => setPaymentLink(e.target.value)}
              placeholder="https://..."
              required
            />
          </>
        ) : (
          <>
            {paymentMethod !== "cash" && (
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel>Moneda</InputLabel>
                <Select 
                  value={accountCurrency} 
                  label="Moneda" 
                  onChange={(e) => setAccountCurrency(e.target.value)}
                >
                  {CURRENCY_OPTIONS.map((c) => (
                    <MenuItem key={c} value={c}>
                      {c === "PEN" ? "Soles (PEN)" : "Dólares (USD)"}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {/* Alias */}
            <TextField
              label="Alias"
              fullWidth
              sx={{ mt: 2 }}
              value={alias}
              onChange={(e) => setAlias(e.target.value)}
              placeholder={paymentMethod === "cash" ? "Ej: Caja Principal" : "Ej: Cuenta Principal, Yape Personal"}
              helperText="Nombre para identificar este método de pago"
            />
          </>
        )}

        {/* Is Default */}
        <FormControlLabel
          control={
            <Checkbox 
              checked={isDefault}
              onChange={(e) => setIsDefault(e.target.checked)}
            />
          }
          label="Establecer como método de pago principal"
          sx={{ mt: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button variant="contained" onClick={handleSubmit}>
          Añadir Método de Pago
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAccountModal;