// src/features/invitation/components/TodayVisits.js

import React from "react";
import { Box, Typography, Card, CardContent, Divider } from "@mui/material";
import { CalendarToday, AccessTime } from "@mui/icons-material";
import { format, isToday, parseISO, isValid } from "date-fns";
import { es } from "date-fns/locale";
import StatusChip from "./StatusChip";
import { useNavigation } from "../../../navigation/hooks/useNavigation";
import { ROUTES } from "../../../config/routes";
import NoVisitsMessage from "./NoVisitsMessage";
import { Link } from "react-router-dom";
import { Visit } from "../types";

const TodayVisits = ({ visits }: { visits: Visit[] }) => {
  const { navigateWithParams } = useNavigation();

  const scheduledVisits = visits
    .filter(
      (visit) =>
        visit.invitation_date &&
        isValid(parseISO(visit.invitation_date)) &&
        isToday(parseISO(visit.invitation_date))
    )
    .sort((a, b) => {
      const dateA = parseISO(a.invitation_date);
      const dateB = parseISO(b.invitation_date);
      return dateB.getTime() - dateA.getTime();
    });

  const manualVisits = visits
    .filter(
      (visit) =>
        !visit.invitation_date &&
        visit.check_in_time &&
        isValid(parseISO(visit.check_in_time)) &&
        isToday(parseISO(visit.check_in_time))
    )
    .sort((a, b) => {
      const timeA = parseISO(a.check_in_time);
      const timeB = parseISO(b.check_in_time);
      return timeB.getTime() - timeA.getTime();
    });


  const getVisitDetailsPath = (visitId: string) =>
    `${ROUTES.VISIT_DETAIL.replace(":visitId", visitId)}?from=today`;

  const renderVisitCard = (visit: Visit) => {
    const isProvider = visit.details?.type === "provider";

    return (
      <Link
        key={visit.visit_id}
        to={getVisitDetailsPath(visit.visit_id)}
        style={{ display: "contents" }}
      >
        <Card
          sx={{
            mb: 2,
            "&:hover": {
              cursor: "pointer",
              boxShadow: 3,
            },
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Box>
                <Typography variant="subtitle1">
                  {visit.visitor_first_name} {visit.visitor_last_name}
                </Typography>
                {isProvider ? (
                  <Typography variant="body2" color="text.secondary">
                    Proveedor
                  </Typography>
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    Depa {visit.apartment_number || "N/A"}
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <AccessTime fontSize="small" sx={{ mr: 0.5 }} />
                  <Typography variant="body2">
                    {format(
                      parseISO(visit.invitation_date || visit.check_in_time),
                      "HH:mm"
                    )}
                  </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <StatusChip status={visit.status} />
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Link>
    )
  };

  if (scheduledVisits.length === 0 && manualVisits.length === 0) {
    return (
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <CalendarToday sx={{ mr: 1 }} />
          <Typography variant="h6">
            {format(new Date(), "EEEE, d 'de' MMMM", { locale: es })}
          </Typography>
        </Box>
        <NoVisitsMessage isActiveTab={true} />
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CalendarToday sx={{ mr: 1 }} />
          <Typography variant="h6">
            {format(new Date(), "EEEE, d 'de' MMMM", { locale: es })}
          </Typography>
        </Box>
        <Typography variant="subtitle1">
          {scheduledVisits.length + manualVisits.length} visitas
        </Typography>
      </Box>

      {scheduledVisits.length > 0 && scheduledVisits.map(renderVisitCard)}

      {manualVisits.length > 0 && (
        <Box mt={4}>
          <Typography variant="subtitle1" gutterBottom>
            Visitas registradas manualmente
          </Typography>
          <Divider sx={{ mb: 2 }} />
          {manualVisits.map(renderVisitCard)}
        </Box>
      )}
    </Box>
  );
};

export default TodayVisits;
