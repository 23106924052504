import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  MenuItem,
  Snackbar,
  Avatar,
  Grid,
  Divider,
  CircularProgress
} from '@mui/material';
import { Email, Phone, Badge } from '@mui/icons-material';
import { manualVisitRegistrationService } from '../services/manualVisitRegistrationService';
import { useNavigation } from '../../../navigation/hooks/useNavigation';
import { ROUTES } from '../../../config/routes';
import { useBuilding } from '../hooks/useBuilding';
import { useManualRegistrationAnalytics } from '../hooks/useManualRegistrationAnalytics';
import useGlobalStore from '../../../state/store';

const ExistingVisitorRegistration = ({ visitor }) => {
  const [registrationType, setRegistrationType] = useState('visitor');
  const [company, setCompany] = useState('');
  const [reason, setReason] = useState('');  
  const [apartment, setApartment] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { navigateWithParams } = useNavigation();
  const { apartments, loading, error, buildingId } = useBuilding();
  const {
    trackManualVisitCreated,
    trackRegistrationCompleted,
    trackRegistrationFailed
  } = useManualRegistrationAnalytics();
  const user = useGlobalStore((state) => state.user);
  const [startTime] = useState(new Date());

  const handleVisitRegistration = async () => {
    if (registrationType === 'visitor' && !apartment) {
      setErrorMessage('Por favor, seleccione un apartamento.');
      return;
    }

    if (registrationType === 'provider' && (!company || !reason)) {
      setErrorMessage('Por favor, complete la empresa y el motivo de la visita.');
      return;
    }

    try {
      const visitResponse = await manualVisitRegistrationService.registerVisit(
        visitor.id,
        registrationType === 'visitor' ? apartment : null,
        visitor.email,
        visitor.document_number,
        registrationType, 
        registrationType === 'provider' ? { company, reason } : null,
        buildingId
      );
      if (user?.id) {
        trackManualVisitCreated(user.id, {
          visitId: visitResponse.id,
          apartmentId: apartment,
          buildingId: apartments.find(apt => apt.id === apartment)?.building_id
        });

        const timeSpent = Math.round((new Date() - startTime) / 1000);
        trackRegistrationCompleted(user.id, {
          timeSpent,
          stepsCompleted: 3,
          visitorId: visitor.id,
          registrationType
        });
      }
      setSuccessMessage('Visita registrada exitosamente.');
      setTimeout(() => {
        navigateWithParams(ROUTES.VISITS);
      }, 2000);
    } catch (error) {
      console.error('Error registering visit:', error);
      setErrorMessage('Error al registrar la visita. Por favor, intente de nuevo.');
      if (user?.id) {
        trackRegistrationFailed(user.id, error, 'visit_registration');
      }
    }
  };

  const handleCloseSnackbar = () => {
    setErrorMessage('');
    setSuccessMessage('');
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (apartments.length === 0) {
    return <Typography color="error">No hay apartamentos disponibles para este edificio.</Typography>;
  }

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', p: 2 }}>
      <Card sx={{ mb: 3, overflow: 'visible' }}>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Avatar
              src={visitor.profile_pic}
              sx={{ width: 80, height: 80, mr: 2 }}
            >
              {visitor.first_name[0]}{visitor.last_name[0]}
            </Avatar>
            <Typography variant="h5">
              {visitor.first_name} {visitor.last_name}
            </Typography>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Badge sx={{ mr: 1 }} />
                <Typography variant="body1">DNI: {visitor.document_number}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Email sx={{ mr: 1 }} />
                <Typography variant="body1">{visitor.email}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Phone sx={{ mr: 1 }} />
                <Typography variant="body1">{visitor.phone}</Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <TextField
        select
        fullWidth
        label="Tipo de visitante"
        value={registrationType}
        onChange={(e) => {
          setRegistrationType(e.target.value);
          setApartment('');
          setCompany('');
          setReason('');
        }}
        sx={{ mb: 1 }}
      >
        <MenuItem value="visitor">A departamento</MenuItem>
        <MenuItem value="provider">Proveedor</MenuItem>
      </TextField>

      {registrationType === 'visitor' && (
        <TextField
          select
          label="Apartamento"
          value={apartment}
          onChange={(e) => setApartment(e.target.value)}
          fullWidth
          margin="normal"
          required
          sx={{ mb: 3 }}
        >
          {apartments.map((apt) => (
            <MenuItem key={apt.id} value={apt.id}>
              {apt.apartment_number}
            </MenuItem>
          ))}
        </TextField>
      )}

      {registrationType === 'provider' && (
        <>
          <TextField
            fullWidth
            label="Empresa"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            margin="normal"
            required
            
          />
          <TextField
            fullWidth
            label="Motivo de la visita"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            margin="normal"
            required
            sx={{ mb: 3 }}
            multiline
            rows={3}
          />
        </>
      )}

      <Button
        onClick={handleVisitRegistration}
        fullWidth
        variant="contained"
        sx={{ mt: 2, borderRadius: '28px', height: '56px' }}
      >
        Registrar visita
      </Button>
      
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={errorMessage}
      />
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </Box>
  );
};

export default ExistingVisitorRegistration;