import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Alert,
  Snackbar,
  Typography,
  Avatar,
} from "@mui/material";
import { MediaUpload } from "../../../incidents/components/create";
import { useStorage } from "../../../../storage/hooks/useStorage";
import { maintenanceFeeService } from "../../services/maintenanceFeeService";
import { maintenanceFeeConfigService } from "../../services/maintenanceFeeConfigService";
import { supabaseStorage } from "../../../../storage"; // Added supabaseStorage import
import useGlobalStore from "../../../../state/store";
import { useMaintenanceFee } from "../../hooks/useMaintenanceFees";
import { useMaintenanceFeeAnalytics } from "../../hooks/useMaintenanceFeeAnalytics";

// Bank and digital wallet logos
const BANK_LOGOS = {
  "Interbank": "https://play-lh.googleusercontent.com/vjYx0jloYA0BSr6fHPhvhhIbgieH0jmOV3fv_evGkj9bxxjITNO3Yhfux77bq2_HvBIF=w480-h960-rw",
  "BCP": "https://play-lh.googleusercontent.com/gBpVaCpZsbBrLufT06aRpuLQvsUq1KAZUCEof_ps76mtB8_llJg3xv24mey8I0m3dUE=w480-h960-rw",
  "Scotiabank": "https://play-lh.googleusercontent.com/bX-2nxLIzRoDZBf_wMVI_VIB8NgnochPebM8aDMGe3wqEYz6dSQZsJjZ4F5j8OMM86S2=w480-h960-rw",
  "BBVA": "https://play-lh.googleusercontent.com/ZsRXxnAaNfWkGh0znnApV1d2BnTysMJVgZSAG5i4xX5c3weg6C0IGr6rtkqWMrXZriA=w480-h960-rw",
  "Yape": "https://play-lh.googleusercontent.com/y5S3ZIz-ohg3FirlISnk3ca2yQ6cd825OpA0YK9qklc5W8MLSe0NEIEqoV-pZDvO0A8=w480-h960-rw",
  "Plin": "https://scontent-lim1-1.xx.fbcdn.net/v/t39.30808-6/358694396_784669073456305_5517374345124528079_n.png?_nc_cat=104&ccb=1-7&_nc_sid=6ee11a&_nc_ohc=PMSZeVeTblMQ7kNvgGg8_nv&_nc_oc=AdgfFKapaOUI2Mzi8ujmZE59DPUThiDWIUjaC-b_3_59Fs6FyzHCo_TMSYLyh-14qPw&_nc_zt=23&_nc_ht=scontent-lim1-1.xx&_nc_gid=Atu9gpjnN9BZ1R85-ws_SOD&oh=00_AYB4nbxB5qPL5enNLCkc5Y2rWzOwOO6VY3NeDLAM6SZurA&oe=67CFE9EE",
  "Tunki": "https://content-us-2.content-cms.com/9b3f67ef-5a9f-4acc-8ce8-bcc27fa681c7/dxdam/26/2688f710-1267-4931-bcad-493fadedf85e/img_cta_f4_tunki_tablet_2x-min.jpg?t=1662393735638",
  "Lukita": "https://epsgrau.pe/webpage/oficinavirtual/pagos-digitales/img/lukita.png"
};

const AddPaymentModal = ({ open, onClose }) => {


  const buildingId = useGlobalStore((state) => state.buildingId);
  const user = useGlobalStore((state) => state.user);
  const { maintenanceFees } = useMaintenanceFee();
  const { trackPaymentAdded } = useMaintenanceFeeAnalytics();
  const setMaintenanceFees = useGlobalStore(state => state.setMaintenanceFees);


  const [apartmentId, setApartmentId] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [concept, setConcept] = useState("");
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedBank, setSelectedBank] = useState("");
  const [bankAccounts, setBankAccounts] = useState([]);
  const [loadingAccounts, setLoadingAccounts] = useState(true);
  const { maintenanceFeesPeriod } = useGlobalStore((state) => state.filters);

  const { uploadFiles } = useStorage();

  useEffect(() => {
    const fetchBankAccounts = async () => {
      try {
        setLoadingAccounts(true);
        const settings = await maintenanceFeeConfigService.fetchBuildingFeeSettings(buildingId);
        setBankAccounts(settings?.bank_accounts?.accounts || []);
      } catch (error) {
        console.error("Error fetching bank accounts:", error);
        setError("Error al cargar los métodos de pago");
      } finally {
        setLoadingAccounts(false);
      }
    };

    if (buildingId) {
      fetchBankAccounts();
    }
  }, [buildingId]);

  const resetForm = () => {
    setApartmentId("");
    setReferenceNumber("");
    setAmount("");
    setConcept("");
    setFiles([]);
    setSelectedBank("");
    setError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!apartmentId || !amount || !referenceNumber || !selectedBank) {
      setError("Por favor complete todos los campos requeridos");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const selectedBankAccount = bankAccounts.find((acc) => acc.id === selectedBank);
      if (!selectedBankAccount) {
        throw new Error("Método de pago no válido");
      }

      let evidenceUrl = null;
      let evidencePath = null;
      if (files.length > 0) {
        const path = `payments/${buildingId}/${apartmentId}`;
        const uploadedFiles = await uploadFiles(files, { path });
        if (uploadedFiles && uploadedFiles[0]) {
          const fileUrl = await supabaseStorage.getFileUrl(uploadedFiles[0].path, true);
          evidenceUrl = fileUrl;
          evidencePath = uploadedFiles[0].path;
        }
      }

      const paymentData = {
        apartment_id: apartmentId,
        amount: parseFloat(amount),
        payment_date: new Date().toISOString().slice(0, 10),
        payment_method: selectedBankAccount.payment_method,
        bank_name: selectedBankAccount.provider_name || selectedBankAccount.alias,
        reference_number: referenceNumber,
        evidence_url: evidenceUrl,
        evidence_path: evidencePath,
        notes: concept,
        status: "pending",
        building_id: buildingId,
        is_active: true,
      };

      const result = await maintenanceFeeService.createPayment(buildingId, paymentData);

      trackPaymentAdded(user.id, result.id, {
        amount: paymentData.amount,
        method: paymentData.payment_method,
        apartmentId: paymentData.apartment_id
      });

      if (paymentData.payment_date.slice(0, 7) === maintenanceFeesPeriod) {
        console.log("Payment is for current period, updating maintenance fees");

        const formattedPayment = {
          id: result.id,
          apartmentId: result.apartment_id,
          amount: parseFloat(result.amount),
          paymentDate: result.payment_date,
          paymentMethod: result.payment_method,
          bankName: result.bank_name,
          referenceNumber: result.reference_number,
          evidenceUrl: result.evidence_url,
          evidencePath: result.evidence_path,
          status: result.status,
          verifiedBy: result.verified_by,
          verifiedAt: result.verified_at,
          notes: result.notes,
          transactionId: result.transaction_id,
          createdAt: result.created_at,
        };

        const updatedFees = maintenanceFees.map(fee => {
          if (fee.id === apartmentId) {
            return {
              ...fee,
              payments: [...(fee.payments || []), formattedPayment],
              porValidar: fee.porValidar + parseFloat(amount),
            };
          }
          return fee;
        });

        setMaintenanceFees({
          data: updatedFees,
          error: null,
          loading: false
        });
      }

      resetForm();
      onClose();
    } catch (error) {
      console.error("Error adding payment:", error);
      setError(error.message || "Error al crear el pago. Por favor, intente nuevamente.");
    } finally {
      setLoading(false);
    }
  };

  const renderBankDetails = (account) => {
    if (account.payment_method === "digital_wallet") {
      return `${account.provider_name || account.alias} - ${account.phone_number}`;
    }
    if (account.payment_method === "cash") {
      return account.alias || account.provider_name;
    }
    if (account.payment_method === "other") {
      return account.alias;
    }
    return `${account.provider_name || account.alias} - ${account.account_number}`;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{ sx: { borderRadius: "24px" } }}
    >
      <DialogTitle>Añadir Pago</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          {/* Apartment Selection */}
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Departamento</InputLabel>
            <Select
              value={apartmentId}
              label="Departamento"
              onChange={(e) => setApartmentId(e.target.value)}
              required
            >
              {maintenanceFees.map((apt) => (
                <MenuItem key={apt.id} value={apt.id}>
                  {apt.apartmentNumber}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Bank Account Selection */}
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Método de Pago</InputLabel>
            <Select
              value={selectedBank}
              label="Método de Pago"
              onChange={(e) => setSelectedBank(e.target.value)}
              required
              disabled={loadingAccounts}
            >
              {loadingAccounts ? (
                <MenuItem disabled>Cargando métodos de pago...</MenuItem>
              ) : Array.isArray(bankAccounts) && bankAccounts.length === 0 ? (
                <MenuItem disabled>No hay métodos de pago configurados</MenuItem>
              ) : (
                bankAccounts.map((account) => (
                  <MenuItem
                    key={account.id}
                    value={account.id}
                    sx={{
                      padding: '12px 16px',
                      "&:hover": {
                        backgroundColor: '#F9FAFB',
                      },
                    }}
                  >
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      width: '100%'
                    }}>
                      {account.payment_method === 'cash' ? (
                        <Box 
                          sx={{ 
                            mr: 2,
                            height: 32, 
                            width: 32, 
                            borderRadius: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#FEF3C7',
                            color: '#D97706',
                            fontSize: '18px',
                            flexShrink: 0
                          }}
                        >
                          💵
                        </Box>
                      ) : account.payment_method === 'other' ? (
                        <Box 
                          sx={{ 
                            mr: 2,
                            height: 32, 
                            width: 32, 
                            borderRadius: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#F3F4F6',
                            color: '#4B5563',
                            fontSize: '18px',
                            flexShrink: 0
                          }}
                        >
                          🔗
                        </Box>
                      ) : (
                        BANK_LOGOS[account.provider_name] ? (
                          <Avatar 
                            src={BANK_LOGOS[account.provider_name]}
                            alt={account.provider_name}
                            variant="rounded"
                            sx={{ 
                              width: 32, 
                              height: 32, 
                              mr: 2,
                              backgroundColor: '#f0f0f0',
                              border: '1px solid #e0e0e0',
                              flexShrink: 0
                            }}
                          />
                        ) : (
                          <Box 
                            sx={{ 
                              mr: 2,
                              height: 32, 
                              width: 32, 
                              borderRadius: '8px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              backgroundColor: account.payment_method === 'digital_wallet' ? '#F0FDF4' : '#EFF6FF',
                              color: account.payment_method === 'digital_wallet' ? '#22C55E' : '#3B82F6',
                              fontSize: '18px',
                              flexShrink: 0
                            }}
                          >
                            {account.payment_method === 'digital_wallet' ? '📱' : '🏦'}
                          </Box>
                        )
                      )}
                      <Box sx={{ minWidth: 0, flex: 1 }}>
                        <Typography 
                          variant="body1" 
                          sx={{ 
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                        >
                          {renderBankDetails(account)}
                        </Typography>
                        <Typography 
                          variant="caption" 
                          color="text.secondary"
                        >
                          {account.currency}
                        </Typography>
                      </Box>
                    </Box>
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>

          {/* Reference Number */}
          <TextField
            fullWidth
            label="Código de Operación"
            value={referenceNumber}
            onChange={(e) => setReferenceNumber(e.target.value)}
            sx={{ mt: 2 }}
            required
          />

          {/* Amount */}
          <TextField
            fullWidth
            label="Monto (S/)"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            sx={{ mt: 2 }}
            required
          />

          {/* Notes */}
          <TextField
            fullWidth
            label="Concepto"
            multiline
            rows={2}
            value={concept}
            onChange={(e) => setConcept(e.target.value)}
            sx={{ mt: 2 }}
          />

          {/* File Upload */}
          <Box sx={{ mt: 2 }}>
            <MediaUpload
              files={files}
              onChange={setFiles}
              disabled={loading}
              hideAttachButton={false}
            />
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={
            loading ||
            !apartmentId ||
            !amount ||
            !referenceNumber ||
            !selectedBank ||
            loadingAccounts
          }
        >
          {loading ? "Procesando..." : "Añadir Pago"}
        </Button>
      </DialogActions>

      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert onClose={() => setError(null)} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default AddPaymentModal;
