import { useEffect, useState, useCallback } from "react";
import { isValidNonEmptyArray } from "../../../shared/utils/isValidNonEmptyArray";
import useGlobalStore from "../../../state/store";
import useUser from "../../account/hooks/useUser";
import { incidentService } from "../services/incidentService";
import { useIncident } from "./useIncident";
import { Incident } from "../types";

export default function useIncidents() {
  const setIncidents = useGlobalStore((state) => state.setIncidents);
  const [error, setError] = useState<string | null>(null);
  const incidents = useGlobalStore((state) => state.incidents);
  
  const hasCachedIncidents = isValidNonEmptyArray(incidents.data);

  const { updateIncidentStatus: updateIncidentStatusBase } = useIncident();
  const { user } = useUser();

  const fetchBuildingIncidents = useCallback(async ({
    buildingId,
  }: {
    buildingId: string;
  }) => {
    if (!buildingId) {
      setIncidents({ error: "Building ID is required" });
      return;
    }

    setIncidents({ error: null, loading: true });

    try {
      const response = await incidentService.fetchBuildingIncidents(
        buildingId,
        user!.id
      );


      setIncidents({
        data: isValidNonEmptyArray(response) ? response : [],
      });
      return response;
    } catch (err: any) {
      const errorMessage = err.message || "Error al cargar las incidencias";
      setIncidents({ error: errorMessage });
    } finally {
      setIncidents({ loading: false });
    }
  }, [setIncidents, user]);

  const updateIncidentStatus = useCallback(async ({
    incidentId,
    status
  }: {
    incidentId: string;
    status: string;
    buildingId: string;
  }) => {
    if (!incidentId ||  !status) return;
    
    try {
      await updateIncidentStatusBase( status, null, incidentId,);
      console.log("Incident status updated");
    } catch (err: any) {
      setError(err.message || "Error updating incident status");
    }
  }, [updateIncidentStatusBase]);

  return {
    fetchBuildingIncidents,
    loading: incidents.loading,
    error: incidents.error,
    incidents: incidents.data ?? [],
    setIncidents,
    hasCachedIncidents,
    updateIncidentStatus,
  };
}