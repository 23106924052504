
export const formatDate = (date: any) => {
    if (!date) return '-';
    const [y, m, d] = new Date(date).toISOString().split('T')[0].split('-');
    return `${d}/${m}/${y}`;
  };

  export const formatMonthYear = (date: any) => {
        if (!date) return '';
        return date.toLocaleDateString('es-ES', { month: 'short', year: 'numeric' })
          .replace(/^\w/, (c: string) => c.toUpperCase());
      };