import React, { useState, useEffect } from 'react';
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Chip,
  Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { maintenanceFeeService } from '../../services/maintenanceFeeService';
import useGlobalStore from '../../../../state/store';
import { supabaseStorage } from '../../../../storage';
import { formatDate } from '../../utils/formatDate';


const ConciliatePaymentDrawer = ({
  open,
  onClose,
  payment = {}
}) => {
  const [signedUrl, setSignedUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
   
  const { maintenanceFees, setMaintenanceFees } = useGlobalStore();
  const { trackPaymentReconciled } = useMaintenanceFeeAnalytics();
  const user = useGlobalStore((state) => state.user);
  
  useEffect(() => {
    const fetchSignedUrl = async () => {
      if (payment.evidencePath) {
        try {
          setLoading(true);
          const url = await supabaseStorage.getFileUrl(payment.evidencePath);
          setSignedUrl(url);
          setError(null);
        } catch (error) {
          console.error('Error getting signed URL:', error);
          setError(error instanceof Error ? error.message : 'Unknown error');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchSignedUrl();
  }, [payment.evidencePath]);

  const statusMap = {
    pending: { label: 'Por Validar', color: '#92400E', bg: '#FEF3C7' },
    verified: { label: 'Validado', color: '#166534', bg: '#DCFCE7' },
    rejected: { label: 'Rechazado', color: '#991B1B', bg: '#FEE2E2' }
  };

  const statusStyle = statusMap[payment.status] || { label: 'Desconocido', color: '#6B7280', bg: '#F3F4F6' };

  const handleConciliateStatus = async (newStatus) => {
    try {
      if (!payment.id) {
        console.error('Missing required data:', { paymentId: payment.id });
        return;
      }
  
      const updated = await maintenanceFeeService.updatePaymentStatus(payment.id, newStatus);
      trackPaymentReconciled(user.id, payment.id, newStatus);

      const formattedPayment = {
        id: updated.id,
        apartmentId: updated.apartment_id,
        amount: parseFloat(updated.amount),
        paymentDate: updated.payment_date,
        paymentMethod: updated.payment_method,
        bankName: updated.bank_name,
        referenceNumber: updated.reference_number,
        evidenceUrl: updated.evidence_url,
        evidencePath: updated.evidence_path,
        status: updated.status,
        verifiedBy: updated.verified_by,
        verifiedAt: updated.verified_at,
        notes: updated.notes,
        transactionId: updated.transaction_id,
        createdAt: updated.created_at,
      };

      setMaintenanceFees({
        data: maintenanceFees.data.map(fee => {
          if (fee.id === updated.apartment_id) {
            return {
              ...fee,
              payments: fee.payments.map(p => p.id === updated.id ? formattedPayment : p),
              montoPagado: updated.status === "verified" ? fee.montoPagado + parseFloat(updated.amount) : fee.montoPagado,
              porValidar:  fee.porValidar - parseFloat(updated.amount) ,
              deuda: updated.status === "verified" ? Math.max(0, fee.deuda - parseFloat(updated.amount)):fee.deuda ,
              
            };
          }
          return fee;
        }),
        error: null,
        loading: false
      });

      onClose();
    } catch (error) {
      console.error('Failed to update payment status:', error);
    }
  };

  const formatTime = (date) => {
    if (!date) return '';
    return format(new Date(date), "HH:mm a", { locale: es });
  };

  const getPaymentMethodLabel = (method) => {
    const methodMap = {
      bank_transfer: 'Transferencia bancaria',
      cash: 'Efectivo',
      check: 'Cheque',
      other: 'Otro método',
      digital_wallet: 'Billetera digital'
    };
    return methodMap[method] || method;
  };

  const handleViewEvidence = () => {
    if (signedUrl) {
      window.open(signedUrl, '_blank');
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      variant="temporary"
      PaperProps={{
        sx: {
          width: '400px',
          boxShadow: '-4px 0 8px rgba(0, 0, 0, 0.1)',
          border: 'none',
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          zIndex: 9999,
        }
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        height: '100%',
        backgroundColor: '#fff',
        px: 3,
        py: 2,
      }}>
        {/* Header */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 3,
        }}>
          <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '18px' }}>
            Detalle Registro de Voucher
          </Typography>
          <IconButton 
            onClick={onClose}
            size="small"
            sx={{ color: '#6B7280' }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        {/* Content */}
        <Box sx={{ flex: 1, overflowY: 'auto' }}>
          {/* Basic Info Section */}
          <Box sx={{ mb: 3 }}>
            <InfoField 
              label="Fecha de registro" 
              value={formatDate(payment.createdAt)} 
            />
            {/* <InfoField 
              label="Periodo" 
              value={formatDate(payment.paymentDate)} 
            /> */}
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                Estado
              </Typography>
              <Chip
                label={statusStyle.label}
                sx={{
                  backgroundColor: statusStyle.bg,
                  color: statusStyle.color,
                  fontWeight: 500,
                  fontSize: '13px',
                  height: '24px',
                  borderRadius: '12px'
                }}
              />
            </Box>
          </Box>

          {/* Voucher Info Section */}
          <Typography variant="subtitle1" sx={{ 
            fontWeight: 500, 
            fontSize: '16px',
            mb: 2 
          }}>
            Información del Voucher
          </Typography>

          <Box sx={{
            backgroundColor: '#F9FAFB',
            borderRadius: '12px',
            p: 2,
            mb: 3
          }}>
            <InfoField 
              label="Banco" 
              value={payment.bankName || '-'} 
            />
            <InfoField 
              label="Método de pago" 
              value={getPaymentMethodLabel(payment.paymentMethod) || '-'} 
            />
            <InfoField 
              label="Código de operación" 
              value={payment.referenceNumber || '-'} 
            />
            <InfoField 
              label="Fecha de transacción" 
              value={payment.paymentDate ? formatDate(payment.paymentDate) : '-'}
            />
            <InfoField 
              label="Valor" 
              value={payment.amount ? `S/ ${payment.amount.toFixed(2)}` : '-'} 
            />
            {payment.notes && (
              <InfoField 
                label="Detalle" 
                value={payment.notes} 
              />
            )}
          </Box>

          {/* Attachment Section */}
          <Typography variant="subtitle1" sx={{ 
            fontWeight: 500, 
            fontSize: '16px',
            mb: 2 
          }}>
            Archivo Adjunto
          </Typography>

          <Box sx={{
            backgroundColor: '#F9FAFB',
            borderRadius: '12px',
            p: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 3
          }}>
            <Box>
              <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                {payment.evidencePath ? 'Voucher adjunto' : 'Sin archivo'}
              </Typography>
              {error && (
                <Typography color="error" sx={{ fontSize: '12px' }}>
                  Error al cargar el archivo
                </Typography>
              )}
            </Box>
            {payment.evidencePath && !loading && !error && (
              <IconButton
                onClick={handleViewEvidence}
                sx={{ color: '#315CAC' }}
              >
                <VisibilityIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        </Box>

        {/* Action Buttons */}
        {payment.status === 'pending' && ( 
           <Box sx={{
          display: 'flex',
          gap: 2,
          mt: 'auto',
          pt: 2,
          borderTop: '1px solid #E5E7EB'
        }}>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleConciliateStatus('rejected')}
            sx={{
              flex: 1,
              borderRadius: '24px',
              textTransform: 'none',
              py: 1,
              backgroundColor: '#DC2626',
              '&:hover': {
                backgroundColor: '#B91C1C'
              }
            }}
          >
            Rechazar
          </Button>

          <Button
            variant="contained"
            color="success"
            onClick={() => handleConciliateStatus('verified')}
            sx={{
              flex: 1,
              borderRadius: '24px',
              textTransform: 'none',
              py: 1,
              backgroundColor: '#059669',
              '&:hover': {
                backgroundColor: '#047857'
              }
            }}
          >
            Aprobar
          </Button>
        </Box>
        )}
      </Box>
    </Drawer>
  );
};

const InfoField = ({ label, value }) => (
  <Box sx={{ mb: 2 }}>
    <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
      {label}
    </Typography>
    <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
      {value}
    </Typography>
  </Box>
);

export default ConciliatePaymentDrawer;