import { create } from "zustand";
import { persist } from "zustand/middleware";
import { authService } from "../features/auth/services/authService";
import { Session } from "@supabase/auth-js";
import { Visit } from "../features/invitation/types";
import { RequestData } from "../shared/types";
import { Incident } from "../features/incidents/types";
import hasKeys from "../shared/utils/hasKey";
import { isValidNonEmptyArray } from "../shared/utils/isValidNonEmptyArray";
import { MaintenanceFee } from "../features/maintenanceFee/types";
import { User } from "../features/account/types";
import getDefaultPeriod from "../shared/utils/getDefaultPeriod";
import { WaterConsumption } from "../features/meters/types";

type Filters = {
  maintenanceFeesPeriod: string;
  waterConsumptionPeriod: string;
};

type FileData = {
  depto: string;
  residente: string;
  monto: number;
  fecha: string;
  id?: string;
};

interface State {
  user: User | null;
  session: Session | null;
  loading: boolean;
  error: any;

  buildingId: string | null;
  apartmentId: string | null;
  apartments: any[];
  invitationToken: string | null;

  visits: RequestData<Visit[]>;
  incidents: RequestData<Incident[]>;
  maintenanceFees: RequestData<MaintenanceFee[]>;
  waterConsumptions: RequestData<WaterConsumption[]>;
  fileData: FileData[];
  filters: Filters;

  setSession: (session: Session | null) => void;
  setUser: (user: any) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: any) => void;
  setBuildingId: (id: string | null) => void;
  setApartmentId: (id: string | null) => void;
  setApartments: (apartments: any[]) => void;
  setInvitationToken: (token: string | null) => void;
  setVisits: (visits: Partial<RequestData<Visit[]>>) => void;
  setIncidents: (incidents: Partial<RequestData<Incident[]>>) => void;
  setMaintenanceFees: (fees: Partial<RequestData<MaintenanceFee[]>>) => void;
  setWaterConsumptions: (waterConsumptions: Partial<RequestData<WaterConsumption[]>>) => void;
  setFileData: (data: FileData[]) => void;
  setFilters: (filter: Partial<Filters>) => void;

  fetchUserProfile: (authUser: any) => Promise<void>;
  initializeUser: () => Promise<void>;
  clearContext: () => void;
}

export const useGlobalStore = create<State>()(
  persist(
    (set, get) => ({
      user: null,
      session: null,
      loading: true,
      error: null,
      buildingId: null,
      apartmentId: null,
      apartments: [],
      invitationToken: null,
      fileData: [],
      
      visits: {
        data: [],
        error: null,
        loading: false,
      },
      
      incidents: {
        data: [],
        error: null,
        loading: false,
      },
      
      maintenanceFees: {
        data: [],
        error: null,
        loading: false,
      },
      
      waterConsumptions: {
        data: [],
        error: null,
        loading: false,
      },
      
      filters: {
        maintenanceFeesPeriod: getDefaultPeriod(),
        waterConsumptionPeriod: getDefaultPeriod(),
      },

      setSession: (session) => set({ session }),
      
      setUser: (user) => {
        set({ user });
        const currentBuildingId = get().buildingId;
      
        if (!currentBuildingId && user?.buildings?.length > 0) {
          const defaultBuilding = user.buildings[0];
          set({ buildingId: defaultBuilding.id });
          
             if (defaultBuilding.apartments?.length > 0) {
            set({ apartmentId: defaultBuilding.apartments[0].id });
            set({ apartments: defaultBuilding.apartments });
          }
        }
      },
      
      setLoading: (loading) => set({ loading }),
      setError: (error) => set({ error }),
      
      setBuildingId: (id) => {
        set({ buildingId: id });
        set({ apartmentId: null });
        
        const user = get().user;
        if (user?.buildings && id) {
          const selectedBuilding = user.buildings.find(b => b.id === id);
          if (selectedBuilding?.apartments) {
            set({ apartments: selectedBuilding.apartments });
            if (selectedBuilding.apartments.length > 0) {
              set({ apartmentId: selectedBuilding.apartments[0].id });
            }
          }
        }
        
        set({
          visits: { data: [], error: null, loading: false },
          incidents: { data: [], error: null, loading: false },
          maintenanceFees: { data: [], error: null, loading: false },
          waterConsumptions: { data: [], error: null, loading: false },
        });
      },
      
      setApartmentId: (id) => set({ apartmentId: id }),
      setApartments: (apartments) => set({ apartments }),
      setInvitationToken: (token) => set({ invitationToken: token }),
      
      setVisits: (visits) => set({ visits: { ...get().visits, ...visits } }),
      setIncidents: (incidents) => set({ incidents: { ...get().incidents, ...incidents } }),
      setMaintenanceFees: (fees) => set({ maintenanceFees: { ...get().maintenanceFees, ...fees } }),
      setWaterConsumptions: (waterConsumptions) => 
        set({ waterConsumptions: { ...get().waterConsumptions, ...waterConsumptions } }),
      
      setFileData: (data) => set({ fileData: data }),
      setFilters: (filters) => set({ filters: { ...get().filters, ...filters } }),

      async fetchUserProfile(authUser) {
        if (!authUser) {
          set({ user: null, loading: false });
          return;
        }

        try {
          const userProfile = await authService.getUserProfile(authUser.id);
          const buildings = userProfile.is_worker
            ? await authService.getUserBuildings(userProfile.id)
            : [];

          const mergedUser = { ...authUser, ...userProfile, buildings };
          
          const currentBuildingId = get().buildingId;
          const isValidBuilding = buildings.some(b => b.id === currentBuildingId);
          
          if (!isValidBuilding && buildings.length > 0) {
            const defaultBuilding = buildings[0];
            set({ 
              buildingId: defaultBuilding.id,
              apartments: defaultBuilding.apartments || [],
              apartmentId: defaultBuilding.apartments?.[0]?.id || null
            });
          }

          get().setUser(mergedUser);
          set({ loading: false });
        } catch (error) {
          console.error("Error fetching user profile:", error);
          set({ error, loading: false });
        }
      },

      async initializeUser() {
        const session = get().session;
        if (session) {
          await get().fetchUserProfile(session.user);
        } else {
          set({ user: null, loading: false });
        }
      },

      clearContext() {
        set({
          invitationToken: null,
          buildingId: null,
          apartmentId: null,
          apartments: [],
          visits: { data: [], error: null, loading: false },
          incidents: { data: [], error: null, loading: false },
          maintenanceFees: { data: [], error: null, loading: false },
          waterConsumptions: { data: [], error: null, loading: false },
          fileData: [],
        });
      },
    }),
    {
      name: 'app-storage',
      partialize: (state) => ({
        buildingId: state.buildingId,
        filters: state.filters,
      }),
    }
  )
);

export default useGlobalStore;