import JSZip from 'jszip';
import { WaterConsumption } from '../types';

export type ZipProgress = {
  current: number;
  total: number;
};

export type ZipStatus = "preparing" | "completed" | "empty" | "error";

export type ZipResult = {
  status: ZipStatus;
  message?: string;
  totalImages?: number;
};

const downloadImagesAsZip = async (
  waterConsumptions: WaterConsumption[],
  period: string,
  onProgressUpdate?: (progress: ZipProgress) => void,
  onStatusChange?: (status: ZipStatus) => void
): Promise<ZipResult> => {
  try {
    onStatusChange?.("preparing");

    const consumptionsWithImages = waterConsumptions.filter(consumption => consumption.image_url);
    const totalImages = consumptionsWithImages.length;

    onProgressUpdate?.({ current: 0, total: totalImages });

    if (totalImages === 0) {
      onStatusChange?.("empty");
      return {
        status: "empty",
        message: "No se encontraron imágenes de medidores para este período."
      };
    }

    const zip = new JSZip();
    const batchSize = 10;

    for (let i = 0; i < totalImages; i += batchSize) {
      const batch = consumptionsWithImages.slice(i, i + batchSize);

      await Promise.all(batch.map(async (consumption) => {
        if (!consumption.image_url) return;

        try {
          const response = await fetch(consumption.image_url);
          const blob = await response.blob();
          const urlPath = new URL(consumption.image_url).pathname;
          const originalFilename = urlPath.split('/').pop() || '';
          const fileExtension = originalFilename.split('.').pop() || 'jpg';

          const filename = `${consumption.apartment}.${fileExtension}`;
          zip.file(filename, blob);
        } catch (error) {
          console.error(`Error downloading image for apartment ${consumption.apartment}:`, error);
        }
      }));

      const processedItems = Math.min(i + batchSize, totalImages);
      onProgressUpdate?.({ current: processedItems, total: totalImages });
    }

    const zipBlob = await zip.generateAsync({
      type: 'blob',
      compression: "DEFLATE",
      compressionOptions: {
        level: 6
      }
    });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(zipBlob);

    link.download = `mediciones_${formatPeriod(period)}.zip`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => URL.revokeObjectURL(link.href), 100);

    onStatusChange?.("completed");
    
    return {
      status: "completed",
      message: "Descarga completada con éxito",
      totalImages: totalImages
    };
  } catch (error) {
    console.error("Error creating ZIP file:", error);
    onStatusChange?.("error");
    return {
      status: "error",
      message: "Ha ocurrido un error al crear el archivo ZIP."
    };
  }
};

function formatPeriod(rp: string): string {
  const [year, month] = rp.split("-");
  const dateObj = new Date(+year, +month - 1, 1);
  const formattedDate = dateObj.toLocaleString("es-ES", { month: "long", year: "numeric" });
  return formattedDate.toLowerCase().replace(/\s+/g, "_");
}


export default downloadImagesAsZip;