// src/features/incident/components/detail/IncidentDetailView.js
import React from "react";
import { Paper, Box, Stack, Typography, Grid } from "@mui/material";
import { Skeleton } from '@mui/material';
import { format } from "date-fns";
import { es } from "date-fns/locale";
import IncidentTimeline from "./IncidentTimeline.jsx";
import IncidentComments from "./IncidentComments.jsx";
// import ResolutionRating from './ResolutionRating'; // Comment out this line

import { StatusBadge, MediaGallery } from "../shared/index.js"; 
import {
  CATEGORY_LABELS,
  PRIORITY_CONFIG,
  VISIBILITY_LABELS,
} from "../../constants";

const IncidentDetailView = ({
  incident,
  onUpdateStatus,
  onAddComment,
  // onRateResolution, // Comment out this line
  userRole = "resident", // Add role prop for future use
}) => {
  const hasFullData = Boolean(incident?.comments && incident?.status_history);
  if (!incident) return null;

  return (
    <Grid container spacing={3}>
      {/* Left Column */}
      <Grid item xs={12} md={4}>
        <Stack spacing={3}>
          {/* Details Card */}
          <Paper sx={{ p: 3, borderRadius: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
              Detalles
            </Typography>
            <Stack spacing={3}>
              <Box>
                <Box sx={{ mb: 1 }}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Numero de referencia
                  </Typography>
                  <Typography>IC-{incident.id.slice(0, 6)}</Typography>
                </Box>
              </Box>

              <Box>
                <Box sx={{ mb: 1 }}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Estado
                  </Typography>
                  <StatusBadge status={incident.status} />
                </Box>
              </Box>

              {/* Add Category */}
              <Box>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Categoría
                </Typography>
                <Typography>{CATEGORY_LABELS[incident.category]}</Typography>
              </Box>

              {/* Add Visibility */}
              <Box>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Visibilidad
                </Typography>
                <Typography>{VISIBILITY_LABELS[incident.incident_visibility]}</Typography>
              </Box>

              <Box>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Fecha de registro
                </Typography>
                <Typography>
                  {format(new Date(incident.created_at), "d 'de' MMMM yyyy - hh:mm a", {
                    locale: es,
                  })}
                </Typography>
              </Box>

              <Box>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Encargado
                </Typography>
                <Typography>
                  {incident.assigned_to?.first_name
                    ? `${incident.assigned_to.first_name} ${incident.assigned_to.last_name}`
                    : "Sin asignar"}
                </Typography>
              </Box>
            </Stack>
          </Paper>

          {/* Timeline Card */}
          <Paper sx={{ p: 3, borderRadius: 2 }}>
          {!hasFullData ? (
          <Skeleton variant="rectangular" height={100} />
        ) : (
            <IncidentTimeline
              statusHistory={incident.status_history || []}
              currentStatus={incident.status}
              onUpdateStatus={onUpdateStatus}
              userRole={userRole}
            />
          )}
          </Paper>
        </Stack>
      </Grid>

      {/* Right Column */}
      <Grid item xs={12} md={8}>
        <Stack spacing={3}>
          {/* Description Card */}
          <Box sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Descripción
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              {incident.description}
            </Typography>
          </Box>

          {/* Evidence Card */}
          {incident.media_urls && incident.media_urls.length > 0 && (
            <Box sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Evidencia
              </Typography>
              <Box sx={{ mt: 2 }}>
                <MediaGallery media={incident.media_urls} />
              </Box>
            </Box>
          )}

          {/* Comments Section */}
          <Box sx={{ p: 3 }}>
            {!hasFullData ? (
              <Skeleton variant="rectangular" height={200} />
            ) : (
              <IncidentComments
                comments={incident.comments || []}
                onAddComment={onAddComment}
                incidentId={incident.id}
                buildingId={incident.building_id} // Make sure this is passed
                readonly={incident.status === "closed"}
              />
            )}
          </Box>

          {/* Resolution Rating - Only show for residents and resolved incidents */}
          {/* Comment out the rating section */}
          {/* {userRole === 'resident' && 
           incident.status === 'resolved' && 
           !incident.resolution_rating && (
            <Paper sx={{ p: 3, borderRadius: 2 }}>
              <ResolutionRating
                incidentId={incident.id}
                onRate={onRateResolution}
              />
            </Paper>
          )} */}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default React.memo(IncidentDetailView);
