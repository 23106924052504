import React, { useState } from 'react';
import { Box, Typography, Chip, Button, IconButton } from '@mui/material';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ConciliatePaymentDrawer from '../../components/maintenanceFeeDashboard/ConciliatePaymentDrawer';
import { formatDate } from '../../utils/formatDate';

const MaintenanceFeeDetailTable = ({ charges = [], payments = [] }) => {
  const statusLabels = {
    pending: 'Por Validar',
    verified: 'Validado',
    rejected: 'Rechazado',
    paid: 'Validado', // For charges that are 'paid'
    unpaid: 'Por Validar', // If you have such a status for charges
  };

  const statusStyles = {
    pending: { bg: '#FEF3C7', text: '#92400E' },
    verified: { bg: '#DCFCE7', text: '#166534' },
    rejected: { bg: '#FEE2E2', text: '#991B1B' },
    paid: { bg: '#DCFCE7', text: '#166534' }, 
    unpaid: { bg: '#FEF3C7', text: '#92400E' }
  };

  const gridStyles = {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    gap: 2,
    px: 2,
    alignItems: 'center'
  };

  const [openConciliateDrawer, setOpenConciliateDrawer] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);

  const handleConciliarClick = (payment) => {
    setSelectedPayment(payment);
    setOpenConciliateDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenConciliateDrawer(false);
    setSelectedPayment(null);
  };

  // Merge charges and payments
  // For sorting we will derive a "date" field:
  // Charges use issue_date
  // Payments use paymentDate
   

  const combinedData = [
    ...charges.map(charge => ({
      ...charge,
      type: 'charge',
      date: new Date(charge.issue_date),
      concepto: 'Emisión Cuota',
      periodo: new Date(charge.due_date)
    })),
    ...payments.map(payment => ({
      ...payment,
      type: 'payment',
      date: new Date(payment.paymentDate),
      concepto: 'Pago de Cuota',
      periodo: new Date(payment.paymentDate)
    }))
  ];

  // Sort by date (descending - newer dates first)
  combinedData.sort((a, b) => b.date - a.date);

  // After sorting, calculate running totals from bottom to top
  let runningTotal = 0;
  for (let i = combinedData.length - 1; i >= 0; i--) {
    const item = combinedData[i];
    if (item.type === 'charge') {
      // For charges, add the debt to running total
      const emiCuota = item.emiCuota !== undefined ? item.emiCuota : (item.total_amount || 0);
      const montoPagado = item.montoPagado || 0;
      const currentDebt = emiCuota > montoPagado ? (emiCuota - montoPagado) : 0;
      runningTotal += currentDebt;
      item.runningDeuda = runningTotal;
    } else {
      // For payments, only subtract if status is 'verified'
      if (item.status === 'verified') {
        runningTotal = Math.max(0, runningTotal - (item.amount || 0));
      }
      item.runningDeuda = runningTotal;
    }
  }

  // Helper to format month/year (in Spanish) with capitalized month
  const formatMonthYear = (date) => {
    if (!date) return '';
    return date.toLocaleDateString('es-ES', { month: 'short', year: 'numeric' })
      .replace(/^\w/, c => c.toUpperCase());
  };

  return (
    <>
      <Box 
        sx={{
          backgroundColor: 'white',
          borderRadius: '24px',
          mb: 2,
          boxShadow: '0 2px 6px rgba(0, 0, 0, 0.05)',
          overflow: 'hidden'
        }}
      >
        {/* Table Header */}
        <Box sx={{
          ...gridStyles,
          py: 2,
          backgroundColor: '#F9FAFB',
          borderBottom: '1px solid #E5E7EB',
        }}>
          {['Concepto', 'Periodo', 'Fecha', 'Cod. Operación', 'Monto', 'Deuda', 'Estado', 'Acciones'].map(header => (
            <Typography key={header} sx={{ color: '#6B7280', fontSize: 14, fontWeight: 600 }}>
              {header}
            </Typography>
          ))}
        </Box>

        {combinedData.length === 0 ? (
          <Box sx={{ p: 4, textAlign: 'center' }}>
            <Typography sx={{ color: '#6B7280', fontSize: 14 }}>
              Aquí podrás ver el resumen tanto de la emisión de la cuota de mantenimiento como de los pagos realizados para este departamento.
            </Typography>
          </Box>
        ) : (
          combinedData.map((item, idx) => {
            const isCharge = item.type === 'charge';
            const concepto = item.concepto;
            const periodo = formatMonthYear(item.periodo);
            
            const fecha  = formatDate(item.date);
            const codigoOperacion = isCharge ? (item.referenceNumber || 'N/A') : (item.referenceNumber || 'N/A');
            const monto = isCharge ? (item.total_amount || 0) : (item.amount || 0);
            let deuda = 0;
            // If charges have emiCuota and montoPagado fields, we can calculate:
            // deuda = emiCuota - montoPagado if montoPagado < emiCuota
            // Otherwise, just show 0 if fully paid.
            if (isCharge) {
              const emiCuota = item.emiCuota !== undefined ? item.emiCuota : monto;
              const montoPagado = item.montoPagado || 0;
              deuda = emiCuota > montoPagado ? (emiCuota - montoPagado) : 0;
            } else {
              // For payments, deuda is typically zero
              deuda = 0;
            }

            // Status
            const itemStatus = item.status || 'rejected'; 
            const label = statusLabels[itemStatus] || statusLabels.rejected;
            const style = statusStyles[itemStatus] || statusStyles.rejected;

            // Background for charges (bluish), for payments (white)
            const rowBg = isCharge ? '#F0F9FF' : 'white';

            return (
              <Box
                key={item.id || idx}
                sx={{
                  ...gridStyles,
                  py: 2,
                  borderBottom: idx < combinedData.length - 1 ? '1px solid #E5E7EB' : 'none',
                  '&:hover': { backgroundColor: '#F9FAFB' },
                  backgroundColor: rowBg
                }}
              >
                <Typography sx={{ fontSize: 14 }}>
                  {concepto}
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                  {periodo}
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                  {fecha}
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                  {codigoOperacion}
                </Typography>
                <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                  S/ {monto.toFixed(2)}
                </Typography>
                <Typography sx={{ fontSize: 14, fontWeight: 600, color: item.runningDeuda === 0 ? '#6B7280' : (item.runningDeuda > 0 ? 'red' : '#6B7280') }}>
                  S/ {item.runningDeuda.toFixed(2)}
                </Typography>
                {/* Estado column - empty for charges */}
                {isCharge ? (
                  <Box /> // Empty box for charges
                ) : (
                  <Chip
                    label={label}
                    sx={{
                      fontSize: 14,
                      backgroundColor: style.bg,
                      color: style.text,
                      fontWeight: 500,
                      borderRadius: '16px',
                      width: 'fit-content'
                    }}
                  />
                )}
                {/* Actions column - empty for charges */}
                {isCharge ? (
                  <Box /> // Empty box for charges
                ) : (
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {item.status === 'pending' && (
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          textTransform: 'none',
                          fontSize: 14,
                          borderColor: '#E5E7EB',
                          color: '#6B7280',
                          borderRadius: '32px',
                          padding: '2px 12px',
                          height: 'auto',
                          minWidth: 'unset',
                          '&:hover': {
                            borderColor: '#4F46E5',
                            color: '#4F46E5',
                          }
                        }}
                        onClick={() => handleConciliarClick(item)}
                      >
                        Conciliar
                      </Button>
                    )}
                    {item.status !== 'pending' &&
                      <IconButton
                        size="small"
                        sx={{
                          color: '#6B7280',
                          '&:hover': {
                            color: '#4F46E5',
                          },
                        }}
                        onClick={() => handleConciliarClick(item)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    }
                  </Box>
                )}
              </Box>
            );
          })
        )}
      </Box>

      {/* Conciliate Payment Drawer */}
      {openConciliateDrawer && selectedPayment && (
        <ConciliatePaymentDrawer
          open={openConciliateDrawer}
          onClose={handleDrawerClose}
          payment={selectedPayment}
        />
      )}
    </>
  );
};

export default React.memo(MaintenanceFeeDetailTable);