import { MenuItem, Select, SelectChangeEvent, styled } from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useGetBuildingData from "../hooks/useGetBuidingData";
import { useEffect } from "react";
import { Building } from "../../features/account/types";
import useGlobalStore from "../../state/store";
import { useBuildingAnalytics } from "../hooks/useBuildingAnalytics";

export function SelectBuildingApartment() {
  const { user, buildingId, setBuildingId } = useGlobalStore();
  const { getBuildingAssociatedData } = useGetBuildingData();
  const { trackBuildingSelected } = useBuildingAnalytics();

  const handleBuildingChange = (event: SelectChangeEvent<unknown>) => {
    const newBuildingId = event.target.value as string;
    setBuildingId(newBuildingId);
    getBuildingAssociatedData({ buildingId: newBuildingId });
    trackBuildingSelected(newBuildingId);
  };

  useEffect(() => {
    if (buildingId) {
      getBuildingAssociatedData({ buildingId });
    }
  }, [buildingId]);

  if (!user?.buildings?.length) return null;

  return (
    <StyledSelect
      variant="outlined"
      IconComponent={ArrowDropDownIcon}
      displayEmpty
      defaultValue={buildingId || ''}
      value={buildingId || ''}
      onChange={handleBuildingChange}
    >
      {user.buildings.map((building: Building) => (
        <MenuItem key={building.id} value={building.id}>
          {building.name}
        </MenuItem>
      ))}
    </StyledSelect>
  );
}

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: 100,
  color: theme.palette.primary.main,
  minWidth: 250,
  paddingLeft: 20,
  fontSize: 15,
  "& .MuiSelect-select": {
    display: "flex",
    alignItems: "center",
    padding: "8px 16px",
  },
  "& .MuiSvgIcon-root": {
    right: "auto", // quita el posicionamiento por defecto a la derecha
    left: "0", // coloca el ícono a la izquierda
    position: "absolute",
    pointerEvents: "none",
    color: theme.palette.primary.main,
    transform: "none",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none", // Quita el borde, si así lo deseas
  },
}));

export default SelectBuildingApartment;
