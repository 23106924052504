// src/features/manualVisitRegistration/hooks/useManualRegistrationAnalytics.js

import { useCallback } from 'react';
import { useAnalytics } from '../../../analytics/hooks/useAnalytics';
import { EVENT_TYPES } from '../../../analytics/config/events';

export const useManualRegistrationAnalytics = () => {
  const { track } = useAnalytics();

  const trackRegistrationStarted = useCallback((userId, buildingId) => {
    track(EVENT_TYPES.MANUAL_REGISTRATION_STARTED, {
      userId,
      buildingId,
      timestamp: new Date().toISOString()
    });
  }, [track]);

  const trackVisitorSearched = useCallback((userId, documentNumber, wasFound) => {
    track(EVENT_TYPES.VISITOR_SEARCHED, {
      userId,
      documentNumber,
      wasFound,
      timestamp: new Date().toISOString()
    });
  }, [track]);

  const trackNewVisitorCreated = useCallback((userId, visitorData) => {
    const { email, phone, documentNumber, ...safeData } = visitorData;
    track(EVENT_TYPES.NEW_VISITOR_CREATED, {
      userId,
      hasEmail: !!email,
      hasPhone: !!phone,
      hasDocument: !!documentNumber,
      ...safeData,
      timestamp: new Date().toISOString()
    });
  }, [track]);

  const trackManualVisitCreated = useCallback((userId, visitData) => {
    const { visitId, apartmentId, buildingId } = visitData;
    track(EVENT_TYPES.MANUAL_VISIT_CREATED, {
      userId,
      visitId,
      apartmentId,
      buildingId,
      timestamp: new Date().toISOString()
    });
  }, [track]);

  const trackRegistrationCompleted = useCallback((userId, registrationDetails) => {
    const { timeSpent, stepsCompleted, ...safeDetails } = registrationDetails;
    track(EVENT_TYPES.MANUAL_REGISTRATION_COMPLETED, {
      userId,
      timeSpent,
      stepsCompleted,
      ...safeDetails,
      timestamp: new Date().toISOString()
    });
  }, [track]);

  const trackRegistrationFailed = useCallback((userId, error, step) => {
    track(EVENT_TYPES.MANUAL_REGISTRATION_FAILED, {
      userId,
      errorMessage: error.message,
      errorCode: error.code,
      step,
      timestamp: new Date().toISOString()
    });
  }, [track]);

  return {
    trackRegistrationStarted,
    trackVisitorSearched,
    trackNewVisitorCreated,
    trackManualVisitCreated,
    trackRegistrationCompleted,
    trackRegistrationFailed,
  };
};