// src/features/invitation/pages/VisitList.js

import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Alert,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useNavigation } from "../../../navigation/hooks/useNavigation";
import { useVisits } from "../hooks/useVisits";
import TodayVisits from "../components/TodayVisits";
import FutureVisits from "../components/FutureVisits";
import HistoricalVisits from "../components/HistoricalVisits";
import { ROUTES } from "../../../config/routes";
import { VisitListSkeleton } from "../components/VisitListSkeleton";
import useGlobalStore from "../../../state/store";
import { useInvitationAnalytics } from "../hooks/useInvitationAnalytics";

const tabToIndexMap: Record<string, number> = {
  today: 0,
  future: 1,
  history: 2,
};

const getTabIndex = (tabName: string) => {
  return tabToIndexMap[tabName] ?? 0;
};

const VisitMain = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get("activeTab");
  const [tabValue, setTabValue] = useState(getTabIndex(String(activeTab)));
  const buildingId = useGlobalStore((state) => state.buildingId);
  const user = useGlobalStore((state) => state.user);
  const { trackVisitTabChanged } = useInvitationAnalytics();


  const { visits, loading, error, getVisits, hasCachedVisits } = useVisits();

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    const tabs = ["today", "future", "history"];
    navigate(`${ROUTES.VISITS}?activeTab=${tabs[newValue]}`, { replace: true });
    if (user?.id) {
      trackVisitTabChanged(user.id, tabs[newValue]);
    }
  };

  const memoizedTodayVisits = useMemo(
    () => <TodayVisits visits={visits} />,
    [visits]
  );
  const memoizedFutureVisits = useMemo(
    () => <FutureVisits visits={visits} />,
    [visits]
  );
  const memoizedHistoricalVisits = useMemo(
    () => <HistoricalVisits visits={visits} />,
    [visits]
  );

  useEffect(() => {
    if (!hasCachedVisits && buildingId) {
      getVisits({ buildingId });
    }
  }, []);

  if (loading) return <VisitListSkeleton />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Box sx={{ width: "100%", maxWidth: 600, margin: "auto" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h5">Visitas</Typography>
        <Link to={ROUTES.MANUAL_VISIT_REGISTRATION}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              minWidth: "unset",
              padding: "6px 16px",
              fontSize: "0.875rem",
              height: "32px",
              borderRadius: "16px",
              whiteSpace: "nowrap",
              width: "auto",
            }}
          >
            Registrar
          </Button>
        </Link>
      </Box>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="visit tabs"
        sx={{ mb: 2 }}
      >
        <Tab label="Hoy" />
        <Tab label="Futuras" />
        <Tab label="Historial" />
      </Tabs>
      <Box>
        {tabValue === 0 && memoizedTodayVisits}
        {tabValue === 1 && memoizedFutureVisits}
        {tabValue === 2 && memoizedHistoricalVisits}
      </Box>
    </Box>
  );
};

export default React.memo(VisitMain);
