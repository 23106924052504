import { useState, useCallback, useEffect } from 'react';
import useGlobalStore from '../../../state/store';
import { maintenanceFeeService } from '../services/maintenanceFeeService';
import { MaintenanceFee } from '../types';

export const useMaintenanceFee = () => {
  const { maintenanceFees, setMaintenanceFees, buildingId, filters } = useGlobalStore();
  const hasCachedMaintenanceFees = maintenanceFees.data?.length > 0;

  const fetchMaintenanceData = useCallback(async ({
    buildingId,
    period,
  }: {
    buildingId: string,
    period: string,
  }) => {
    try {
      if (maintenanceFees.data.length > 0 && period === filters.maintenanceFeesPeriod) {
        return maintenanceFees.data;
      }
      setMaintenanceFees({ loading: true, error: null });

      const [apartments, { charges }, { payments }] = await Promise.all([
        maintenanceFeeService.fetchApartmentsByBuildingId(buildingId),
        maintenanceFeeService.fetchMaintenanceData(buildingId, period),
        maintenanceFeeService.fetchPayments(buildingId, {
          startDate: `${period}-01`,
          endDate: new Date(
            parseInt(period.split('-')[0]),
            parseInt(period.split('-')[1]),
            0
          ).toISOString().split('T')[0]
        })
      ]);

      const processedData: MaintenanceFee[] = apartments.map(apt => {
        const aptCharges = charges.filter(charge => charge.apartmentNumber === apt.apartmentNumber);
        const aptPayments = payments.filter(payment => payment.apartmentId === apt.id);
        const emiCuota = aptCharges
          .filter((c) => c.charge_type === "maintenance_fee")
          .reduce((sum, c) => sum + (c.total_amount || 0), 0);

        const totalCharges = aptCharges.reduce(
          (sum, c) => sum + (c.total_amount || 0),
          0
        );

        const montoPagado = aptPayments
          .filter((p) => p.status === "verified")
          .reduce((sum, p) => sum + (p.amount || 0), 0);

        const porValidar = aptPayments
          .filter((p) => p.status === "pending")
          .reduce((sum, p) => sum + (p.amount || 0), 0);

        const deuda = Math.max(0, totalCharges - montoPagado);

        // Only consider residents that are active
        const activeResidents = apt.residents.filter(resident => resident.is_active !== false);
        
        const residentName = activeResidents.length > 0
          ? `${activeResidents[0].first_name} ${activeResidents[0].last_name}`
          : "Sin residente";

        return {
          id: apt.id,
          apartmentNumber: apt.apartmentNumber,
          residentName,
          emiCuota,
          montoPagado,
          porValidar,
          deuda,
          payments: aptPayments,
          charges: aptCharges,
          residents: activeResidents, // Only return active residents
        };
      });

      setMaintenanceFees({
        data: processedData,
        loading: false,
        error: null,
      });

      return processedData;
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Error desconocido';
      setMaintenanceFees({ loading: false, error: errorMessage });
      return [];
    } finally {
      setMaintenanceFees({ loading: false });
    }
  }, [maintenanceFees.data, filters.maintenanceFeesPeriod]);

  useEffect(() => {
    if (buildingId) {
      fetchMaintenanceData({
        buildingId,
        period: filters.maintenanceFeesPeriod,
      });
    }
  }, [buildingId, filters.maintenanceFeesPeriod]);

  const getMaintenanceFeeDetails = useCallback((maintenanceFeeId: string): MaintenanceFee | null => {
    if (!maintenanceFees.data) {
      return null;
    }
  
    return maintenanceFees.data.find(fee => fee.id === maintenanceFeeId) || null;
  }, [maintenanceFees.data]);

  return {
    maintenanceFees: maintenanceFees.data ?? [],
    loading: maintenanceFees.loading,
    error: maintenanceFees.error,
    fetchMaintenanceData,
    hasCachedMaintenanceFees,
    getMaintenanceFeeDetails
  };
};