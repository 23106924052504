// src/features/incident/pages/CreateIncident.js
import React from "react";
import { Container, Typography, Box, Alert, IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { IncidentForm } from "../components/create";
import { useIncident } from "../hooks/useIncident";

import { useNavigation } from "../../../navigation/hooks/useNavigation";
import { ROUTES } from "../../../config/routes";
import useGlobalStore from "../../../state/store";
import { Link } from "react-router-dom";

const CreateIncident = () => {
  const { navigateWithParams } = useNavigation();
  const { createIncident, error } = useIncident();
  const building_id = useGlobalStore((state) => state.buildingId);


  const user = useGlobalStore((state) => state.user);

  const handleSubmit = async (formData) => {
    try {
    
      if (!building_id) {
        throw new Error("No building assigned to user");
      }

      const result = await createIncident({
        ...formData,
        building_id,
      });

      if (result?.data?.incident_id) {
        navigateWithParams(`/incidents/${result.data.incident_id}`);
      }
    } catch (err) {
      console.error("Error creating incident:", err);
    }
  };

  return (
    <Container maxWidth="lg">
      <Box py={4}>
        <Box display="flex" alignItems="center" mb={3}>
          <Link to={ROUTES.INCIDENTS}>
            <IconButton sx={{ mr: 2 }}>
              <ArrowBack />
            </IconButton>
          </Link>
          <Typography variant="h5" gutterBottom>
            Reportar Incidencia
          </Typography>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        <IncidentForm onSubmit={handleSubmit} />
      </Box>
    </Container>
  );
};

export default CreateIncident;
