import { isValidNonEmptyArray } from "../../../shared/utils/isValidNonEmptyArray";
import { WaterConsumption } from "../types";
import * as XLSX from "xlsx";

export default function exportWaterConsumptionsToExcel(
  waterConsumptions: WaterConsumption[]
) {
  if (!isValidNonEmptyArray(waterConsumptions)) {
    return;
  }

  const exportData = waterConsumptions.map((meter) => ({
    Departamento: meter.apartment,
    Periodo: meter.period,
    "Lectura Inicial (m3)": meter.initial,
    "Lectura Final (m3)": meter.final,
    "Consumo (m3)": meter.consumption,
    "Costo (S/)": `${meter.amount?.toFixed(2) || 0} `,
  }));
  const worksheet = XLSX.utils.json_to_sheet(exportData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Contadores");
  XLSX.writeFile(workbook, "Contadores.xlsx");
}
