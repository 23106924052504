import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Tooltip,
  IconButton,
  Skeleton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import MeterActions from "./MeterActions";
import createNumberArray from "../../../shared/utils/createNumberArray";
import { getRandomNumber } from "../../../shared/utils/getRandomNumber";

/**
 * Estructura de cada fila de medidor
 */
export interface MeterRow {
  id: string;
  apartment: string;
  period: string;
  initial: number | string;
  final: number | string;
  consumption?: number | string;
  created_at: string;
  image_url?: string;
  needsEdit?: boolean;
  amount?: number;
}

/**
 * Props que recibe la tabla
 */
interface MeterTableProps {
  onDetailOpen: (row: MeterRow) => void;
  costPerM3: number;
  selectedDate: string;
  meters: MeterRow[];
  loading: boolean;
}

const MeterTable: React.FC<MeterTableProps> = ({
  onDetailOpen,
  costPerM3,
  meters,
  loading,
}) => {
  if (!meters.length && !loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Typography variant="body1" color="textSecondary">
          No se encontraron registros de medidores.
        </Typography>
      </Box>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Departamento</TableCell>
            <TableCell>Período</TableCell>
            <TableCell>Lectura anterior (m3)</TableCell>
            <TableCell>Lectura actual (m3)</TableCell>
            <TableCell>Consumo (m3)</TableCell>
            <TableCell>
              Monto
              <Tooltip title="Monto calculado basado en el consumo y el costo por m3">
                <IconButton size="small">
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableSkeleton />
          ) : (
            meters.map((row) => {
              const consumptionNum =
                typeof row.consumption === "number"
                  ? row.consumption
                  : row.consumption
                  ? parseFloat(row.consumption)
                  : 0;

              // 2. Calcular monto
              const amount = consumptionNum * costPerM3;
              row.amount = amount;

              

              return (
                <TableRow key={row.id}>
                  <TableCell>{row.apartment}</TableCell>
                  <TableCell>{row.period}</TableCell>

                  {/* initial y final son strings o números; aquí solo se muestran */}
                  <TableCell>
                    {row.initial !== undefined && row.initial !== ""
                      ? parseFloat(row.initial.toString()).toFixed(3)
                      : "No agregada"}
                  </TableCell>
                  <TableCell>
                    {row.final !== undefined && row.final !== ""
                      ? parseFloat(row.final.toString()).toFixed(3)
                      : "No agregada"}
                  </TableCell>

                  {/* Mostrar el consumo convertido a número */}
                  <TableCell>{consumptionNum.toFixed(3)}</TableCell>

                  {/* Mostrar el monto calculado, formateado a 2 decimales */}
                  <TableCell>S/ {amount.toFixed(2)}</TableCell>

                  <TableCell>
                    <MeterActions meter={row} onViewDetails={onDetailOpen} />
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TableRowSkeleton = () => (
  <TableRow>
    {createNumberArray(7).map((column) => (
      <TableCell key={column}>
        <Skeleton width={getRandomNumber(50, 90) + "%"} height={18} />
      </TableCell>
    ))}
  </TableRow>
);

const TableSkeleton = () =>
  createNumberArray(10).map((row) => <TableRowSkeleton key={row} />);

export default MeterTable;
