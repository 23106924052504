import { meterService } from "../services/meterService";
import useGlobalStore from "../../../state/store";
import { isValidNonEmptyArray } from "../../../shared/utils/isValidNonEmptyArray";
import { WaterConsumption } from "../types";
import { useBuilding } from "../../manualVisitRegistration/hooks/useBuilding";
import { manualVisitRegistrationService } from "../../manualVisitRegistration/services/manualVisitRegistrationService";

export const useWaterConsumptions = () => {

  const waterConsumptions = useGlobalStore((state) => state.waterConsumptions);
  const setWaterConsumptions = useGlobalStore(
    (state) => state.setWaterConsumptions
  );
  const hasCachedWaterConsumptions = isValidNonEmptyArray(waterConsumptions.data);

  const getWaterConsumptions = async (waterConsumptionPeriod: string, buildingId: string) => {
    try {
      setWaterConsumptions({ data: [], loading: true, error: null });

      const apartments = await manualVisitRegistrationService.fetchApartmentsForBuilding(buildingId);
      const meterData = await meterService.getMeters(buildingId, waterConsumptionPeriod);
      const meterMap = new Map(meterData.map(m => [m.apartment, m]));
      const mergedData = (apartments ?? []).map((apartment: any) => {
        const existingMeter = meterMap.get(apartment.apartment_number);
        return existingMeter || {
          id: apartment.id,
          apartment: apartment.apartment_number,
          period: formatPeriod(waterConsumptionPeriod),
          initial: "",
          final: "",
          consumption: 0.000,
          created_at: "",
          needsEdit: true,
          readingPeriodRaw: waterConsumptionPeriod,
          isNew: true
        };
      }).sort((a, b) => a.apartment.localeCompare(b.apartment, undefined, { numeric: true }));


      setWaterConsumptions({ data: mergedData });
    } catch (error) {
      setWaterConsumptions({ error: "Error getting water consumption." });
    } finally {
      setWaterConsumptions({ loading: false });
    }
  };

  const updateWaterConsumption = async ({
    waterConsumption,
    waterConsumptionPeriod,
    buildingId
  }: {
    waterConsumption: WaterConsumption;
    waterConsumptionPeriod: string;
    buildingId: string;
  }) => {
    try {
      await meterService.updateMeter(waterConsumption);
      await getWaterConsumptions(waterConsumptionPeriod, buildingId);
    } catch (error) {
      setWaterConsumptions({
        error: "Error updating water consumption.",
      });
    }
  };

  return {
    waterConsumptions: waterConsumptions.data ?? [],
    loading: waterConsumptions.loading,
    error: waterConsumptions.error ?? null,
    updateWaterConsumption,
    hasCachedWaterConsumptions: isValidNonEmptyArray(waterConsumptions.data),
    getWaterConsumptions,
  };
};


function formatPeriod(rp: string): string {
  const [year, month] = rp.split("-");
  const dateObj = new Date(+year, +month - 1, 1);
  return dateObj.toLocaleString("es-ES", { month: "long", year: "numeric" });
}