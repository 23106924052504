// src/features/incident/hooks/useIncidentAnalytics.js
import { useCallback } from 'react';
import { useAnalytics } from '../../../analytics/hooks/useAnalytics';
import { EVENT_TYPES } from '../../../analytics/config/events';

export const useIncidentAnalytics = () => {
  const { track } = useAnalytics();

  const trackIncidentCreated = useCallback((incidentId, metadata = {}) => {
    track(EVENT_TYPES.INCIDENT_CREATED, {
      incidentId,
      ...metadata,
      timestamp: new Date().toISOString()
    });
  }, [track]);

  const trackIncidentUpdated = useCallback((incidentId, metadata = {}) => {
    track(EVENT_TYPES.INCIDENT_UPDATED, {
      incidentId,
      ...metadata,
      timestamp: new Date().toISOString()
    });
  }, [track]);

  const trackStatusChanged = useCallback((incidentId, newStatus) => {
    track(EVENT_TYPES.INCIDENT_STATUS_CHANGED, {
      incidentId,
      newStatus,
      timestamp: new Date().toISOString()
    });
  }, [track]);

  const trackIncidentViewed = useCallback((incidentId) => {
    track(EVENT_TYPES.INCIDENT_VIEWED, {
      incidentId,
      timestamp: new Date().toISOString()
    });
  }, [track]);

  const trackCommentAdded = useCallback((incidentId, hasMedia = false) => {
    track(EVENT_TYPES.INCIDENT_COMMENT_ADDED, {
      incidentId,
      hasMedia,
      timestamp: new Date().toISOString()
    });
  }, [track]);

  const trackResolutionRated = useCallback((incidentId, rating) => {
    track(EVENT_TYPES.INCIDENT_RATED, {
      incidentId,
      rating,
      timestamp: new Date().toISOString()
    });
  }, [track]); 
  
  const trackIncidentAssigned = useCallback((incidentId, workerId) => {
    track(EVENT_TYPES.INCIDENT_ASSIGNED, {
      incidentId,
      workerId,
      timestamp: new Date().toISOString()
    });
  }, [track]);

  return {
    trackIncidentCreated,
    trackIncidentAssigned,
    trackIncidentUpdated,
    trackStatusChanged,
    trackIncidentViewed,
    trackCommentAdded,
    trackResolutionRated
  };
};

