// src/features/manualVisitRegistration/hooks/useBuilding.js

import { useState, useEffect } from "react";
import { manualVisitRegistrationService } from "../services/manualVisitRegistrationService";
import useGlobalStore from "../../../state/store";
import { useManualRegistrationAnalytics } from "./useManualRegistrationAnalytics";

export const useBuilding = () => {
  const [apartments, setApartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const user = useGlobalStore((state) => state.user);
  const buildingId = useGlobalStore((state) => state.buildingId);
  const setBuildingId = useGlobalStore((state) => state.setBuildingId); 
  const { trackRegistrationFailed } = useManualRegistrationAnalytics();

  useEffect(() => {
    const fetchApartments = async () => {
      if (!user || !user.id) {
        setError("No se pudo determinar el usuario actual.");
        setLoading(false);
        return;
      } 
      
      try {
        const apartmentsData = await manualVisitRegistrationService.fetchApartmentsForBuilding(
          buildingId
        );
        
        setApartments(apartmentsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching apartments:", error);
        setError("Error al cargar los apartamentos. Por favor, intente de nuevo.");
        setLoading(false);
        if (user?.id) {
          trackRegistrationFailed(user.id, error, 'fetch_apartments');
        }
      }
    };
  
    fetchApartments();
  }, [user, setBuildingId, buildingId]); 

  console.log("buildingId desde el hook:", buildingId);

  return { apartments, loading, error, buildingId };
};
