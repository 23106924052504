import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Chip,
  Divider,
  IconButton,
  CircularProgress,
  Alert,
  Snackbar,
  Stepper,
  Step,
  StepLabel,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Paper,
  Collapse,
  Switch,
  Tooltip
} from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CloseIcon from '@mui/icons-material/Close';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useGlobalStore from "../../../../state/store";
import { notificationService } from '../../services/notificationService';
import { useMaintenanceFeeAnalytics } from '../../hooks/useMaintenanceFeeAnalytics';

const REMINDER_TEMPLATES = [
  {
    id: 'gentle',
    title: 'Recordatorio Amable',
    message: `Estimado(a) {{ residente }},

Le recordamos que actualmente tiene una deuda pendiente de S/ {{ monto }} correspondiente a la cuota de mantenimiento del departamento {{ apartamento }} - {{ edificio }}.

Agradecemos su pronto pago. Si ya realizó el pago, por favor háganos saber para verificarlo.

Opciones de pago:
• {{ opciones_pago }}

¡Gracias!`,
    tone: 'friendly'
  },
  {
    id: 'formal',
    title: 'Recordatorio Formal',
    message: `Estimado(a) {{ residente }}:

Le informamos que registra una deuda de S/ {{ monto }} por concepto de mantenimiento del departamento {{ apartamento }} - {{ edificio }}. Le solicitamos regularizar el pago a la brevedad posible para evitar recargos adicionales.

Puede realizar su pago mediante:
• {{ opciones_pago }}

Gracias por su atención.`,
    tone: 'formal'
  },
  {
    id: 'urgent',
    title: 'Recordatorio Urgente',
    message: `⚠️ IMPORTANTE:

Sr(a). {{ residente }}, su deuda de mantenimiento del departamento {{ apartamento }} - {{ edificio }} asciende a S/ {{ monto }} y tiene {{ meses }} meses de atraso.

Es urgente que regularice su situación para evitar la suspensión de servicios y otras medidas administrativas.

Métodos de pago disponibles:
• {{ opciones_pago }}

Contáctenos a la brevedad.`,
    tone: 'urgent'
  }
];

const formatPhoneNumber = (phone) => {
  if (!phone) return '';
  
  // Remove any non-digit characters except + at the start
  const cleaned = phone.replace(/[^\d+]/g, '');
  
  // If it already starts with +, return as is
  if (cleaned.startsWith('+')) {
    return cleaned;
  }
  
  // If it starts with a country code (2-3 digits), add +
  if (/^[1-9]\d{1,2}/.test(cleaned)) {
    return `+${cleaned}`;
  }
  
  // If no country code is present, assume Peru (+51)
  return `+51 ${cleaned}`;
};

const sanitizeWhatsAppText = (text) => {
  return text
    .replace(/[\n\t]/g, ' ') // Replace newlines and tabs with spaces
    .replace(/\s+/g, ' ') // Replace multiple spaces with single space
    .trim(); // Remove leading/trailing spaces
};

// Sample building info to use if not provided
const DEFAULT_BUILDING_INFO = {
  name: "Mi Edificio",
  paymentOptions: [
    "Transferencia bancaria BCP: 123-456-789",
    "Yape al número: 912 345 678"
  ]
};

// Map our template IDs to the API's expected format
const TEMPLATE_ID_MAP = {
  'gentle': 'reminder_warm',
  'formal': 'reminder_formal',
  'urgent': 'reminder_urgent'
};

const WhatsAppReminderModal = ({ open, onClose, apartments = [], buildingInfo = DEFAULT_BUILDING_INFO, debtCollectionSettings = { enabled: false, whatsapp_enabled: false } }) => {
  // Get building data from global store
  const user = useGlobalStore((state) => state.user);
  const currentBuildingId = useGlobalStore((state) => state.buildingId);
  
  // Find the current building name from the user's buildings array
  const currentBuilding = user?.buildings?.find(b => b.id === currentBuildingId);
  const buildingName = currentBuilding?.name || buildingInfo?.name || "Mi Edificio";
  
  // Step management
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Seleccionar Destinatarios', 'Personalizar Mensaje', 'Confirmar Envío'];
  
  // Apartment and resident selection
  const [selectedApartments, setSelectedApartments] = useState([]);
  const [expandedApartments, setExpandedApartments] = useState({});
  const [selectedResidents, setSelectedResidents] = useState({});
  
  // Message customization - custom message states kept but not used
  const [selectedTemplate, setSelectedTemplate] = useState('gentle');
  const [customMessage, setCustomMessage] = useState('');
  const { trackReminderSent} = useMaintenanceFeeAnalytics();
  
  // UI states
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  
  // Filter apartments with debt and sort by total debt descending
  const eligibleApartments = apartments
    .filter(apt => apt.totalDebt > 0)
    .sort((a, b) => b.totalDebt - a.totalDebt);
  
  // Enhanced each apartment with actual residents instead of demo data
  const apartmentsWithResidents = eligibleApartments.map(apt => ({
    ...apt,
    // Use the actual residents from apt.residents (which comes from the database)
    // If no residents, provide an empty array
    residents: apt.residents || []
  }));
  
  // Handle apartment expansion toggle
  const toggleApartmentExpansion = (aptId) => {
    setExpandedApartments(prev => {
      const isExpanding = !prev[aptId];
      
      // If we're expanding and the apartment is selected, make sure all residents are displayed
      if (isExpanding && selectedApartments.includes(aptId)) {
        const apt = apartmentsWithResidents.find(a => a.id === aptId);
        if (apt && apt.residents.length > 0) {
          // Get all resident IDs for this apartment
          const allResidentIds = apt.residents.map(resident => resident.id);
          
          // Update the selected residents state to include all residents
          setSelectedResidents(prevState => ({
            ...prevState,
            [aptId]: allResidentIds
          }));
        }
      }
      
      return {
        ...prev,
        [aptId]: !prev[aptId]
      };
    });
  };
  
  // Handle apartment selection toggle
  const toggleApartmentSelection = (aptId) => {
    if (selectedApartments.includes(aptId)) {
      setSelectedApartments(selectedApartments.filter(id => id !== aptId));
      
      // Also remove any selected residents for this apartment
      const newSelectedResidents = { ...selectedResidents };
      delete newSelectedResidents[aptId];
      setSelectedResidents(newSelectedResidents);
    } else {
      setSelectedApartments([...selectedApartments, aptId]);
      
      // Initialize resident selection with all residents if there are 2 or more
      const apt = apartmentsWithResidents.find(a => a.id === aptId);
      if (apt && apt.residents.length > 0) {
        // Filter for active residents only (those with is_active = true)
        const activeResidents = apt.residents.filter(r => r.is_active !== false);
        
        // If there are 2 or more active residents, select all of them
        if (activeResidents.length >= 2) {
          setSelectedResidents(prev => ({
            ...prev,
            [aptId]: activeResidents.map(r => r.id)
          }));
        } else if (activeResidents.length === 1) {
          // If only one active resident, select just that one
          const primaryResident = activeResidents[0];
          setSelectedResidents(prev => ({
            ...prev,
            [aptId]: [primaryResident.id]
          }));
        }
      }
    }
  };
  
  // Handle resident selection toggle
  const toggleResidentSelection = (aptId, residentId) => {
    setSelectedResidents(prev => {
      const currentSelected = prev[aptId] || [];
      const newSelected = currentSelected.includes(residentId)
        ? currentSelected.filter(id => id !== residentId)
        : [...currentSelected, residentId];
      
      return {
        ...prev,
        [aptId]: newSelected
      };
    });
  };
  
  // Handle select all apartments
  const handleSelectAllApartments = () => {
    if (selectedApartments.length === apartmentsWithResidents.length) {
      // Deselect all
      setSelectedApartments([]);
      setSelectedResidents({});
      setExpandedApartments({});
    } else {
      // Select all apartments
      const allAptIds = apartmentsWithResidents.map(apt => apt.id);
      setSelectedApartments(allAptIds);
      
      // Select ALL residents for each apartment that have phones
      const initialResidentSelection = {};
      apartmentsWithResidents.forEach(apt => {
        // Get all residents that have phone numbers
        const validResidents = apt.residents.filter(r => r.phone);
        if (validResidents.length > 0) {
          initialResidentSelection[apt.id] = validResidents.map(r => r.id);
        }
      });
      
      setSelectedResidents(initialResidentSelection);

      // Expand all apartments
      const expandedState = {};
      allAptIds.forEach(id => {
        expandedState[id] = true;
      });
      setExpandedApartments(expandedState);
    }
  };

  // Prepare payment options text
  const getPaymentOptionsText = () => {
    if (!buildingInfo || !buildingInfo.bank_accounts?.accounts || buildingInfo.bank_accounts.accounts.length === 0) {
      return "Contactar a administración para detalles de pago";
    }
    
    return buildingInfo.bank_accounts.accounts.map(acc => {
      if (acc.payment_method === 'bank_transfer') {
        return `${acc.provider_name}: ${acc.account_type === 'savings' ? 'Ahorros' : 'Corriente'} ${acc.account_number}${acc.cci_code ? ` (CCI: ${acc.cci_code})` : ''}`;
      } else if (acc.payment_method === 'digital_wallet') {
        return `${acc.provider_name}: ${acc.phone_number}`;
      } else if (acc.payment_method === 'cash') {
        return `${acc.alias || 'Pago en efectivo'}`;
      } else if (acc.payment_method === 'other') {
        return `${acc.alias}: ${acc.payment_link}`;
      }
      return acc.alias || acc.provider_name;
    }).join("\n• ");
  };
  
  // Calculate message preview by replacing placeholders
  const getMessagePreview = (templateId, aptId) => {
    const messageTemplate = REMINDER_TEMPLATES.find(t => t.id === templateId)?.message || '';
    
    const apartment = apartmentsWithResidents.find(a => a.id === aptId);
    if (!apartment) return messageTemplate;
    
    // Get the first selected resident for this apartment
    const residentId = selectedResidents[aptId]?.[0];
    const resident = apartment.residents.find(r => r.id === residentId);
    const fullName = resident ? resident.name : apartment.residentName;
    // Get only the first word of the name (usually the first name)
    const residentName = fullName?.split(' ')[0] || '';
    
    // Payment options 
    const paymentOptions = getPaymentOptionsText();
    
    // Use the building name from the store
    return messageTemplate
      .replace('{{ residente }}', residentName)
      .replace('{{ monto }}', apartment.totalDebt.toLocaleString('es-PE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
      .replace('{{ meses }}', apartment.monthsOfDebt || '1')
      .replace('{{ apartamento }}', apartment.apartmentNumber || 'N/A')
      .replace('{{ edificio }}', buildingName)
      .replace('{{ opciones_pago }}', paymentOptions);
  };
  
  // Calculate total selected recipients
  const getTotalRecipients = () => {
    return Object.entries(selectedResidents).reduce((count, [aptId, residentIds]) => {
      const apt = apartmentsWithResidents.find(a => a.id === aptId);
      if (!apt) return count;
      
      // Only count residents who have phone numbers and are selected
      const validResidents = residentIds.filter(resId => {
        const resident = apt.residents.find(r => r.id === resId);
        return resident && resident.phone;
      });
      
      return count + validResidents.length;
    }, 0);
  };

  // Handle next step
  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };
  
  // Handle back step
  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };
  
  // Handle final send action
  const handleSendReminders = async () => {
    if (getTotalRecipients() === 0) {
      setError('Por favor seleccione al menos un destinatario');
      return;
    }

    setLoading(true);
    
    try {
      // Map our template ID to the API's expected format
      const templateId = TEMPLATE_ID_MAP[selectedTemplate] || 'reminder_nice';
      
      // Prepare the notifications data
      const notifications = [];
      
      // Check if WhatsApp collection is enabled
      if (!debtCollectionSettings.whatsapp_enabled) {
        console.log('WhatsApp collection is disabled, running in test mode');
        // In test mode, just show success after a delay
        await new Promise(resolve => setTimeout(resolve, 1000));
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          onClose();
        }, 2000);
        setLoading(false);
        return;
      }

      // For each selected apartment, get the residents with phones
      for (const aptId of selectedApartments) {
        const apartment = apartmentsWithResidents.find(a => a.id === aptId);
        if (!apartment) continue;
        
        const residentIds = selectedResidents[aptId] || [];
        
        // Get all selected residents that have phone numbers
        for (const residentId of residentIds) {
          const resident = apartment.residents.find(r => r.id === residentId);
          if (!resident || !resident.phone) continue;
          
          // Format phone number correctly (remove any non-digit characters and country code if needed)
          const formattedPhone = formatPhoneNumber(resident.phone);
          
          // Get the first name of the resident
          const residentFirstName = resident.name.split(' ')[0] || '';

          // Get payment options text
          const paymentOptions = getPaymentOptionsText();
          
          // Create the notification data with the correct parameter structure
          const parameters = [
            {
              type: "text",
              parameter_name: "residente",
              text: sanitizeWhatsAppText(residentFirstName)
            },
            {
              type: "text",
              parameter_name: "apartamento",
              text: sanitizeWhatsAppText(apartment.apartmentNumber || 'N/A')
            },
            {
              type: "text",
              parameter_name: "edificio",
              text: sanitizeWhatsAppText(buildingName)
            },
            {
              type: "text",
              parameter_name: "monto",
              text: sanitizeWhatsAppText(apartment.totalDebt.toFixed(2))
            },
            {
              type: "text",
              parameter_name: "meses",
              text: sanitizeWhatsAppText((apartment.monthsOfDebt || '1').toString())
            },
            {
              type: "text",
              parameter_name: "opciones_pago",
              text: sanitizeWhatsAppText(paymentOptions)
            }
          ];

          // Filter out the meses parameter if not using the urgent template
          const finalParameters = templateId === 'reminder_urgent' 
            ? parameters 
            : parameters.filter(p => p.parameter_name !== 'meses');

          notifications.push({
            recipient: {
              phone: formattedPhone,
              name: residentFirstName,
              user_id: resident.id,
              apartment_id: aptId
            },
            payload: {
              template: templateId,
              parameters: finalParameters,
              building_id: currentBuildingId,
              debt_amount: apartment.totalDebt.toFixed(2),
              building_name: sanitizeWhatsAppText(buildingName),
              payment_options: sanitizeWhatsAppText(paymentOptions),
              apartment_number: sanitizeWhatsAppText(apartment.apartmentNumber || 'N/A')
            }
          });
        }
      }
      
      // Make the API request through our service
      const requestData = {
        notifications,
        metadata: {
          user_id: user?.id,
          sent_by: user?.email || user?.firstName || 'admin',
          sent_from: 'web_app',
          is_test: !debtCollectionSettings.whatsapp_enabled
        }
      };

      await notificationService.sendDebtReminders(requestData);
      trackReminderSent( user?.id, notifications.length, "whatsapp");

      
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        onClose();
      }, 2000);
    } catch (err) {
      console.error('Error sending reminders:', err);
      setError(err.message || 'Error al enviar los recordatorios. Intente nuevamente.');
    } finally {
      setLoading(false);
    }
  };
  
  // Handle error dismissal
  const handleCloseError = () => {
    setError(null);
  };
  
  // Render step content based on active step
  const getStepContent = () => {
    switch (activeStep) {
      case 0: // Select recipients
        return (
          <Box sx={{ mt: 2 }}>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              mb: 2
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <GroupIcon />
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  Departamentos con Deuda ({apartmentsWithResidents.length})
                </Typography>
              </Box>
              <Button 
                size="small"
                onClick={handleSelectAllApartments}
                sx={{ 
                  textTransform: 'none',
                  color: '#4B5563'
                }}
                startIcon={selectedApartments.length === apartmentsWithResidents.length ? 
                  <CheckCircleIcon color="primary" /> : null}
              >
                {selectedApartments.length === apartmentsWithResidents.length
                  ? 'Deseleccionar todos'
                  : 'Seleccionar todos'}
              </Button>
            </Box>
            
            <Paper 
              variant="outlined" 
              sx={{ 
                maxHeight: '450px', 
                overflowY: 'auto',
                borderRadius: '12px',
                mb: 2
              }}
            >
              <List sx={{ p: 0 }}>
                {apartmentsWithResidents.length === 0 ? (
                  <ListItem sx={{ py: 3 }}>
                    <ListItemText 
                      primary={
                        <Typography align="center" color="text.secondary">
                          No hay departamentos con deudas pendientes
                        </Typography>
                      } 
                    />
                  </ListItem>
                ) : (
                  apartmentsWithResidents.map(apt => (
                    <React.Fragment key={apt.id}>
                      <ListItem 
                        sx={{
                          borderBottom: '1px solid #E5E7EB',
                          py: 1.5,
                          backgroundColor: selectedApartments.includes(apt.id) ? '#F9FAFB' : 'transparent',
                          '&:hover': { backgroundColor: '#F9FAFB' }
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={selectedApartments.includes(apt.id)}
                              onChange={() => toggleApartmentSelection(apt.id)}
                              sx={{ mr: 1 }}
                            />
                          }
                          label={
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                              <Typography sx={{ fontWeight: 600 }}>
                                Depa {apt.apartmentNumber}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                {apt.residentName}
                              </Typography>
                            </Box>
                          }
                          sx={{ flex: 1, mr: 2 }}
                        />
                        
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', mr: 1 }}>
                          <Typography variant="body2" sx={{ fontWeight: 600, color: '#DC2626' }}>
                            S/ {apt.totalDebt.toLocaleString('es-PE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                          </Typography>
                          <Chip 
                            label={`${apt.monthsOfDebt} ${apt.monthsOfDebt === 1 ? 'mes' : 'meses'}`}
                            size="small"
                            sx={{ 
                              backgroundColor: apt.monthsOfDebt >= 6 ? '#FEE2E2' : apt.monthsOfDebt >= 3 ? '#FEF3C7' : '#E0F2FE',
                              color: apt.monthsOfDebt >= 6 ? '#991B1B' : apt.monthsOfDebt >= 3 ? '#92400E' : '#0369A1',
                              fontWeight: 500,
                              height: 20,
                              mt: 0.5
                            }}
                          />
                        </Box>
                        
                        <IconButton 
                          size="small"
                          onClick={() => toggleApartmentExpansion(apt.id)}
                          sx={{ color: selectedApartments.includes(apt.id) ? '#4B5563' : '#9CA3AF' }}
                        >
                          {expandedApartments[apt.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                      </ListItem>
                      
                      <Collapse in={expandedApartments[apt.id]}>
                        <Box sx={{ pl: 6, pr: 2, py: 1, backgroundColor: '#F9FAFB' }}>
                          <Typography variant="subtitle2" sx={{ fontWeight: 600, mb: 1 }}>
                            {apt.residents.length > 0 ? 'Seleccionar Residentes' : 'No hay residentes registrados'}
                          </Typography>
                          
                          {apt.residents.length > 0 ? (
                            <List dense disablePadding>
                              {apt.residents.map(resident => (
                                <ListItem 
                                  key={resident.id}
                                  sx={{ 
                                    py: 0.5,
                                    borderRadius: '8px',
                                    '&:hover': { backgroundColor: '#F3F4F6' },
                                    opacity: !resident.phone ? 0.7 : 1
                                  }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox 
                                        checked={selectedResidents[apt.id]?.includes(resident.id) || false}
                                        onChange={() => toggleResidentSelection(apt.id, resident.id)}
                                        sx={{ mr: 1 }}
                                        disabled={!selectedApartments.includes(apt.id) || !resident.phone}
                                      />
                                    }
                                    label={
                                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                          <Typography variant="body2" sx={{ fontWeight: 600 }}>
                                            {resident.name}
                                          </Typography>
                                          {resident.isPrimary && (
                                            <Chip 
                                              label="Principal" 
                                              size="small"
                                              sx={{ 
                                                height: 18, 
                                                fontSize: '10px',
                                                backgroundColor: '#E0F2FE',
                                                color: '#0C4A6E'
                                              }}
                                            />
                                          )}
                                          {!resident.phone && (
                                            <Chip 
                                              label="Sin teléfono" 
                                              size="small"
                                              sx={{ 
                                                height: 18, 
                                                fontSize: '10px',
                                                backgroundColor: '#FEF3C7',
                                                color: '#92400E'
                                              }}
                                            />
                                          )}
                                        </Box>
                                        <Typography variant="caption" color="text.secondary">
                                          {resident.phone ? formatPhoneNumber(resident.phone) : 'No registrado'}
                                        </Typography>
                                      </Box>
                                    }
                                    sx={{ flex: 1, mr: 2 }}
                                  />
                                </ListItem>
                              ))}
                            </List>
                          ) : (
                            <Typography variant="body2" color="text.secondary" sx={{ pl: 2 }}>
                              Este departamento no tiene residentes registrados.
                            </Typography>
                          )}
                        </Box>
                      </Collapse>
                    </React.Fragment>
                  ))
                )}
              </List>
            </Paper>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="body2" color="text.secondary">
                {getTotalRecipients()} destinatarios seleccionados
              </Typography>
              
              <Button 
                variant="contained" 
                color="primary"
                onClick={handleNext}
                disabled={getTotalRecipients() === 0}
                endIcon={<SendIcon />}
                sx={{ borderRadius: '24px' }}
              >
                Continuar
              </Button>
            </Box>
          </Box>
        );
        
      case 1: // Message templates
        return (
          <Box sx={{ mt: 2 }}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1 }}>
                Mensaje de Recordatorio
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Seleccione una plantilla de mensaje para el recordatorio.
              </Typography>
            </Box>
            
            <FormControl component="fieldset" sx={{ width: '100%', mb: 2 }}>
              <RadioGroup
                value={selectedTemplate}
                onChange={(e) => setSelectedTemplate(e.target.value)}
              >
                {REMINDER_TEMPLATES.map(template => (
                  <Paper
                    key={template.id}
                    variant="outlined"
                    sx={{ 
                      mb: 2,
                      borderRadius: '12px',
                      borderColor: selectedTemplate === template.id ? '#3B82F6' : '#E5E7EB',
                      backgroundColor: selectedTemplate === template.id ? '#F0F9FF' : 'white',
                      overflow: 'hidden'
                    }}
                  >
                    <Box sx={{ display: 'flex', p: 2 }}>
                      <Radio 
                        value={template.id} 
                        sx={{ 
                          mt: -0.5, 
                          mr: 1,
                          '&:hover': {
                            boxShadow: 'none',
                            backgroundColor: 'transparent'
                          },
                          '& .MuiSvgIcon-root': {
                            boxShadow: 'none',
                            filter: 'none',
                            '&:hover': {
                              boxShadow: 'none',
                              filter: 'none'
                            }
                          },
                          '&.Mui-focusVisible': {
                            boxShadow: 'none'
                          },
                          '& .MuiTouchRipple-root': {
                            display: 'none'
                          }
                        }}
                        disableRipple
                      />
                      <Box sx={{ flex: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                          <Typography sx={{ fontWeight: 600 }}>
                            {template.title}
                          </Typography>
                          <Chip 
                            label={template.tone === 'friendly' ? 'Amable' : template.tone === 'formal' ? 'Formal' : 'Urgente'}
                            size="small"
                            sx={{
                              backgroundColor: template.tone === 'friendly' ? '#DCFCE7' : 
                                template.tone === 'formal' ? '#E0F2FE' : '#FEE2E2',
                              color: template.tone === 'friendly' ? '#166534' : 
                                template.tone === 'formal' ? '#0369A1' : '#991B1B',
                              fontWeight: 500
                            }}
                          />
                        </Box>
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            color: '#6B7280',
                            whiteSpace: 'pre-line'
                          }}
                        >
                          {template.message}
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                ))}
                
                {/* Disabled Custom Message Option */}
                <Paper
                  variant="outlined"
                  sx={{ 
                    mb: 2,
                    borderRadius: '12px',
                    borderColor: '#E5E7EB',
                    backgroundColor: '#F3F4F6',
                    overflow: 'hidden',
                    opacity: 0.7,
                    cursor: 'not-allowed'
                  }}
                >
                  <Box sx={{ display: 'flex', p: 2 }}>
                    <Radio 
                      value="custom" 
                      sx={{ mt: -0.5, mr: 1 }}
                      disabled
                    />
                    <Box sx={{ width: '100%' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography sx={{ fontWeight: 600, color: '#6B7280' }}>
                          Mensaje Personalizado
                        </Typography>
                        <Chip 
                          label="Próximamente" 
                          size="small"
                          sx={{
                            backgroundColor: '#E0F2FE',
                            color: '#0C4A6E',
                            fontWeight: 500
                          }}
                        />
                      </Box>
                      <TextField
                        multiline
                        rows={4}
                        placeholder="Esta función estará disponible próximamente..."
                        fullWidth
                        disabled
                        sx={{
                          mt: 1,
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                          }
                        }}
                      />
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                        <IconButton 
                          size="small" 
                          disabled
                          sx={{ color: '#D1D5DB' }}
                        >
                          <InsertEmoticonIcon fontSize="small" />
                        </IconButton>
                        <IconButton 
                          size="small" 
                          disabled
                          sx={{ color: '#D1D5DB' }}
                        >
                          <AttachFileIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </RadioGroup>
            </FormControl>
            
            {/* Preview Section */}
            {selectedApartments.length > 0 && (
              <Box 
                sx={{
                  backgroundColor: '#F0FDF4',
                  borderRadius: '12px',
                  p: 2,
                  mb: 2
                }}
              >
                <Typography variant="subtitle2" sx={{ fontWeight: 600, mb: 1 }}>
                  Vista previa del mensaje
                </Typography>
                <Box
                  sx={{
                    backgroundColor: '#FFFFFF',
                    borderRadius: '8px',
                    p: 2,
                    borderLeft: '4px solid #25D366'
                  }}
                >
                  <Typography 
                    variant="body2"
                    sx={{ whiteSpace: 'pre-line' }}
                  >
                    {getMessagePreview(selectedTemplate, selectedApartments[0])}
                  </Typography>
                </Box>
              </Box>
            )}
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 3 }}>
              <Button 
                variant="outlined" 
                onClick={handleBack}
                startIcon={<ArrowBackIcon />}
                sx={{ borderRadius: '24px' }}
              >
                Atrás
              </Button>
              
              <Button 
                variant="contained" 
                color="primary"
                onClick={handleNext}
                endIcon={<SendIcon />}
                sx={{ borderRadius: '24px' }}
              >
                Continuar
              </Button>
            </Box>
          </Box>
        );
        
      case 2: // Confirmation
        return (
          <Box sx={{ mt: 2 }}>
            {!debtCollectionSettings.whatsapp_enabled && (
              <Box 
                sx={{
                  backgroundColor: '#FEF3C7',
                  borderRadius: '12px',
                  p: 2,
                  mb: 3,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}
              >
                <Typography variant="body2" color="#92400E">
                  Función en modo prueba. Los mensajes no serán enviados realmente.
                </Typography>
              </Box>
            )}

            <Box 
              sx={{
                backgroundColor: '#F0FDF4',
                borderRadius: '12px',
                p: 3,
                mb: 3,
                textAlign: 'center'
              }}
            >
              <Typography variant="h6" sx={{ color: '#166534', mb: 2 }}>
                Resumen del Envío
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                Se enviarán <strong>{getTotalRecipients()}</strong> mensajes a {selectedApartments.length} departamentos
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Deuda total: S/ {apartmentsWithResidents
                  .filter(apt => selectedApartments.includes(apt.id))
                  .reduce((sum, apt) => sum + apt.totalDebt, 0)
                  .toLocaleString('es-PE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </Typography>
            </Box>
            
            <Paper variant="outlined" sx={{ borderRadius: '12px', mb: 3 }}>
              <List sx={{ pt: 0, pb: 0 }}>
                <ListItem sx={{ 
                  borderBottom: '1px solid #E5E7EB',
                  backgroundColor: '#F9FAFB',
                  px: 3,
                  py: 1.5
                }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                    Destinatarios
                  </Typography>
                </ListItem>
                
                {selectedApartments.map(aptId => {
                  const apt = apartmentsWithResidents.find(a => a.id === aptId);
                  const residentIds = selectedResidents[aptId] || [];
                  
                  // Only show residents that have phone numbers
                  const residentsWithPhones = apt?.residents.filter(r => {
                    return r.phone && residentIds.includes(r.id);
                  }) || [];
                  
                  if (residentsWithPhones.length === 0) return null;
                  
                  return (
                    <ListItem key={aptId} sx={{ px: 3, py: 1.5, borderBottom: '1px solid #F3F4F6' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.5 }}>
                          <Typography sx={{ fontWeight: 600 }}>
                            Depa {apt?.apartmentNumber || 'N/A'}
                          </Typography>
                          <Typography variant="body2" sx={{ color: '#DC2626', fontWeight: 500 }}>
                            S/ {apt?.totalDebt.toLocaleString('es-PE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '0.00'}
                          </Typography>
                        </Box>
                        
                        <Box sx={{ ml: 2 }}>
                          {residentsWithPhones.map(resident => (
                            <Box 
                              key={resident.id}
                              sx={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                gap: 1,
                                py: 0.5,
                                pl: 1
                              }}
                            >
                              <PersonIcon sx={{ color: '#6B7280', fontSize: 16 }} />
                              <Typography variant="body2">
                                {resident.name}
                              </Typography>
                              <Typography variant="caption" color="text.secondary" sx={{ ml: 'auto' }}>
                                {formatPhoneNumber(resident.phone)}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </ListItem>
                  );
                })}
              </List>
            </Paper>
            
            <Paper variant="outlined" sx={{ borderRadius: '12px', mb: 3 }}>
              <List sx={{ pt: 0, pb: 0 }}>
                <ListItem sx={{ 
                  borderBottom: '1px solid #E5E7EB',
                  backgroundColor: '#F9FAFB',
                  px: 3,
                  py: 1.5
                }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                    Mensaje
                  </Typography>
                </ListItem>
                
                <ListItem sx={{ px: 3, py: 2 }}>
                  <Box sx={{ width: '100%' }}>
                    <Box sx={{ display: 'flex', mb: 1 }}>
                      <Chip 
                        label={
                          REMINDER_TEMPLATES.find(t => t.id === selectedTemplate)?.title || "Plantilla"
                        }
                        size="small"
                        sx={{
                          backgroundColor: REMINDER_TEMPLATES.find(t => t.id === selectedTemplate)?.tone === 'friendly' 
                            ? '#DCFCE7' 
                            : REMINDER_TEMPLATES.find(t => t.id === selectedTemplate)?.tone === 'formal' 
                              ? '#E0F2FE' 
                              : '#FEE2E2',
                          color: REMINDER_TEMPLATES.find(t => t.id === selectedTemplate)?.tone === 'friendly' 
                            ? '#166534' 
                            : REMINDER_TEMPLATES.find(t => t.id === selectedTemplate)?.tone === 'formal' 
                              ? '#0369A1' 
                              : '#991B1B',
                          fontWeight: 500
                        }}
                      />
                    </Box>
                    
                    <Box
                      sx={{
                        backgroundColor: '#F9FAFB',
                        borderRadius: '8px',
                        p: 2,
                        borderLeft: '4px solid #25D366'
                      }}
                    >
                      <Typography 
                        variant="body2"
                        sx={{ whiteSpace: 'pre-line' }} // Preserve line breaks
                      >
                        {REMINDER_TEMPLATES.find(t => t.id === selectedTemplate)?.message}
                      </Typography>
                    </Box>
                    
                    <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1 }}>
                      * Los valores {'{residente}'}, {'{monto}'}, {'{apartamento}'}, {'{edificio}'}, {'{meses}'} y {'{opciones_pago}'} serán reemplazados automáticamente para cada destinatario.
                    </Typography>
                  </Box>
                </ListItem>
              </List>
            </Paper>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Button 
                variant="outlined" 
                onClick={handleBack}
                startIcon={<ArrowBackIcon />}
                sx={{ borderRadius: '24px' }}
              >
                Atrás
              </Button>
              
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <Button
                  variant="contained"
                  onClick={handleSendReminders}
                  disabled={loading}
                  startIcon={loading ? <CircularProgress size={16} /> : <WhatsAppIcon />}
                  sx={{
                    backgroundColor: '#25D366',
                    color: 'white',
                    borderRadius: '24px',
                    '&:hover': {
                      backgroundColor: '#1DA851'
                    }
                  }}
                >
                  {loading ? 'Enviando...' : `Enviar Recordatorios (${getTotalRecipients()})`}
                </Button>
                {!debtCollectionSettings.whatsapp_enabled && (
                  <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5 }}>
                    Modo prueba - No se enviarán mensajes reales
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        );

      default:
        return null;
    }
  };
  
  return (
    <>
      <Dialog 
        open={open} 
        onClose={loading || success ? undefined : onClose}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: { borderRadius: '16px' }
        }}
      >
        <DialogTitle sx={{ borderBottom: '1px solid #E5E7EB', pb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <WhatsAppIcon sx={{ color: '#25D366' }} />
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                Recordatorio de Pagos por WhatsApp
              </Typography>
            </Box>
            <IconButton 
              onClick={onClose}
              disabled={loading || success}
              sx={{ color: '#6B7280' }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        
        <DialogContent sx={{ p: 3, minHeight: '60vh' }}>
          {success ? (
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center', 
              justifyContent: 'center',
              height: '100%',
              py: 8
            }}>
              <Box
                sx={{
                  backgroundColor: '#DCFCE7',
                  borderRadius: '50%',
                  p: 2,
                  mb: 3
                }}
              >
                <CheckCircleIcon sx={{ fontSize: 48, color: '#059669' }} />
              </Box>
              <Typography variant="h5" sx={{ color: '#059669', mb: 2, fontWeight: 600 }}>
                ¡Recordatorios enviados con éxito!
              </Typography>
              <Typography variant="body1" sx={{ textAlign: 'center', color: '#6B7280' }}>
                Se han enviado {getTotalRecipients()} recordatorios a {selectedApartments.length} departamentos.
              </Typography>
            </Box>
          ) : (
            <>
              <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              
              {getStepContent()}
            </>
          )}
        </DialogContent>
      </Dialog>
      
      <Snackbar
        open={!!error}
        autoHideDuration={4000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseError} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default WhatsAppReminderModal;