import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  TextField,
  IconButton,
  Chip,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Tooltip,
  CircularProgress
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ReceiptIcon from '@mui/icons-material/Receipt';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import useGlobalStore from '../../../state/store';
import { usePendingCharges } from '../hooks/usePendingCharges';
import { ROUTES } from '../../../config/routes';
import EmptyStateAlert from '../components/common/EmptyStateAlert';
import WhatsAppReminderModal from '../components/debtManagement/WhatsAppReminderModal';
import { maintenanceFeeConfigService } from '../services/maintenanceFeeConfigService';

const MaintenanceFeeDebts = () => {
  const navigate = useNavigate();
  const buildingId = useGlobalStore((state) => state.buildingId);
  const { apartmentDebts, loading, error, refetch } = usePendingCharges();
  
  const [searchTerm, setSearchTerm] = useState('');
  const [debtFilter, setDebtFilter] = useState('all');
  const [timeFilter, setTimeFilter] = useState('all');
  const [sortOrder, setSortOrder] = useState('desc');
  const [showReminders, setShowReminders] = useState(false);
  const [buildingInfo, setBuildingInfo] = useState(null);
  const [loadingBuildingInfo, setLoadingBuildingInfo] = useState(false);
  
  // Fetch building info including payment options and debt collection settings
  useEffect(() => {
    const fetchBuildingInfo = async () => {
      if (!buildingId) return;
      
      try {
        setLoadingBuildingInfo(true);
        const data = await maintenanceFeeConfigService.fetchBuildingFeeSettings(buildingId);
        setBuildingInfo(data);
      } catch (err) {
        console.error("Error fetching building fee settings:", err);
      } finally {
        setLoadingBuildingInfo(false);
      }
    };
    
    fetchBuildingInfo();
  }, [buildingId]);

  // Filter and sort apartments
  const filteredApartments = !loading ? apartmentDebts
    .filter(apt => {
      // Search filter
      const matchesSearch = 
        apt.apartmentNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
        apt.residentName.toLowerCase().includes(searchTerm.toLowerCase());
      
      // Debt filter
      let matchesDebtFilter = true;
      if (debtFilter === 'high') matchesDebtFilter = apt.totalDebt > 1000;
      else if (debtFilter === 'medium') matchesDebtFilter = apt.totalDebt > 500 && apt.totalDebt <= 1000;
      else if (debtFilter === 'low') matchesDebtFilter = apt.totalDebt > 0 && apt.totalDebt <= 500;
      else if (debtFilter === 'none') matchesDebtFilter = apt.totalDebt === 0;
      
      // Time filter
      let matchesTimeFilter = true;
      if (timeFilter === '3months') matchesTimeFilter = apt.monthsOfDebt >= 3;
      else if (timeFilter === '6months') matchesTimeFilter = apt.monthsOfDebt >= 6;
      else if (timeFilter === '1year') matchesTimeFilter = apt.monthsOfDebt >= 12;
      
      return matchesSearch && matchesDebtFilter && matchesTimeFilter;
    })
    .sort((a, b) => {
      return sortOrder === 'desc' 
        ? b.totalDebt - a.totalDebt 
        : a.totalDebt - b.totalDebt;
    }) : [];
  
  // Calculate stats
  const stats = !loading ? {
    totalDebt: apartmentDebts.reduce((sum, apt) => sum + apt.totalDebt, 0),
    totalDebtors: apartmentDebts.filter(apt => apt.totalDebt > 0).length,
    highDebtors: apartmentDebts.filter(apt => apt.totalDebt > 1000).length,
    avgDebtPerApartment: apartmentDebts.length > 0 
      ? apartmentDebts.reduce((sum, apt) => sum + apt.totalDebt, 0) / apartmentDebts.length 
      : 0,
    longTermDebtors: apartmentDebts.filter(apt => apt.monthsOfDebt >= 3).length
  } : {
    totalDebt: 0,
    totalDebtors: 0,
    highDebtors: 0,
    avgDebtPerApartment: 0,
    longTermDebtors: 0
  };
  
  const handleExportToExcel = () => {
    // Would implement Excel export functionality here
    console.log("Export to Excel");
  };
  
  const handleBulkReminder = () => {
    setShowReminders(true);
  };
  
  const handleSendReminder = (apartmentId) => {
    // Would implement sending reminder functionality here
    console.log(`Send reminder to apartment ${apartmentId}`);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
        <CircularProgress />
      </Box>
    );
  }
  
  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh', flexDirection: 'column', gap: 2 }}>
        <Typography color="error" variant="h6">
          {error}
        </Typography>
        <Button variant="contained" onClick={refetch}>
          Reintentar
        </Button>
      </Box>
    );
  }
  
  return (
    <Box sx={{ padding: 4 }}>
      {/* Header */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <ReceiptIcon sx={{ fontSize: 28, color: '#DC2626' }} />
          <Typography sx={{ fontSize: '20px', fontWeight: 600, color: '#DC2626' }}>
            Gestión de Deudas de Mantenimiento
          </Typography>
        </Box>
        <Button 
          variant="text" 
          onClick={() => navigate('/maintenance-fee')}
          sx={{ color: '#6B7280' }}
        >
          <CloseIcon />
        </Button>
      </Box>
      
      {/* Stats cards */}
      <Box sx={{ mb: 4 }}>
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: '16px',
            padding: '20px 24px',
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.05)',
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(5, 1fr)',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#6B7280', mb: 1 }}>
                Deuda Total
              </Typography>
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: '#DC2626' }}>
                S/ {stats.totalDebt.toLocaleString('es-PE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </Typography>
            </Box>
            
            <Box>
              <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#6B7280', mb: 1 }}>
                Departamentos con Deuda
              </Typography>
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: '#111827' }}>
                {stats.totalDebtors} / {apartmentDebts.length}
              </Typography>
            </Box>
            
            <Box>
              <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#6B7280', mb: 1 }}>
                Deudores de Alto Riesgo
              </Typography>
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: '#DC2626' }}>
                {stats.highDebtors}
              </Typography>
            </Box>
            
            <Box>
              <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#6B7280', mb: 1 }}>
                Deuda Promedio
              </Typography>
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: '#111827' }}>
                S/ {stats.avgDebtPerApartment.toLocaleString('es-PE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </Typography>
            </Box>
            
            <Box>
              <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#6B7280', mb: 1 }}>
                Deudas &gt; 3 meses
              </Typography>
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: '#F59E0B' }}>
                {stats.longTermDebtors}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      
      {/* Search and filter toolbar */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 3,
          gap: 2,
        }}
      >
        {/* Search + Filters */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <TextField
            placeholder="Buscar por depto o residente..."
            onChange={(e) => setSearchTerm(e.target.value)}
            size="small"
            InputProps={{
              startAdornment: (
                <SearchIcon sx={{ color: '#9CA3AF', marginRight: 1 }} />
              ),
            }}
            sx={{
              width: '250px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '24px',
                fontSize: '14px',
                backgroundColor: '#F9FAFB',
              },
            }}
          />
          
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel sx={{ fontSize: '14px', color: '#6B7280' }}>
              Monto
            </InputLabel>
            <Select
              value={debtFilter}
              onChange={(e) => setDebtFilter(e.target.value)}
              sx={{
                borderRadius: '24px',
                fontSize: '14px',
                backgroundColor: '#FFFFFF',
              }}
            >
              <MenuItem value="all">Todos</MenuItem>
              <MenuItem value="high">Alta (&gt;S/1,000)</MenuItem>
              <MenuItem value="medium">Media (S/500-1,000)</MenuItem>
              <MenuItem value="low">Baja (&lt;S/500)</MenuItem>
              <MenuItem value="none">Sin deuda</MenuItem>
            </Select>
          </FormControl>
          
          <FormControl size="small" sx={{ minWidth: 140 }}>
            <InputLabel sx={{ fontSize: '14px', color: '#6B7280' }}>
              Antigüedad
            </InputLabel>
            <Select
              value={timeFilter}
              onChange={(e) => setTimeFilter(e.target.value)}
              sx={{
                borderRadius: '24px',
                fontSize: '14px',
                backgroundColor: '#FFFFFF',
              }}
            >
              <MenuItem value="all">Todas</MenuItem>
              <MenuItem value="3months">+3 meses</MenuItem>
              <MenuItem value="6months">+6 meses</MenuItem>
              <MenuItem value="1year">+1 año</MenuItem>
            </Select>
          </FormControl>
          
          <Button
            variant="text"
            onClick={() => setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc')}
            sx={{
              textTransform: 'none',
              fontSize: '14px',
              color: '#6B7280',
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            {sortOrder === 'desc' ? (
              <>
                <ArrowDownwardIcon fontSize="small" /> Mayor deuda primero
              </>
            ) : (
              <>
                <ArrowUpwardIcon fontSize="small" /> Menor deuda primero
              </>
            )}
          </Button>
        </Box>
        
        {/* Actions */}
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="outlined"
            onClick={handleExportToExcel}
            sx={{
              color: '#4F46E5',
              borderColor: '#E0E7FF',
              backgroundColor: '#F3F4F6',
              borderRadius: '24px',
              textTransform: 'none',
              fontSize: '14px',
              fontWeight: 500,
              '&:hover': {
                backgroundColor: '#E0E7FF',
                borderColor: '#4F46E5',
              },
            }}
            startIcon={<FileDownloadIcon />}
          >
            Exportar
          </Button>
          
          <Button
            variant="contained"
            onClick={handleBulkReminder}
            sx={{
              backgroundColor: '#25D366', // WhatsApp green
              color: '#FFFFFF',
              borderRadius: '24px',
              textTransform: 'none',
              fontSize: '14px',
              fontWeight: 500,
              padding: '8px 16px',
              '&:hover': { backgroundColor: '#1EA855' },
            }}
            startIcon={<WhatsAppIcon />}
          >
            Recordatorio Masivo
          </Button>
        </Box>
      </Box>
      
      {/* Table of apartments with debt */}
      {filteredApartments.length === 0 ? (
        <EmptyStateAlert
          message="No se encontraron departamentos con deudas que coincidan con los criterios de búsqueda."
          buttonLabel="Limpiar Filtros"
          onButtonClick={() => {
            setSearchTerm('');
            setDebtFilter('all');
            setTimeFilter('all');
          }}
        />
      ) : (
        <TableContainer component={Paper} sx={{ borderRadius: '16px', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.05)', mb: 4 }}>
          <Table>
            <TableHead sx={{ backgroundColor: '#F9FAFB' }}>
              <TableRow>
                <TableCell sx={{ fontWeight: 600, color: '#6B7280' }}>Departamento</TableCell>
                <TableCell sx={{ fontWeight: 600, color: '#6B7280' }}>Residente</TableCell>
                <TableCell align="right" sx={{ fontWeight: 600, color: '#6B7280' }}>Deuda Total</TableCell>
                <TableCell align="right" sx={{ fontWeight: 600, color: '#6B7280' }}>Deuda Mes Actual</TableCell>
                <TableCell align="right" sx={{ fontWeight: 600, color: '#6B7280' }}>Deuda Histórica</TableCell>
                <TableCell align="center" sx={{ fontWeight: 600, color: '#6B7280' }}>Meses</TableCell>
                <TableCell align="center" sx={{ fontWeight: 600, color: '#6B7280' }}>Último Pago</TableCell>
                <TableCell align="center" sx={{ fontWeight: 600, color: '#6B7280' }}>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredApartments.map((apt) => (
                <TableRow 
                  key={apt.id}
                  sx={{ 
                    '&:hover': { backgroundColor: '#F9FAFB' },
                    backgroundColor: apt.totalDebt === 0 ? '#F0FDF4' : 'white'
                  }}
                >
                  <TableCell>
                    <Typography sx={{ fontWeight: 600 }}>
                      {apt.apartmentNumber}
                    </Typography>
                  </TableCell>
                  <TableCell>{apt.residentName}</TableCell>
                  <TableCell align="right">
                    <Typography sx={{ 
                      fontWeight: 600, 
                      color: apt.totalDebt > 1000 ? '#DC2626' : apt.totalDebt > 500 ? '#F59E0B' : apt.totalDebt > 0 ? '#6B7280' : '#10B981'
                    }}>
                      S/ {apt.totalDebt.toLocaleString('es-PE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    S/ {apt.currentDebt.toLocaleString('es-PE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </TableCell>
                  <TableCell align="right">
                    S/ {apt.historicalDebt.toLocaleString('es-PE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </TableCell>
                  <TableCell align="center">
                    {apt.monthsOfDebt > 0 ? (
                      <Chip 
                        label={apt.monthsOfDebt} 
                        size="small"
                        sx={{ 
                          backgroundColor: apt.monthsOfDebt >= 6 ? '#FEE2E2' : apt.monthsOfDebt >= 3 ? '#FEF3C7' : '#E0F2FE',
                          color: apt.monthsOfDebt >= 6 ? '#991B1B' : apt.monthsOfDebt >= 3 ? '#92400E' : '#0369A1',
                          fontWeight: 600,
                        }}
                      />
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {apt.lastPaymentDate ? (
                      new Date(apt.lastPaymentDate).toLocaleDateString('es-PE', { 
                        day: '2-digit', 
                        month: '2-digit', 
                        year: 'numeric' 
                      })
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                      <Tooltip title="Ver detalle">
                        <IconButton 
                          size="small"
                          onClick={() => navigate(`${ROUTES.MAINTENANCE_FEE_DETAIL.replace(':id', apt.id)}`)}
                          sx={{ color: '#6B7280' }}
                        >
                          <InfoOutlinedIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      
                      {apt.totalDebt > 0 && (
                        <Tooltip title="Enviar recordatorio">
                          <IconButton 
                            size="small"
                            onClick={() => handleSendReminder(apt.id)}
                            sx={{ color: '#25D366' }}
                          >
                            <SendIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      
      {/* WhatsApp Reminder Modal */}
      {showReminders && (
        <WhatsAppReminderModal 
          open={showReminders}
          onClose={() => setShowReminders(false)}
          apartments={apartmentDebts.filter(apt => apt.totalDebt > 0)}
          buildingInfo={buildingInfo || undefined}
          debtCollectionSettings={buildingInfo?.debt_collection_settings || { enabled: false, whatsapp_enabled: false }}
        />
      )}
    </Box>
  );
};

export default MaintenanceFeeDebts;