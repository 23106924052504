import { useCallback, useEffect, useMemo } from "react";
import {
  Container,
  Box,
  Alert,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import { ArrowBack, PlayArrow, CheckCircle } from "@mui/icons-material";
import { Link, Navigate, useParams } from "react-router-dom";
import { useIncident } from "../hooks/useIncident";
import { ROUTES } from "../../../config/routes";
import IncidentDetailView from "../components/detail/IncidentDetailView";
import useUser from "../../account/hooks/useUser";
import IncidentDetailViewSkeleton from "../components/detail/IncidentDetailViewSkeleton";
import { useIncidentAnalytics } from "../hooks/useIncidentAnalytics";

const IncidentDetail = () => {
  const { id: incidentId } = useParams();
  
  const { user } = useUser();
  const {
    incident,
    loading,
    error,
    updateIncidentStatus,
    addComment,
    assignIncidentToWorker,
  } = useIncident(incidentId);
  const { trackIncidentViewed } = useIncidentAnalytics();


  useEffect(() => {
    if (incident?.id) {
      trackIncidentViewed(incident.id);
    }
  }, [incident?.id]);

  const handleUpdateStatus = useCallback(
    async (newStatus: string) => {
      try {
        await updateIncidentStatus (newStatus);
      } catch (err) {
        console.error("Error updating status:", err);
      }
    },
    [updateIncidentStatus]
  );

  const handleComment = useCallback(
    
    async (commentText: string, mediaFiles: []) => {
      
    console.log("Adding comment...");
      try {
        if (!incident?.building_id) {
          throw new Error("Missing building ID");
        }
        await addComment(commentText, mediaFiles);
      } catch (err) {
        console.error("Error adding comment:", err);
      }
    },
    [incident, addComment]
  );

  const handleAssignToWorker = useCallback(async () => {
    console.log("Assigning incident to worker...");
    try {
      if (!incident?.building_id) {
        throw new Error("Missing building ID");
      }
      if (!user?.id) {
        throw new Error("User not authenticated");
      }
      await assignIncidentToWorker(user.id);
    } catch (err) {
      console.error("Error assigning incident to worker:", err);
    }
  }, [incident, user, assignIncidentToWorker]);

  const handleMarkAsResolved = useCallback(async () => {
    try {
      await updateIncidentStatus( "resolved");
    } catch (err) {
      console.error("Error marking incident as resolved:", err);
    }
  }, [updateIncidentStatus]);
  if (loading) {
    return <IncidentDetailViewSkeleton />;
  }

 
  if (error) {
    return (
      <Container maxWidth="lg">
        <Box py={4}>
          <Alert severity="error">{error}</Alert>
        </Box>
      </Container>
    );
  }
  
  if (!incident) {
    return <Navigate to={ROUTES.INCIDENTS} />;
  }


  return (
    <Container maxWidth="lg">
      <Box py={4}>
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "flex-start", sm: "center" }}
          justifyContent="space-between"
          mb={3}
        >
          <Box display="flex" alignItems="center">
            <Link to={ROUTES.INCIDENTS}>
              <IconButton sx={{ mr: 2 }}>
                <ArrowBack />
              </IconButton>
            </Link>
            <Typography variant="h5">{incident.title}</Typography>
          </Box>
          {incident.status === "new" && !incident.assigned_to && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<PlayArrow />}
              onClick={handleAssignToWorker}
              sx={{ mt: { xs: 2, sm: 0 } }}
            >
              Resolver Incidente
            </Button>
          )}
          {incident.status === "in_progress" && (
            <Button
              variant="contained"
              sx={{
                backgroundColor: "status.resolved.main",
                color: "white",
                mt: { xs: 2, sm: 0 },
              }}
              startIcon={<CheckCircle />}
              onClick={handleMarkAsResolved}
            >
              Marcar como Resuelto
            </Button>
          )}
        </Box>

        <IncidentDetailView
          incident={incident}
          onUpdateStatus={handleUpdateStatus}
          onAddComment={handleComment}
          // onRateResolution={(rating, comment) => { }}        
          />
      </Box>
    </Container>
  );
};

export default IncidentDetail;