// src/features/maintenanceFee/services/notificationService.js

/**
 * Service to handle sending notifications via the API
 */
export const notificationService = {
    /**
     * Send debt reminder notifications via WhatsApp
     * 
     * @param {Object} data - The notification data
     * @param {Array} data.notifications - Array of notification recipients and their data
     * @param {Object} data.metadata - Metadata about who sent the notification
     * @returns {Promise<Object>} - API response
     */
    async sendDebtReminders(data) {
      try {
        // Check if we're in production (Vercel)
        const isProduction = !import.meta.env.DEV && window.location.hostname.includes('vercel.app');
        
        // Use either the direct API in development or a proxy path in production
        let endpointUrl;
        
        if (isProduction) {
          // In production (Vercel), use the API route that's configured in vercel.json
          endpointUrl = '/api/notifications/debt-collection'; 
        } else {
          // In local development, use the direct path which is handled by your dev server proxy
          const baseApiUrl = import.meta.env.VITE_NOTIFICATIONS_API_URL || '';
          const endpointPath = '/api/v1/events/debt-collection/admin';
          endpointUrl = import.meta.env.DEV ? endpointPath : `${baseApiUrl}${endpointPath}`;
        }
        
        console.log('Sending request to:', endpointUrl);
        console.log('Request payload:', JSON.stringify(data, null, 2));
        
        // Simple request configuration
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify(data)
        };
        
        // Send the request
        const response = await fetch(endpointUrl, options);
        console.log('Response status:', response.status);
        
        // Handle error responses
        if (!response.ok) {
          let errorMsg = '';
          try {
            const errorData = await response.json();
            console.error('Error response data:', errorData);
            
            // Extract error message from different possible formats
            if (typeof errorData === 'object') {
              // Handle different error object structures
              if (errorData.error) {
                if (typeof errorData.error === 'string') {
                  errorMsg = errorData.error;
                } else if (typeof errorData.error === 'object') {
                  errorMsg = errorData.error.message || JSON.stringify(errorData.error);
                }
              } else if (errorData.message) {
                errorMsg = errorData.message;
              } else {
                errorMsg = JSON.stringify(errorData);
              }
            } else {
              errorMsg = `API error: ${response.status}`;
            }
          } catch (e) {
            errorMsg = `API error: ${response.status}`;
          }
          throw new Error(errorMsg);
        }
        
        // Success response might be empty (204 No Content)
        if (response.status === 204) {
          console.log('Received 204 No Content response');
          return { success: true };
        }
        
        // Parse successful response
        const responseData = await response.json();
        console.log('Response data:', responseData);
        return responseData;
        
      } catch (error) {
        console.error('Error sending notifications:', error);
        
        // Improved user-friendly error messages
        if (error.name === 'TypeError' || 
            error.message.includes('Failed to fetch') ||
            error.message.includes('Network request failed') ||
            error.message.includes('Load failed')) {
          throw new Error('Error de conexión con el servidor de notificaciones. Verifica tu conexión a internet o contacta soporte.');
        } else if (error.message.includes('405')) {
          throw new Error('El servicio de notificaciones no está configurado correctamente. Por favor contacta a soporte técnico.');
        } else if (error.message.includes('404')) {
          throw new Error('El servicio de notificaciones no se encuentra disponible. Por favor contacta a soporte técnico.');
        } else if (error.message.includes('CORS')) {
          throw new Error('Error de configuración del servidor de notificaciones. Por favor contacta a soporte técnico.');
        } else if (error.message.includes('[object Object]')) {
          throw new Error('Error en el servicio de notificaciones. Por favor contacta a soporte técnico.');
        }
        
        throw error;
      }
    }
  };