// src/features/invitation/pages/VisitDetail.js

import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography, IconButton, Button, Skeleton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useParams, useLocation, Link } from "react-router-dom";
import { invitationService } from "../services/invitationService";
import VisitCard from "../components/VisitCard";
import { useNavigation } from "../../../navigation/hooks/useNavigation";
import { ROUTES } from "../../../config/routes";
import { isPast, parseISO, isToday, subMinutes, addHours, startOfDay, endOfDay, addDays } from "date-fns";
import useGlobalStore from "../../../state/store";
import { useVisits } from "../hooks/useVisits";
import isValidNonEmptyObject from "../../../shared/utils/isValidNonEmptyObject";
import { useInvitationAnalytics } from "../hooks/useInvitationAnalytics";

const VisitDetail = () => {
  const { getLocalCachedVisit } = useVisits();
  const { trackVisitDetailsViewed, trackVisitCheckedIn } = useInvitationAnalytics();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [checkingIn, setCheckingIn] = useState(false);
  const { visitId } = useParams();

  const cachedVisit = getLocalCachedVisit(String(visitId)) ?? null;
  const [visit, setVisit] = useState(cachedVisit);

  const user = useGlobalStore((state) => state.user);
  const buildingId = useGlobalStore((state) => state.buildingId);
  const userLoading = useGlobalStore((state) => state.loading);

  const { navigateWithParams } = useNavigation();
  const location = useLocation();

  const fetchVisitDetails = useCallback(async () => {
    // Don't attempt to fetch if we're still loading user or missing data
    if (userLoading) return;

    if (!visitId || !user?.id) {
      setError("No visit ID provided or user not authenticated");
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      const data = await invitationService.fetchVisitDetails(visitId, user.id);
      if (!data) {
        setError("Visit not found");
      } else {
        setVisit(data);
        trackVisitDetailsViewed(visitId, user.id);
      }
    } catch (error) {
      console.error("Error fetching visit details:", error);
      setError("Failed to fetch visit details. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, [visitId, user?.id, userLoading, trackVisitDetailsViewed]);

  useEffect(() => {
    if (!isValidNonEmptyObject(cachedVisit)) {
      fetchVisitDetails();
    } else if (user?.id && visitId) {
      trackVisitDetailsViewed(visitId, user.id);
    }
  }, [fetchVisitDetails, trackVisitDetailsViewed]);
  

  const handleCheckIn = async () => {
    if (!visitId) return;

    setCheckingIn(true);
    try {
      await invitationService.checkInVisitor(visitId);
      trackVisitCheckedIn(visitId, user!.id, buildingId);
      await fetchVisitDetails();
    } catch (error) {
      console.error("Error checking in visitor:", error);
      setError("Failed to check in visitor. Please try again.");
    } finally {
      setCheckingIn(false);
    }
  };

  const getBackPath = () => {
    const searchParams = new URLSearchParams(location.search);
    const from = searchParams.get("from");
    const activeTab = from === "history" ? "history" : "today";

    console.log({ activeTab, from });

    return `${ROUTES.VISITS}?activeTab=${activeTab}`;
  };

  const handleGoBack = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    const from = searchParams.get("from");
    const activeTab = from === "history" ? "history" : "today";
    navigateWithParams(ROUTES.VISITS, { activeTab });
  }, [location.search, navigateWithParams]);

  const isVisitPassed =
    visit &&
    (visit.invitation_date || visit.check_in_time) &&
    isPast(parseISO(visit.invitation_date || visit.check_in_time));

  const isVisitToday = 
    visit &&
    visit.invitation_date &&
    isToday(parseISO(visit.invitation_date));

  const isCheckInAllowed = 
    visit &&
    visit.status === "pending" &&
    visit.invitation_date &&
    isToday(parseISO(visit.invitation_date));

  // Add combined loading state
  if (userLoading || loading) {
    return (
      <Box sx={{ maxWidth: 600, margin: "auto", p: 2 }}>
        <Skeleton variant="rectangular" width="100%" height={200} />
        <Skeleton variant="text" width="60%" height={40} sx={{ mt: 2 }} />
        <Skeleton variant="text" width="40%" height={20} sx={{ mt: 1 }} />
        <Skeleton
          variant="rectangular"
          width="100%"
          height={50}
          sx={{ mt: 2 }}
        />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ maxWidth: 600, margin: "auto", p: 2 }}>
        <Typography color="error">{error}</Typography>
        <Button onClick={handleGoBack} sx={{ mt: 2 }}>
          Back to Dashboard
        </Button>
      </Box>
    );
  }

  if (!visit) {
    return (
      <Box sx={{ maxWidth: 600, margin: "auto", p: 2 }}>
        <Typography>Visit not found.</Typography>
        <Button onClick={handleGoBack} sx={{ mt: 2 }}>
          Back to Dashboard
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 600, margin: "auto", p: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Link to={getBackPath()}>
          <IconButton sx={{ mr: 1 }}>
            <ArrowBack />
          </IconButton>
        </Link>

        <Typography variant="h6" component="h1" gutterBottom>
          Detalles de visita
        </Typography>
      </Box>
      <VisitCard visit={visit} />
      {isCheckInAllowed && (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleCheckIn}
          disabled={checkingIn}
          sx={{ mt: 2 }}
        >
          {checkingIn ? "Registrando..." : "Registrar Visitante"}
        </Button>
      )}
    </Box>
  );
};

export default React.memo(VisitDetail);
