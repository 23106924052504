import { useCallback, useState, useEffect } from "react";
import { incidentService } from "../services/incidentService";
import { useAnalytics } from "../../../analytics/hooks/useAnalytics";
import useGlobalStore from "../../../state/store";
import { Incident } from "../types";
import { isValidNonEmptyArray } from "../../../shared/utils/isValidNonEmptyArray";
import { useIncidentAnalytics } from "./useIncidentAnalytics";

export const useIncident = (incidentId?: string) => {

  const { track } = useAnalytics();
  const { user, incidents, setIncidents } = useGlobalStore();
  const [error, setError] = useState<string | null>(null);
  const { trackIncidentCreated, trackStatusChanged, trackCommentAdded, trackIncidentAssigned} = useIncidentAnalytics();

  const incident = incidents.data?.find(inc => inc.id === incidentId) || null;

  const createIncident = useCallback(
    async (incidentData: Partial<Incident>) => {
      if (!user?.id) {
        setError("User not authenticated");
        return;
      }


      setError(null);
      try {
        const result = await incidentService.createIncident(incidentData, user.id);

        if (result?.data?.incident_id) {
          
          trackIncidentCreated(result.data.incident_id, {
            category: incidentData.category,
            priority: incidentData.priority
          });
          await fetchIncident();
        }

        return result;
      } catch (err: any) {
        setError(err.message || "Error al crear la incidencia");
        throw err;
      }
    },
    [user?.id, track, setIncidents, incidents.data]
  );


  const fetchIncident = useCallback(async (targetId?: string): Promise<Incident | void> => {
    const idToFetch = targetId || incidentId;

    if (!idToFetch || !user?.id) {
      setError("Missing required data");
      return;
    }

    try {
      const data = await incidentService.fetchIncidentById(idToFetch, user.id);
      const transformedIncident: Incident = {
        ...data,
        created_at: new Date(data.created_at),
        updated_at: new Date(data.updated_at),
        due_date: data.due_date ? new Date(data.due_date) : null,
        resolution_date: data.resolution_date ? new Date(data.resolution_date) : null,
        media_urls: Array.isArray(data.media_urls) ? data.media_urls : [],
        metadata: typeof data.metadata === "object" ? data.metadata : {},
        comments: Array.isArray(data.comments) ? data.comments : [],
        status_history: Array.isArray(data.status_history) ? data.status_history : [],
      };

      setIncidents({
        data: [
          ...incidents.data.filter((inc: Incident) => inc.id !== idToFetch),
          transformedIncident
        ],
      });

      return transformedIncident;
    } catch (err: any) {
      throw err;
    }
  }, [incidentId, user?.id, setIncidents, incidents.data]);

  const updateIncidentStatus = useCallback(
    async (newStatus: string, notes: any = null, targetIncidentId?: string) => {
      const idToUpdate = targetIncidentId || incidentId;

      if (!idToUpdate || !user?.id) {
        setError("Missing required data");
        return;
      }

      setError(null);
      try {
        const result = await incidentService.updateIncidentStatus(
          idToUpdate,
          newStatus,
          user.id,
          notes
        );

        if (result?.data?.incident_id) {
          
          trackStatusChanged(result.data.incident_id, newStatus);

          await fetchIncident(idToUpdate);
        }

        return result;
      } catch (err: any) {
        setError(err.message || "Error al actualizar el estado");
        throw err;
      }
    },
    [incidentId, user?.id, track, fetchIncident, incidents.data, setIncidents]
  );

  const addComment = useCallback(
    async (comment: string, mediaFiles: [] = [], isInternal: boolean = false) => {
      if (!incidentId || !user?.id) {
        setError("Missing required data");
        return;
      }

      const buildingId = incident?.building_id;
      if (!buildingId) {
        setError("Missing building ID");
        return;
      }
      setError(null);
      try {
        const cleanComment = String(comment).trim();
        const cleanMediaFiles = Array.isArray(mediaFiles) ? mediaFiles : [];
        const result = await incidentService.addComment(
          incidentId,
          user.id,
          cleanComment,
          cleanMediaFiles,
          isInternal,
          buildingId
        );


        if (result.success) {
          await fetchIncident();
          trackCommentAdded(incidentId, mediaFiles.length > 0);
        }

        return result;
      } catch (err: any) {
        setError(err.message || "Error al agregar el comentario");
        throw err;
      }
    },
    [incidentId, user?.id, incident, fetchIncident, track]
  );

  const assignIncidentToWorker = useCallback(
    async (workerId: string) => {
      if (!incidentId || !user?.id || !incident?.building_id) return;
      try {
        const buildingId = incident?.building_id;
        if (!buildingId) {
          throw new Error("Missing building ID");
        }

        const result = await incidentService.assignIncidentToWorker(
          incidentId,
          workerId,
          user.id,
          buildingId
        );

        if (result.success) {
          trackIncidentAssigned(incidentId, workerId);
          await updateIncidentStatus("in_progress");
          await fetchIncident();
        }

        return result;
      } catch (err: any) {
        setError(err.message || "Error al asignar la incidencia");
        throw err;
      }
    },
    [incidentId, user?.id, incident?.building_id, updateIncidentStatus, fetchIncident, track]
  );

  useEffect(() => {
    if (incident && (!incident.comments || !incident.status_history)) {
      fetchIncident();
    }
  }, [incident, fetchIncident]);

  return {
    incident,
    createIncident,
    updateIncidentStatus,
    addComment,
    assignIncidentToWorker,
    loading: incidents.loading,
    error: incidents.error,
  };
};