import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles"; // <-- useTheme is imported here
import MuiDrawer from "@mui/material/Drawer";
// import theme from "../../styles/theme"; // Removed static theme import
import {
  CalendarToday,
  Chat,
  LocalLaundryService,
  LocalShipping,
  MonetizationOn,
  OpacityOutlined,
  People,
  Room,
  Warning,
} from "@mui/icons-material";
import { ROUTES } from "../../config/routes";
import { Link, useLocation } from "react-router-dom";
import PremiumFeaturePopup from "../../features/dashboard/components/PremiumFeaturePopup";
import { useState } from "react";
import { useMediaQuery } from "@mui/material";

export const DRAWER_WIDTH = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

// Minimal change: forward the "variant" prop and, when variant is "temporary", return a simpler style.
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, variant }) => {
  if (variant === "temporary") {
    return {
      "& .MuiDrawer-paper": {
        width: DRAWER_WIDTH,
        backgroundColor: "transparent",
        border: "none",
      },
    };
  }
  return {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...theme.mixins.toolbar,
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  };
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // Necessary for content to be below the AppBar
  ...theme.mixins.toolbar,
}));

type MainSidebarProps = {
  open: boolean;
  handleToggleSidebar?: () => void;
  handleCloseSidebar?: () => void;
};

const menuItems = [
  { text: "Visitas", icon: <People />, route: ROUTES.VISITS, premium: false },
  {
    text: "Reservas",
    icon: <CalendarToday />,
    route: ROUTES.RESERVATIONS,
    premium: true,
  },
  {
    text: "Lavandería",
    icon: <LocalLaundryService />,
    route: ROUTES.LAUNDRY,
    premium: true,
  },
  {
    text: "Áreas comunes",
    icon: <Room />,
    route: ROUTES.COMMON_AREAS,
    premium: true,
  },
  {
    text: "Delivery",
    icon: <LocalShipping />,
    route: ROUTES.DELIVERY,
    premium: true,
  },
  {
    text: "Residentes",
    icon: <People />,
    route: ROUTES.RESIDENTS,
    premium: true,
  },
  { text: "Chat", icon: <Chat />, route: ROUTES.CHAT, premium: true },
  {
    text: "Incidencias",
    icon: <Warning />,
    route: ROUTES.INCIDENTS,
    premium: false,
  },
  {
    text: "Contadores",
    icon: <OpacityOutlined />,
    route: ROUTES.METERS,
    premium: false,
  },
  {
    text: "Contabilidad",
    icon: <MonetizationOn />,
    route: ROUTES.MAINTENANCE_FEE,
    premium: false,
  },
];

function MainSidebar({ open, handleCloseSidebar }: MainSidebarProps) {
  const [popupOpen, setPopupOpen] = useState(false);
  const location = useLocation();
  // Use the useTheme hook to get the current theme.
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Drawer
      PaperProps={{
        sx: {
          backgroundColor: "transparent",
          border: "none",
          ...(isMobile && {
            top: 0,
            height: "100%",
            boxShadow: "4px 0px 8px rgba(0, 0, 0, 0.1)",
          }),
        },
      }}
      variant={isMobile ? "temporary" : "permanent"}
      open={open}
      onClose={handleCloseSidebar}
      ModalProps={{
        keepMounted: true, // Better performance on mobile.
      }}
    >
      <DrawerHeader />
      <List>
        {menuItems.map((item, index) => (
          <MenuItemWrapper key={index} menu={item}>
            <ListItem
              disablePadding
              sx={{
                display: "block",
                bgcolor:
                  item.route === location.pathname ? "#E9EDFF" : "inherit",
                color:
                  item.route === location.pathname
                    ? "#315CAC"
                    : theme.palette.text.primary,
                "&.Mui-disabled": {
                  opacity: 0.5,
                },
              }}
              onClick={() => {
                if (item.premium) {
                  setPopupOpen(true);
                } else if (isMobile && handleCloseSidebar) {
                  handleCloseSidebar();
                }
              }}
            >
              <ListItemButton
                disabled = {item.premium}
                sx={[
                  { minHeight: 48 },
                  open
                    ? { justifyContent: "initial" }
                    : { justifyContent: "center" },
                ]}
              >
                <ListItemIcon
                  sx={[
                    {
                      minWidth: 0,
                      justifyContent: "center",
                      color:
                        item.route === location.pathname
                          ? "#315CAC"
                          : theme.palette.text.primary,
                    },
                    open ? { mr: 3 } : { mr: "auto" },
                  ]}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  sx={[open ? { opacity: 1 } : { opacity: 0 }]}
                />
              </ListItemButton>
            </ListItem>
          </MenuItemWrapper>
        ))}
      </List>

      {/* <PremiumFeaturePopup
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
      /> */}
    </Drawer>
  );
}

const MenuItemWrapper = ({
  menu,
  children,
}: {
  menu: (typeof menuItems)[0];
  children: React.ReactNode;
}) => {
  if (menu.premium) {
    return children;
  }

  return (
    <Link style={{ textDecorationLine: "none" }} to={menu.route}>
      {children}
    </Link>
  );
};

export default MainSidebar;