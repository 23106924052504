import { useCallback } from 'react';
import { useAnalytics } from '../../../analytics/hooks/useAnalytics';
import { EVENT_TYPES } from '../../../analytics/config/events';

export const useMaintenanceFeeAnalytics = () => {
  const { track } = useAnalytics();

  const trackChargeCreated = useCallback((userId: string, chargeId: string, metadata: Record<string, any> = {}) => {
    track(EVENT_TYPES.MAINTENANCE_CHARGE_CREATED, {
      userId,
      chargeId,
      ...metadata,
      timestamp: new Date().toISOString()
    });
  }, [track]);

  const trackPaymentAdded = useCallback((userId: string, paymentId: string, metadata: Record<string, any> = {}) => {
    track(EVENT_TYPES.MAINTENANCE_PAYMENT_ADDED, {
      userId,
      paymentId,
      ...metadata,
      timestamp: new Date().toISOString()
    });
  }, [track]);

  const trackPaymentReconciled = useCallback((userId: string, paymentId: string, status: string) => {
    track(EVENT_TYPES.MAINTENANCE_PAYMENT_RECONCILED, {
      userId,
      paymentId,
      status,
      timestamp: new Date().toISOString()
    });
  }, [track]);

  const trackDataExported = useCallback((userId: string, format: string, recordCount: number) => {
    track(EVENT_TYPES.MAINTENANCE_DATA_EXPORTED, {
      userId,
      format,
      recordCount,
      timestamp: new Date().toISOString()
    });
  }, [track]);

  const trackReminderSent = useCallback((userId: string, recipientCount: number, method: string) => {
    track(EVENT_TYPES.MAINTENANCE_REMINDER_SENT, {
      userId,
      recipientCount,
      method,
      timestamp: new Date().toISOString()
    });
  }, [track]);

  const trackChargesImported = useCallback((userId: string, importType: string, recordCount: number) => {
    track(EVENT_TYPES.MAINTENANCE_CHARGES_IMPORTED, {
      userId,
      importType,
      recordCount,
      timestamp: new Date().toISOString()
    });
  }, [track]);

  return {
    trackChargeCreated,
    trackPaymentAdded,
    trackPaymentReconciled,
    trackDataExported,
    trackReminderSent,
    trackChargesImported
  };
};