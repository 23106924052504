// src/features/invitation/components/VisitCard.js

import React from 'react';
import { Box, Typography, Avatar, Paper } from '@mui/material';
import { CalendarToday, AccessTime, LocationOn, Apartment, Person, Work , PushPin } from '@mui/icons-material';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import StatusChip from './StatusChip';

const VisitCard = ({ visit }) => {
  if (!visit) {
    console.error('Invalid visit data:', visit);
    return null;
  }

  const visitDate = visit.invitation_date || visit.check_in_time;
  const formattedDate = visitDate ? parseISO(visitDate) : null;
  const isProvider = visit.details?.type === 'provider';
  const providerAttributes = visit.details?.attributes || {};

  return (
    <Paper elevation={0} sx={{ p: 3, mb: 2 }}>
      <Typography variant="h6" gutterBottom fontWeight={700}>Visitante</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <Avatar src={visit.visitor_profile_pic} sx={{ width: 56, height: 56, mr: 2 }}>
          {visit.visitor_first_name && visit.visitor_last_name 
            ? `${visit.visitor_first_name[0]}${visit.visitor_last_name[0]}`
            : '?'}
        </Avatar>
        <Box>
          <Typography variant="h5" fontWeight={700}>
            {visit.visitor_first_name && visit.visitor_last_name 
              ? `${visit.visitor_first_name} ${visit.visitor_last_name}`
              : 'Nombre no disponible'}
          </Typography>
          {visit.visitor_document_number && (
            <Typography variant="body2" color="text.secondary" fontWeight={600}>
              DNI: {visit.visitor_document_number}
            </Typography>
          )}
        </Box>
      </Box>
      <StatusChip status={visit.status} />
      <Typography variant="h6" gutterBottom sx={{ mt: 2 }} fontWeight={700}>Detalles de la visita</Typography>
      {isProvider && (
        <>  
          <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
            <Work  sx={{ mr: 2 }} />
            <Box>
              <Typography variant="body2" color="text.secondary" fontWeight={600}>Empresa</Typography>
              <Typography>{providerAttributes.company || 'Motivo no especificado'}</Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
            <PushPin sx={{ mr: 2 }} />
            <Box>
              <Typography variant="body2" color="text.secondary" fontWeight={600}>Motivo</Typography>
              <Typography>{providerAttributes.reasons || 'Motivo no especificado'}</Typography>
            </Box>
          </Box>
        </>
      )}

      {visit.resident_first_name && visit.resident_last_name && (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Person sx={{ mr: 2 }} />
          <Box>
            <Typography variant="body2" color="text.secondary" fontWeight={600}>Anfitrión</Typography>
            <Typography>
              {`${visit.resident_first_name} ${visit.resident_last_name}`}
            </Typography>
          </Box>
        </Box>
      )}
      {formattedDate && (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <CalendarToday sx={{ mr: 2 }} />
            <Box>
              <Typography variant="body2" color="text.secondary" fontWeight={600}>Fecha</Typography>
              <Typography>
                {format(formattedDate, "EEEE d 'de' MMMM, yyyy", { locale: es })}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <AccessTime sx={{ mr: 2 }} />
            <Box>
              <Typography variant="body2" color="text.secondary" fontWeight={600}>Hora</Typography>
              <Typography>{format(formattedDate, "HH:mm")}</Typography>
            </Box>
          </Box>
        </>
      )}
      {visit.building_name && (
        <>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
            <Apartment sx={{ mr: 2 }} />
            <Box>
              <Typography variant="body2" color="text.secondary" fontWeight={600}>Lugar</Typography>
              <Typography>{visit.building_name}</Typography>
              {!isProvider && ( <Typography fontWeight={500}>Depa {visit.apartment_number || 'N/A'}</Typography>)}
            </Box>
          </Box>
          {visit.building_address && (
            <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
              <LocationOn sx={{ mr: 2 }} />
              <Box>
                <Typography variant="body2" color="text.secondary" fontWeight={600}>Dirección</Typography>
                <Typography>{visit.building_address}</Typography>
              </Box>
            </Box>
          )}
        </>
      )}
    </Paper>
  );
};

export default VisitCard;