import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Checkbox,
  FormControlLabel,
  Switch,
  Divider,
  Chip,
  Tooltip,
  Snackbar,
  Alert,
  Avatar
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddAccountModal from "../components/config/AddAccountModal";
import { maintenanceFeeConfigService } from "../services/maintenanceFeeConfigService";
import useGlobalStore from "../../../state/store";
import { useNavigate } from "react-router-dom";

const DAYS_OF_MONTH = Array.from({ length: 31 }, (_, i) => i + 1);

// Bank and digital wallet logos
const BANK_LOGOS = {
  "Interbank": "https://play-lh.googleusercontent.com/vjYx0jloYA0BSr6fHPhvhhIbgieH0jmOV3fv_evGkj9bxxjITNO3Yhfux77bq2_HvBIF=w480-h960-rw",
  "BCP": "https://play-lh.googleusercontent.com/gBpVaCpZsbBrLufT06aRpuLQvsUq1KAZUCEof_ps76mtB8_llJg3xv24mey8I0m3dUE=w480-h960-rw",
  "Scotiabank": "https://play-lh.googleusercontent.com/bX-2nxLIzRoDZBf_wMVI_VIB8NgnochPebM8aDMGe3wqEYz6dSQZsJjZ4F5j8OMM86S2=w480-h960-rw",
  "BBVA": "https://play-lh.googleusercontent.com/ZsRXxnAaNfWkGh0znnApV1d2BnTysMJVgZSAG5i4xX5c3weg6C0IGr6rtkqWMrXZriA=w480-h960-rw",
  // Digital wallets
  "Yape": "https://play-lh.googleusercontent.com/y5S3ZIz-ohg3FirlISnk3ca2yQ6cd825OpA0YK9qklc5W8MLSe0NEIEqoV-pZDvO0A8=w480-h960-rw",
  "Plin": "https://scontent-lim1-1.xx.fbcdn.net/v/t39.30808-6/358694396_784669073456305_5517374345124528079_n.png?_nc_cat=104&ccb=1-7&_nc_sid=6ee11a&_nc_ohc=PMSZeVeTblMQ7kNvgGg8_nv&_nc_oc=AdgfFKapaOUI2Mzi8ujmZE59DPUThiDWIUjaC-b_3_59Fs6FyzHCo_TMSYLyh-14qPw&_nc_zt=23&_nc_ht=scontent-lim1-1.xx&_nc_gid=Atu9gpjnN9BZ1R85-ws_SOD&oh=00_AYB4nbxB5qPL5enNLCkc5Y2rWzOwOO6VY3NeDLAM6SZurA&oe=67CFE9EE",
  "Tunki": "https://content-us-2.content-cms.com/9b3f67ef-5a9f-4acc-8ce8-bcc27fa681c7/dxdam/26/2688f710-1267-4931-bcad-493fadedf85e/img_cta_f4_tunki_tablet_2x-min.jpg?t=1662393735638",
  "Lukita": "https://epsgrau.pe/webpage/oficinavirtual/pagos-digitales/img/lukita.png"
};

// Payment method icons and labels (fallbacks)
const PAYMENT_METHOD_INFO = {
  bank_transfer: { icon: "🏦", label: "Transferencia" },
  digital_wallet: { icon: "📱", label: "Billetera Digital" },
  cash: { icon: "💵", label: "Efectivo" },
  credit_card: { icon: "💳", label: "Tarjeta" },
};

const MaintenanceFeeConfig = () => {
  const navigate = useNavigate();
  const buildingId = useGlobalStore((s) => s.buildingId);

  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showAddAccount, setShowAddAccount] = useState(false);
  const [reminderSettings, setReminderSettings] = useState({
    enabled: true,
    before_due: [3, 7],
    after_due: [1],
    daily_after_due: false,
    whatsapp_enabled: true,
    email_enabled: true,
    templates: {
      before_due: "template_id_1",
      after_due: "template_id_2",
      daily_after_due: "template_id_3"
    }
  });
  
  const [debtCollectionSettings, setDebtCollectionSettings] = useState({
    enabled: false,
    whatsapp_enabled: false,
    phone_agent_enabled: false,
    templates: {
      reminder_warm: "template_id_1",
      reminder_formal: "template_id_2",
      reminder_urgent: "template_id_3"
    }
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
    autoHideDuration: 4000 // Auto-hide after 4 seconds
  });

  useEffect(() => {
    if (!buildingId) return;
    (async () => {
      try {
        setLoading(true);
        const data = await maintenanceFeeConfigService.fetchBuildingFeeSettings(buildingId);
        
        // Handle backward compatibility
        if (data && data.bank_accounts && data.bank_accounts.accounts) {
          // Migrate old data structure to new structure if needed
          const migratedAccounts = data.bank_accounts.accounts.map(account => {
            const migratedAccount = { ...account };
            
            // Map old field names to new ones if they exist
            if (account.bank && !account.provider_name) {
              migratedAccount.provider_name = account.bank;
            }
            
            if (account.bank_name && !account.provider_name) {
              migratedAccount.provider_name = account.bank_name;
            }
            
            if (account.owner && !account.account_owner) {
              migratedAccount.account_owner = account.owner;
            }
            
            if (account.cci && !account.cci_code) {
              migratedAccount.cci_code = account.cci;
            }
            
            if (account.account && !account.account_number) {
              migratedAccount.account_number = account.account;
            }
            
            if (account.paymentMethod && !account.payment_method) {
              migratedAccount.payment_method = account.paymentMethod;
            }
            
            // Always make sure we have a payment_method field
            if (!migratedAccount.payment_method) {
              // Try to infer from context if missing
              if (migratedAccount.provider_name === "Efectivo") {
                migratedAccount.payment_method = "cash";
              } else if (["Yape", "Plin", "Tunki", "Lukita"].includes(migratedAccount.provider_name)) {
                migratedAccount.payment_method = "digital_wallet";
              } else {
                migratedAccount.payment_method = "bank_transfer";
              }
            }
            
            return migratedAccount;
          });
          
          data.bank_accounts.accounts = migratedAccounts;
        }
        
        setSettings(data || {});
        
        // Initialize reminder settings from loaded data
        if (data?.reminder_settings) {
          setReminderSettings(data.reminder_settings);
        }
      } catch (err) {
        console.error("Error fetching building fee settings:", err);
      } finally {
        setLoading(false);
      }
    })();
  }, [buildingId]);

  if (!buildingId) {
    return <Typography sx={{ p: 2 }}>No building selected.</Typography>;
  }

  if (loading) {
    return <Typography sx={{ p: 2 }}>Loading...</Typography>;
  }

  // Use nested accounts structure
  const bankAccounts = settings?.bank_accounts?.accounts || [];

  const handleDueDayChange = (e) => {
    setSettings((prev) => ({
      ...prev,
      fee_due_day: e.target.value,
    }));
  };

  const handleAddAccount = (newAccount) => {
    const updated = [...bankAccounts, newAccount];
    
    // If this is the default account, ensure no other accounts are default
    if (newAccount.is_default) {
      updated.forEach(acc => {
        if (acc.id !== newAccount.id) {
          acc.is_default = false;
        }
      });
    }
    
    setSettings((prev) => ({
      ...prev,
      bank_accounts: {
        accounts: updated
      }
    }));
  };

  const handleDeleteAccount = (index) => {
    const updated = [...bankAccounts];
    updated.splice(index, 1);
    
    // If we deleted the default account and there are other accounts, make the first one default
    if (bankAccounts[index]?.is_default && updated.length > 0) {
      updated[0].is_default = true;
    }
    
    setSettings((prev) => ({
      ...prev,
      bank_accounts: {
        accounts: updated
      }
    }));
  };

  const handleSetDefaultAccount = (index) => {
    const updated = [...bankAccounts];
    
    // Set all accounts to non-default
    updated.forEach((acc, i) => {
      acc.is_default = i === index;
    });
    
    setSettings((prev) => ({
      ...prev,
      bank_accounts: {
        accounts: updated
      }
    }));
  };

  // Reminder settings handlers
  const handleReminderChange = (days, type, isChecked) => {
    setReminderSettings(prev => {
      const currentDays = [...(prev[type] || [])];
      
      if (isChecked && !currentDays.includes(days)) {
        currentDays.push(days);
        // Sort the days for better display
        currentDays.sort((a, b) => a - b);
      } else if (!isChecked && currentDays.includes(days)) {
        const index = currentDays.indexOf(days);
        currentDays.splice(index, 1);
      }
      
      return {
        ...prev,
        [type]: currentDays
      };
    });
  };

  const handleDailyAfterDueChange = (isChecked) => {
    setReminderSettings(prev => ({
      ...prev,
      daily_after_due: isChecked
    }));
  };

  const handleReminderEnabledChange = (isEnabled) => {
    setReminderSettings(prev => ({
      ...prev,
      enabled: isEnabled
    }));
  };

  const handleChannelChange = (channel, isEnabled) => {
    setReminderSettings(prev => ({
      ...prev,
      [`${channel}_enabled`]: isEnabled
    }));
  };

  const handleSave = async () => {
    try {
      const updatedData = {
        fee_due_day: settings.fee_due_day || 5,
        bank_accounts: settings.bank_accounts || { accounts: [] },
        reminder_settings: reminderSettings,
        debt_collection_settings: debtCollectionSettings,
        // Preserve other fields
        water_rate: settings.water_rate,
        electricity_rate: settings.electricity_rate,
        grace_period_days: settings.grace_period_days,
        common_area_allocation: settings.common_area_allocation,
        late_fee_rate: settings.late_fee_rate || {
          type: "percentage",
          value: 0,
          compound: false,
          application_day: 1,
          max_rate: null,
          min_amount: null
        },
        currency: settings.currency || "PEN",
        is_active: settings.is_active !== false
      };
      await maintenanceFeeConfigService.upsertBuildingFeeSettings(buildingId, updatedData);
      setSnackbar({
        open: true,
        message: 'Configuración guardada exitosamente',
        severity: 'success',
        autoHideDuration: 4000
      });
    } catch (error) {
      console.error("Error saving config:", error);
      setSnackbar({
        open: true,
        message: 'Error al guardar la configuración',
        severity: 'error',
        autoHideDuration: 6000 // Longer duration for errors
      });
    }
  };

  // Add this handler for closing the snackbar
  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const handleClose = () => {
    navigate(-1); // go back one page
  };

  // Helper to get the bank logo or fallback to emoji
  const getBankLogo = (account) => {
    // Special case for cash payments
    if (account.payment_method === 'cash') {
      return (
        <Box 
          sx={{ 
            mr: 2,
            height: 40, 
            width: 40, 
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#FEF3C7', // Warm yellow background
            color: '#D97706', // Warm yellow text
            fontSize: '20px'
          }}
        >
          💵
        </Box>
      );
    }

    // Special case for other payment method
    if (account.payment_method === 'other') {
      return (
        <Box 
          sx={{ 
            mr: 2,
            height: 40, 
            width: 40, 
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#F3F4F6', // Gray background
            color: '#4B5563', // Gray text
            fontSize: '20px'
          }}
        >
          🔗
        </Box>
      );
    }
    
    // For bank transfers and digital wallets, try to get logo
    const logoUrl = BANK_LOGOS[account.provider_name];
    
    if (logoUrl) {
      return (
        <Avatar 
          src={logoUrl}
          alt={account.provider_name}
          variant="rounded"
          sx={{ 
            width: 40, 
            height: 40, 
            mr: 2,
            backgroundColor: '#f0f0f0',
            border: '1px solid #e0e0e0'
          }}
        />
      );
    }
    
    // Fallback to emoji icon
    const method = account.payment_method || "bank_transfer";
    const info = PAYMENT_METHOD_INFO[method] || { icon: "💲" };
    
    return (
      <Box 
        sx={{ 
          mr: 2,
          height: 40, 
          width: 40, 
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: method === 'digital_wallet' 
            ? '#F0FDF4' // Green background for digital wallets
            : '#EFF6FF', // Blue background for bank transfers
          color: method === 'digital_wallet'
            ? '#22C55E' // Green text
            : '#3B82F6', // Blue text
          fontSize: '20px'
        }}
      >
        {info.icon}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        p: 3,
        maxWidth: 800,
        margin: "0 auto",
        position: "relative",
      }}
    >
      {/* Header with Title + Close Button */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          Configuraciones de Cuotas
        </Typography>
        <IconButton onClick={handleClose} sx={{ color: "#6B7280" }}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Fecha de Vencimiento */}
      <Box
        sx={{
          mb: 4,
          backgroundColor: "#F9FAFB",
          borderRadius: "8px",
          p: 2,
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
          Fecha de Vencimiento
        </Typography>
        <Typography variant="body2" sx={{ mb: 2, color: "#6B7280" }}>
          Define la fecha límite de pago para las cuotas de mantenimiento. Esta fecha será aplicada
          automáticamente a las cuotas de los próximos meses
        </Typography>

        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel>Día</InputLabel>
          <Select
            value={settings.fee_due_day || 5}
            label="Día"
            onChange={handleDueDayChange}
            sx={{ backgroundColor: "white" }}
          >
            {DAYS_OF_MONTH.map((day) => (
              <MenuItem key={day} value={day}>
                {`Día ${day}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Medios de Pago */}
      <Box
        sx={{
          mb: 4,
          backgroundColor: "#F9FAFB",
          borderRadius: "8px",
          p: 3,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1 }}>
              Medios de Pago
            </Typography>
            <Typography variant="body2" sx={{ color: "#6B7280" }}>
              Indica las cuentas bancarias y métodos de pago en los que los residentes podrán realizar los pagos
            </Typography>
          </Box>
          <Button
            variant="contained"
            onClick={() => setShowAddAccount(true)}
            size="small"
            sx={{
              minWidth: 'unset',
              width: '36px',
              height: '36px',
              borderRadius: '6px',
              p: 0,
              backgroundColor: '#0284C7',
              boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
              '&:hover': {
                backgroundColor: '#0369A1',
              }
            }}
          >
            <AddCircleOutlineIcon fontSize="small" />
          </Button>
        </Box>

        {/* Existing Accounts */}
        {bankAccounts.length === 0 ? (
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            No hay medios de pago registrados.
          </Typography>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {bankAccounts.map((acc, idx) => (
              <Box
                key={idx}
                sx={{
                  border: "1px solid #E5E7EB",
                  borderRadius: "12px",
                  p: 0,
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#FFFFFF",
                  overflow: "hidden",
                  boxShadow: "0 1px 3px rgba(0,0,0,0.05)",
                  transition: "box-shadow 0.2s",
                  "&:hover": {
                    boxShadow: "0 4px 6px rgba(0,0,0,0.05), 0 1px 3px rgba(0,0,0,0.1)",
                  },
                }}
              >
                {/* Header with payment type and options */}
                <Box 
                  sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    borderBottom: '1px solid #F3F4F6',
                    p: 2,
                    backgroundColor: acc.is_default ? '#F0F9FF' : 'transparent',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {/* Bank/Wallet Logo */}
                    {getBankLogo(acc)}
                    <Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 600, mr: 1 }}>
                          {acc.alias || acc.provider_name}
                        </Typography>
                        {acc.is_default && (
                          <Box 
                            sx={{ 
                              backgroundColor: '#0284C7', 
                              color: 'white',
                              py: 0.5,
                              px: 1.5,
                              borderRadius: '12px',
                              fontSize: '0.75rem',
                              fontWeight: 600,
                            }}
                          >
                            Principal
                          </Box>
                        )}
                      </Box>
                      <Typography variant="body2" color="text.secondary">
                        {acc.payment_method === 'digital_wallet' 
                          ? 'Billetera Digital' 
                          : acc.payment_method === 'cash'
                          ? 'Efectivo'
                          : 'Transferencia'}
                      </Typography>
                    </Box>
                  </Box>
                  
                  <Box>
                    {!acc.is_default && (
                      <Tooltip title="Hacer Principal">
                        <IconButton
                          onClick={() => handleSetDefaultAccount(idx)}
                          size="small"
                          sx={{
                            color: '#6B7280',
                            mr: 1,
                            '&:hover': {
                              color: '#1F2937',
                              backgroundColor: '#F9FAFB',
                            }
                          }}
                        >
                          <StarOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Eliminar">
                      <IconButton
                        onClick={() => handleDeleteAccount(idx)}
                        size="small"
                        sx={{
                          color: '#EF4444',
                          '&:hover': {
                            backgroundColor: '#FEF2F2',
                          }
                        }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>

                {/* Details Section */}
                <Box sx={{ p: 2, backgroundColor: '#FAFAFA' }}>
                  {/* Cash payment details - simplified view */}
                  {acc.payment_method === 'cash' && (
                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', rowGap: 1 }}>
                      <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                        Pago en efectivo directamente
                      </Typography>
                    </Box>
                  )}

                  {/* Digital wallet details */}
                  {acc.payment_method === 'digital_wallet' && (
                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', rowGap: 1 }}>
                      {acc.phone_number && (
                        <Box>
                          <Typography variant="body2" color="text.secondary">Teléfono</Typography>
                          <Typography variant="body1">{acc.phone_number}</Typography>
                        </Box>
                      )}
                      {acc.currency && (
                        <Box>
                          <Typography variant="body2" color="text.secondary">Moneda</Typography>
                          <Typography variant="body1">{acc.currency}</Typography>
                        </Box>
                      )}
                    </Box>
                  )}

                  {/* Bank transfer details */}
                  {acc.payment_method === 'bank_transfer' && (
                    <Box sx={{ display: 'grid', gridTemplateColumns: {xs: '1fr', sm: '1fr 1fr'}, rowGap: 1, columnGap: 2 }}>
                      {acc.account_type && (
                        <Box>
                          <Typography variant="body2" color="text.secondary">Cuenta</Typography>
                          <Typography variant="body1">
                            {acc.account_type === "savings" ? "Ahorros" : "Corriente"}
                          </Typography>
                        </Box>
                      )}
                      {acc.account_owner && (
                        <Box>
                          <Typography variant="body2" color="text.secondary">Titular</Typography>
                          <Typography variant="body1">{acc.account_owner}</Typography>
                        </Box>
                      )}
                      {acc.account_number && (
                        <Box>
                          <Typography variant="body2" color="text.secondary">Nro de Cuenta</Typography>
                          <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
                            {acc.account_number}
                          </Typography>
                        </Box>
                      )}
                      {acc.cci_code && (
                        <Box>
                          <Typography variant="body2" color="text.secondary">Cód. Interbancario</Typography>
                          <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>{acc.cci_code}</Typography>
                        </Box>
                      )}
                      {acc.currency && (
                        <Box>
                          <Typography variant="body2" color="text.secondary">Moneda</Typography>
                          <Typography variant="body1">{acc.currency}</Typography>
                        </Box>
                      )}
                    </Box>
                  )}

                  {/* Other payment method details */}
                  {acc.payment_method === 'other' && (
                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', rowGap: 1 }}>
                      <Box>
                        <Typography variant="body2" color="text.secondary">Enlace de Pago</Typography>
                        <Typography 
                          variant="body1" 
                          component="a" 
                          href={acc.payment_link}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{ 
                            color: '#2563EB',
                            textDecoration: 'none',
                            '&:hover': {
                              textDecoration: 'underline'
                            }
                          }}
                        >
                          {acc.payment_link}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>

      {/* Recordatorios */}
      <Box
        sx={{
          mb: 4,
          backgroundColor: "#F9FAFB",
          borderRadius: "8px",
          p: 2,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            Recordatorios
          </Typography>
          <FormControlLabel
            control={
              <Switch 
                checked={reminderSettings.enabled}
                onChange={(e) => handleReminderEnabledChange(e.target.checked)}
              />
            }
            label="Activar recordatorios"
          />
        </Box>

        <Typography variant="body2" sx={{ mb: 2, color: "#6B7280" }}>
          Activa y personaliza los recordatorios automáticos que el sistema enviará a los residentes
          en WhatsApp. Puedes configurar la frecuencia
        </Typography>

        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" sx={{ fontWeight: 500, mb: 1 }}>
            Canales de notificación
          </Typography>
          <Box sx={{ display: "flex", gap: 3 }}>
            <FormControlLabel
              control={
                <Checkbox 
                  checked={reminderSettings.whatsapp_enabled}
                  onChange={(e) => handleChannelChange('whatsapp', e.target.checked)}
                  disabled={!reminderSettings.enabled}
                />
              }
              label="WhatsApp"
            />
            <FormControlLabel
              control={
                <Checkbox 
                  checked={reminderSettings.email_enabled}
                  onChange={(e) => handleChannelChange('email', e.target.checked)}
                  disabled={!reminderSettings.enabled}
                />
              }
              label="Email"
            />
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box sx={{ display: "flex", gap: 4 }}>
          {/* Antes del Vencimiento */}
          <Box>
            <Typography sx={{ fontWeight: 500, mb: 1 }}>Antes del vencimiento</Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Box>
                <input 
                  type="checkbox" 
                  id="reminder1" 
                  checked={reminderSettings.before_due?.includes(1)}
                  onChange={(e) => handleReminderChange(1, 'before_due', e.target.checked)}
                  disabled={!reminderSettings.enabled}
                />
                <label htmlFor="reminder1"> Avisar 1 día antes del vencimiento</label>
              </Box>
              <Box>
                <input 
                  type="checkbox" 
                  id="reminder3" 
                  checked={reminderSettings.before_due?.includes(3)}
                  onChange={(e) => handleReminderChange(3, 'before_due', e.target.checked)}
                  disabled={!reminderSettings.enabled}
                />
                <label htmlFor="reminder3"> Avisar 3 días antes del vencimiento</label>
              </Box>
              <Box>
                <input 
                  type="checkbox" 
                  id="reminder7" 
                  checked={reminderSettings.before_due?.includes(7)}
                  onChange={(e) => handleReminderChange(7, 'before_due', e.target.checked)}
                  disabled={!reminderSettings.enabled}
                />
                <label htmlFor="reminder7"> Avisar 7 días antes del vencimiento</label>
              </Box>
              {/* <Box>
                <input 
                  type="checkbox" 
                  id="reminder14" 
                  checked={reminderSettings.before_due?.includes(14)}
                  onChange={(e) => handleReminderChange(14, 'before_due', e.target.checked)}
                  disabled={!reminderSettings.enabled}
                />
                <label htmlFor="reminder14"> Avisar 14 días antes del vencimiento</label>
              </Box> */}
            </Box>
          </Box>

          {/* Posterior al Vencimiento */}
          <Box>
            <Typography sx={{ fontWeight: 500, mb: 1 }}>Posterior al vencimiento</Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Box>
                <input 
                  type="checkbox" 
                  id="reminderPost1" 
                  checked={reminderSettings.after_due?.includes(1)}
                  onChange={(e) => handleReminderChange(1, 'after_due', e.target.checked)}
                  disabled={!reminderSettings.enabled}
                />
                <label htmlFor="reminderPost1"> Avisar 1 día después</label>
              </Box>
              <Box>
                <input 
                  type="checkbox" 
                  id="reminderPost3" 
                  checked={reminderSettings.after_due?.includes(3)}
                  onChange={(e) => handleReminderChange(3, 'after_due', e.target.checked)}
                  disabled={!reminderSettings.enabled}
                />
                <label htmlFor="reminderPost3"> Avisar 3 días después</label>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Debt Collection Settings */}
      <Box
        sx={{
          mb: 4,
          backgroundColor: "#F9FAFB",
          borderRadius: "8px",
          p: 2,
          opacity: 0.7, // Make it look disabled
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            Cobranza Masiva
          </Typography>
          <Tooltip title="Esta función estará disponible próximamente">
            <FormControlLabel
              control={
                <Switch 
                  checked={debtCollectionSettings.enabled}
                  disabled={true}
                />
              }
              label="Activar cobranza masiva"
            />
          </Tooltip>
        </Box>

        <Typography variant="body2" sx={{ mb: 2, color: "#6B7280" }}>
          Las notificaciones masivas de cobranza están temporalmente deshabilitadas. 
          Esta función estará disponible próximamente.
        </Typography>

        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" sx={{ fontWeight: 500, mb: 1 }}>
            Canales de cobranza
          </Typography>
          <Box sx={{ display: "flex", gap: 3 }}>
            <FormControlLabel
              control={
                <Checkbox 
                  checked={debtCollectionSettings.whatsapp_enabled}
                  disabled={true}
                />
              }
              label="WhatsApp"
            />
            <FormControlLabel
              control={
                <Checkbox 
                  checked={debtCollectionSettings.phone_agent_enabled}
                  disabled={true}
                />
              }
              label="Agente telefónico"
            />
          </Box>
        </Box>
      </Box>

      {/* Guardar Cambios */}
      <Box sx={{ textAlign: "right" }}>
        <Button
          variant="contained"
          onClick={handleSave}
          sx={{
            textTransform: "none",
            borderRadius: "24px",
            px: 4,
          }}
        >
          Guardar Configuraciones
        </Button>
      </Box>

      {/* Modal to add payment method */}
      {showAddAccount && (
        <AddAccountModal
          open={showAddAccount}
          onClose={() => setShowAddAccount(false)}
          onAddAccount={handleAddAccount}
          currency={settings?.currency || "PEN"}
        />
      )}

      {/* Auto-hiding Snackbar */}
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={snackbar.autoHideDuration} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MaintenanceFeeConfig;